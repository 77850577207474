import React, { useState } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Service from "../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
const { RangePicker } = DatePicker;


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

export default function GestionUtilisateur() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);

  const periode = async (value) => {
    // await setEndDate(new Date());
    const date = new Date();
    if(value === "Journalière") {
    } else if(value === "Hebdomendaire") {
      await date.setDate(date.getDate() - 7);
    } else if(value === "Mensuel") {
      await date.setDate(date.getDate() - 30);
    } else if(value === "Trimestriel") {
      await date.setDate(date.getDate() - 90);
    } else if(value === "Annuelle") {
      await date.setDate(date.getDate() - 365);
    }
    historique(date, new Date())
  }


  const historique = async (date, endDate) => {
    setSpinner(true);
    endDate.setMonth(endDate.getMonth() + 1);
    date.setMonth(date.getMonth() + 1);
    let data = {
      // utilisateurid: localStorage.getItem('user').id,
      debut: date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate(),
      fin: endDate.getFullYear()+"-"+endDate.getMonth()+"-"+endDate.getDate(),
    }

    Service.historiqueAllUtilisateur(data).then(
      async (response) => {
        let donnees = []
        response.data.data.forEach((element, index) => {
          let e = JSON.parse(element);
          donnees.push([
            e.id, e.nom, e.telephone, e.email, e.dateinscription
          ])
          if(response.data.data.length === (index + 1)){
            setReponse(donnees);
          }
        });
        setSpinner(false);
      }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
}


function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}
const onChange = (dates, dateStrings) => {
  historique(new Date(dateStrings[0]), new Date(dateStrings[1]))
  // this.setState({debut: dateStrings[0].split("-").reverse().join("-"), fin: dateStrings[1].split("-").reverse().join("-")})
}

  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des utilisateurs" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={2}>
          <strong>Par Période</strong>
          <br/>
          <Select
            style={{ width: 120 }}
            onChange={(e)=>periode(e)}
            options={[
              { value: 'Journalière', label: 'Journalière' },
              { value: 'Hebdomendaire', label: 'Hebdomendaire' },
              { value: 'Mensuel', label: 'Mensuel' },
              { value: 'Trimestriel', label: 'Trimestriel' },
              { value: 'Annuelle', label: 'Annuelle' },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <strong>Par intervalle de Date</strong>
          <br/>
          <RangePicker 
            disabledDate={disabledDate}
            onChange={onChange}
            // format="DD-MM-YYYY"
            style={{width: "100%"}}
            locale={frFR}
            ranges={{
              'Aujourd\'hui': [moment(), moment()],
              'Ce mois': [moment().startOf('month'), moment().endOf('month')],
            }}
            placeholder={["Du", "au"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color="secondary" />}
          <MUIDataTable
            title="Listes des utilisateurs"
            data={reponse}
            columns={["ID", "Nom", "Téléphone", "Email", "Date d'ajout"]}
            options={{
              filterType: "checkbox",
              textLabels: {
                body: {
                  noMatch: 'Rien à afficher pour le moment',
                }
              },
              selectableRows: "none"
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
