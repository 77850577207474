import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Header from "../../../components/Header/Header";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let search = "&search="
let user = JSON.parse(localStorage.getItem("user"));
export default function Repartition() {
  const classes = useStyles();

  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [apivalide, setApiValide] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [typeconcours, setTypeconcours] = useState([]);
  const [salleList, setSalleList] = useState([]);
  const [centreconcours, setCentreconcours] = useState([]);
  const [profesion, setProfesion] = useState([]);
  var [open, setOpen] = useState(false);
  var [stateEtape, setStateEtape] = useState(0);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    schoolYear: "",
    step: 0,
    classroom__id: "",
    classroom__competitionCenter__id: "",
    candidature__programmationCategoryProfession__profession__id: "",
    candidature__programmationCategoryProfession__competitionCategory__id: ""
  });

  React.useEffect(() => {
    (()=>{
      // GetProfession(-1, -1)
      GetCategoryConcours(-1, -1)
      // GetCentreConcours(-1, -1)
      // GetSalleCentres(-1, -1)
      bindAnnees()
    })();
  }, []);

//   const GetSalleCentres = async (page, size) => {
//     Service.GetSalleCentres(page, size).then(
//       async (response) => {
//         setSalleList(response.data)
//       })
// }

  const GetCategoryConcours = async (page, size) => {
    Service.GetCategoryConcours(page, size).then(
      async (response) => {
        setTypeconcours(response.data)
      })
}


// const GetCentreConcours = async (page, size) => {
//   Service.GetCentreConcours(page, size).then(
//     async (response) => {
//       setCentreconcours(response.data)
//     })
//   }

const GetRepartionsSalle = async (page, size, search) => {
  setSpinner(true)
  Service.GetRepartionsSalle(page, size, recherche, search).then(
    async (response) => {
      setSpinner(false)
      setPage({
        page: page,
        size: size,
        totalElements: response.data.totalElements
      })
      // setReponse(response.data)
      setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      // Service.GetAnneeScolaire().then(anneesBinded => {
      //   let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
      //     setRecherche({...recherche, annee: year})
      //     setAnnee(anneesBinded.data.content);
      // });
      Service.GetCurrentYear().then(
        async (response) => {
          setRecherche({...recherche, annee: response.data.id})
          let data = []
          data.push(response.data)
          setAnnee(data);
        })
    }
//   const GetProfession = async (page, size) => {
//     Service.GetCorps(page, size).then(
//       async (response) => {
//         setProfesion(response.data)
//       })
// }

  const columns = [
      {
        name: "candidature.applicationNumber",
        label: "Numéro Dossier",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidature.numPlace",
        label: "Numéro de place",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidature.candidate.firstName",
        label: "Prénom(s)",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidature.candidate.lastName",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        label: "Père",
        name: "candidature.candidate",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{value.fatherFirstName} {value.fatherLastName}</div>;
          }
        }
      },
      {
        label: "Mère",
        name: "candidature.candidate",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{value.motherFirstName} {value.motherLastName}</div>;
          }
        }
      },
      {
        name: "candidature.candidate.birthdate",
        label: "Date de naissance",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidature.candidate.birthplace",
        label: "Lieu de naissance",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidature.candidate.gender",
        label: "Genre",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidature.candidate.phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidature.formationGrade",
        label: "Diplôme",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "classroom.acronym",
        label: "Salle",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "classroom.competitionCenter.name",
        label: "Centre",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "classroom.competitionCenter.academy.name",
        label: "Académie",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "step",
        label: "Etape",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidature.programmationCategoryProfession.schoolYear.year",
        label: "Année",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidature.programmationCategoryProfession.competitionCategory.name",
        label: "Type de concours",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidature.programmationCategoryProfession.profession.name",
        label: "Corps",
        options: {
          filter: true,
          sort: true,
         }
      },
      // {
      //   label: "Action",
      //   name: "id",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <div>
      //         <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteProgrammationConcours(tableMeta.rowData)}}> <Delete /> Supprimer </IconButton>
      //         {/* <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta)}}> <Edit /> Modifier </IconButton> */}
      //       </div>;
      //     }
      //   }
      // }
    ];

const afficher = async () => {
  search = "&search=surveillant%3Dnull&"
  if(recherche.candidature__programmationCategoryProfession__competitionCategory__id > 0){
    search = `${search}candidature__programmationCategoryProfession__competitionCategory__id%3D${recherche.candidature__programmationCategoryProfession__competitionCategory__id}`
  }
  if(recherche.candidature__programmationCategoryProfession__profession__id > 0){
    search = `${search}candidature__programmationCategoryProfession__profession__id%3D${recherche.candidature__programmationCategoryProfession__profession__id}&`
  }
  if(recherche.classroom__id > 0){
    search =`${search}classroom__id%3D${recherche.classroom__id}&`
  }
  if(recherche.classroom__competitionCenter__id > 0){
    search =`${search}classroom__competitionCenter__id%3D${recherche.classroom__competitionCenter__id}&`
  }
  GetRepartionsSalle(page.page, page.size, (search !== "&search=" ? search : ""))
}

  return (
    <>
      <ToastContainer />
      <Header />
      <Grid container className={classes.container}>
          <Grid item sm={1}></Grid>
          <Grid item sm={10}>
            <PageTitle title="Répartition dans les salles" />
            <Grid container spacing={4}>
              <Grid item xs={3}>
                  <Select
                      placeholder="Année"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setRecherche({...recherche, schoolYear: e})}
                      // defaultValue={annee.length > 0 && annee[0].year}
                      options=
                      {
                        annee.length > 0 && annee.map((element) => {
                          return { "value": element.year, "label": element.year }
                        })
                      }
                    />
              </Grid>
              <Grid item xs={3}>
                  <Select
                    placeholder="Etape"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    onChange={(e)=>setRecherche({...recherche, step: e})}
                    // defaultValue={annee.length > 0 && annee[0].year}
                    options=
                    {Constant.etape}
                  />
              </Grid>
              {/* <Grid item xs={3}>
                  <Select
                      placeholder="Salle"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setRecherche({...recherche, classroom__id: e})}
                      // defaultValue={annee.length > 0 && annee[0].year}
                      options=
                      {
                        salleList.length > 0 && salleList.map((element) => {
                          return { "value": element.id, "label": element.acronym + " ("+element.numberPlace+")" }
                        })
                      }
                    />
              </Grid> */}
              <Grid item xs={3}>
                  <Select
                      placeholder="Type de concours"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setRecherche({...recherche, candidature__programmationCategoryProfession__competitionCategory__id: e})}
                      options=
                      {
                        typeconcours.length > 0 && typeconcours.map((element) => {
                          return { "value": element.id, "label": element.name }
                        })
                      }
                    />
              </Grid>
              {/* <Grid item xs={3}>
                  <Select
                      placeholder="Corps"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setRecherche({...recherche, candidature__programmationCategoryProfession__profession__id: e})}
                      options=
                      {
                        profesion.length > 0 && profesion.map((element) => {
                          return { "value": element.id, "label": element.name + " ("+element.acronym+")" }
                        })
                      }
                    />
              </Grid>
              <Grid item xs={3}>
                  <Select
                      placeholder="Centre"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setRecherche({...recherche, classroom__competitionCenter__id: e})}
                      options=
                      {
                        centreconcours.length > 0 && centreconcours.map((element) => {
                          return { "value": element.id, "label": element.name + " ("+element.acronym+")" }
                        })
                      }
                    />
              </Grid> */}
              
              <Grid item xs={2}>
                <Button onClick={(e)=>afficher()} variant="contained" startIcon={<Add />} style={{color: 'black', backgroundColor: 'white'}}>
                    Afficher
                  </Button>
              </Grid>
              <Grid item xs={12}>
                {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
                <MUIDataTable
                  title="Liste des candidats répartis"
                  data={reponse}
                  columns={columns}
                  options={{
                    draggableColumns: { enabled: true },
                    enableNestedDataAccess: '.',
                    filterType: "multiselect",
                    jumpToPage: true,
                    serverSide: serverSide,
                    rowsPerPageOptions:[50, 100, 500, 1000],
                    rowsPerPage: page.size,
                    count: page.totalElements,
                    textLabels: Constant.textLabels,
                    selectableRows: "none",
                    setFilterChipProps: (colIdx, colName, data) => {
                      return {
                        label: `${colName}: ${data}`,
                      }
                    },
                    onTableChange(action, tableState) {
                      if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                        setServerSide(false)
                      }
                    },
                    onChangePage(currentPage) {
                      setServerSide(true)
                      GetRepartionsSalle(currentPage, page.size, (search !== "&search=" ? search : ""))
                    },
                    onChangeRowsPerPage(numberOfRows) {
                      setServerSide(true)
                      GetRepartionsSalle(page.page, numberOfRows, (search !== "&search=" ? search : ""))
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={1}></Grid>
      </Grid>
    </>
  );
}
