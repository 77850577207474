import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let user = JSON.parse(localStorage.getItem("user"));
export default function MesApis() {
  const classes = useStyles();

  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [apivalide, setApiValide] = useState([]);
  const [annee, setAnnee] = useState([]);
  const [eventList, setEventList] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [state, setState] = React.useState({
    id: "",
    type: "",
    startDate: "",
    endDate: "",
    schoolYear: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    startDate: false,
    endDate: false,
    type: false,
    schoolYear: false,
  });

  React.useEffect(() => {
    (()=>{
      GetCandidatureEvents(page.page, page.size)
      bindAnnees()
      GetEventList()
    })();
  }, []);

   //Functions
   function bindAnnees (){
    Service.GetAnneeScolaire().then(anneesBinded => {
        setAnnee(anneesBinded.data.content);
    });
}

   function GetEventList (){
    Service.GetEventList().then(event => {
        setEventList(event.data);
    });
}

  const GetCandidatureEvents = async (page, size) => {
    setSpinner(true)
    Service.GetCandidatureEvents(page, size).then(
      async (response) => {
        setSpinner(false)
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}

const OpenModal = async () => {
  let d = []
  setState(
    {
      id: "",
      type: "",
      startDate: "",
      endDate: "",
      schoolYear: {
        id: "",
      },
    }
  )
  setOpen(true)
}
const modifier = async (data) => {
  setState({
    id: data.id,
    type: data.type,
    startDate: data.startDate,
    endDate: data.endDate,
    schoolYear: {
      id: data.schoolYear.id,
    },
  })
  setOpen(true)
}
  const deleteEvent = async (id) => {
    setSpinner(true)
    Service.deleteEvent(id).then(
      async (response) => {
        GetCandidatureEvents(page.page, page.size);
        setSpinner(false)
        toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}



  const columns = [
      {
        name: "type",
        label: "Type",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "startDate",
        label: "Début",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "endDate",
        label: "Fin",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "schoolYear.year",
        label: "Année",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return value.year;
          // }
        }
      },
      {
        name: "eventRemainingDays",
        label: "Statut",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Chip label={value >= 0 ? "EN COURS" : "PASSE"} classes={{root: value >= 0 ? classes.success : classes.secondary}}/>;
          }
        }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteEvent(value)}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex])}}> <Edit /> Modifier </IconButton>
            </div>;
          }
        }
      }
    ];


  const cliquer = async () => {
    if(state.schoolYear.id === ""){
      setError({...error, schoolYear: true})
    } else if(state.type.trim() === ""){
      setError({...error, type: true, schoolYear: false})
    } else if(state.endDate.trim() === ""){
      setError({...error, endDate: true, type: false, schoolYear: false})
    } else{
        setError({...error, endDate: false, type: false, schoolYear: false})
        setSpinner(true);
        if(state.id === "") {
            Service.PostEvents(state).then(
              async (response) => {
                GetCandidatureEvents(page.page, page.size)
                setSpinner(false);
                setOpen(false)
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
                // getAPIsList()
              
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          Service.PutEvents(state).then(
            async (response) => {
              GetCandidatureEvents(page.page, page.size)
              setSpinner(false);
              setOpen(false)
              toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
              // getAPIsList()
            
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    }
}


function disabledDate(current) {
  return current && current < moment().endOf('day');
}
const onChange = (dates, dateStrings) => {
  setState({
    ...state,
    startDate: dateStrings[0],
    endDate: dateStrings[1],
  })
  // historique(new Date(dateStrings[0]), new Date(dateStrings[1]))
}

  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des événements" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Ajouter un événement
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Listes des événements"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetCandidatureEvents(currentPage, page.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetCandidatureEvents(page.page, numberOfRows)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={4}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Nouveau événement
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Année</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, schoolYear: {...state.schoolYear, id: e}})}
                                  defaultValue={state.schoolYear.id !== "" && state.schoolYear.id}
                                  options=
                                  {
                                    annee.length > 0 && annee.map((element) => {
                                      return { "value": element.id, "label": element.year }
                                    })
                                  }
                                />
                                {error.schoolYear && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <span>Type de l'événement</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, type: e})}
                                  defaultValue={state.type}
                                  options={
                                      eventList.length > 0 && eventList.map((element) => {
                                        return { "value": element, "label": `${element}` }
                                      })
                                    }
                                />
                                {error.type && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <label>Date début et fin</label>
                              <br/>
                              <RangePicker 
                                // disabledDate={disabledDate}
                                onChange={onChange}
                                // format="DD-MM-YYYY"
                                defaultValue={state.startDate !== "" && [dayjs(state.startDate, dateFormat), dayjs(state.endDate, dateFormat)]}
                                style={{width: "100%"}}
                                locale={frFR}
                                // ranges={{
                                  //   'Aujourd\'hui': [moment(), moment()],
                                  //   'Ce mois': [moment().startOf('month'), moment().endOf('month')],
                                  //   'Cette année': [moment().startOf('year'), moment().endOf('year')],
                                  // }}
                                  placeholder={["Du", "au"]}
                                  />
                                  {error.endDate && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
