import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Group,
  DesktopWindows,
  ShowChart,
  AccountBalance,
  AccountBalanceWallet,
  Payment,
  PieChart,
  BarChart,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const enaAdmin = process.env.REACT_APP__ENA_ADMINISTRAT_ROUTE;

const structure = [
  { id: 0, type: "title", label: "Administation" },
  {
    id: 49,
    label: "Gestion globale",
    // link: "/app/ui",
    icon: <KeyboardArrowDown />,
    children: [
      { id: 3, label: "Années", link: enaAdmin+"/annees", icon: <DesktopWindows /> },
      { id: 8, label: "Evénements", link: enaAdmin+"/evenements", icon: <LibraryIcon /> },
      { id: 50, label: "Académies", link: enaAdmin+"/academy", icon: <LibraryIcon /> },
      { id: 52, label: "Professions", link: enaAdmin+"/profession", icon: <LibraryIcon /> },
      { id: 54, label: "Types de rejet", link: enaAdmin+"/typeRejet", icon: <LibraryIcon /> },
      { id: 55, label: "Services des canditats", link: enaAdmin+"/serviceCandidats", icon: <LibraryIcon /> },
      { id: 62, label: "Corps", link: enaAdmin+"/corps", icon: <LibraryIcon /> },
      { id: 63, label: "Handicap nature", link: enaAdmin+"/handicapNature", icon: <LibraryIcon /> },
      { id: 64, label: "Handicap assistance", link: enaAdmin+"/handicapAssistance", icon: <LibraryIcon /> },
    ],
  },
  {
    id: 48,
    label: "Recrutements",
    // link: "/app/ui",
    icon: <KeyboardArrowDown />,
    children: [
      { id: 51, label: "Type de Concours", link: enaAdmin+"/categoryConcours", icon: <LibraryIcon /> },
      { id: 56, label: "Centres du concours", link: enaAdmin+"/centreConcours", icon: <LibraryIcon /> },
      { id: 57, label: "Salles des centres", link: enaAdmin+"/salleCentres", icon: <LibraryIcon /> },
      { id: 53, label: "Programmation", link: enaAdmin+"/programmation", icon: <LibraryIcon /> },
      { id: 52, label: "Candidatures", link: enaAdmin+"/gestionCandidature", icon: <LibraryIcon /> },
      { id: 52, label: "Répartition candidats", link: enaAdmin+"/Repartitions", icon: <LibraryIcon /> },
      { id: 58, label: "Epreuves du concours", link: enaAdmin+"/epreuveConcours", icon: <LibraryIcon /> },
      { id: 70, label: "Programmation épreuves", link: enaAdmin+"/ProgrammationEpreuveConcours", icon: <LibraryIcon /> },
      { id: 71, label: "Evaluations", link: enaAdmin+"/Evaluations", icon: <LibraryIcon /> },
      { id: 71, label: "Gestion des anonymats", link: enaAdmin+"/GestionAnonimat", icon: <LibraryIcon /> },
      { id: 72, label: "Notes avec anonymats", link: enaAdmin+"/GestionNoteAnonimat", icon: <LibraryIcon /> },
      { id: 73, label: "Noter les candidats", link: enaAdmin+"/NoteCandidat", icon: <LibraryIcon /> },
      { id: 86, label: "Gestion des écarts", link: enaAdmin+"/gestionEcarts", icon: <LibraryIcon /> },
      { id: 74, label: "Paramètres de passage", link: enaAdmin+"/ParametresConcours", icon: <LibraryIcon /> },
      { id: 75, label: "Résultat du concours", link: enaAdmin+"/ResultatConcours", icon: <LibraryIcon /> },
    ],
  },
  {
    id: 47,
    label: "Structure",
    // link: "/app/ui",
    icon: <KeyboardArrowDown />,
    children: [
      // { id: 1, label: "Utilisateurs", link: "/app/gestionUtilisateur", icon: <Group /> },
      { id: 59, label: "Département", link: enaAdmin+"/departement", icon: <LibraryIcon /> },
      { id: 60, label: "Service", link: enaAdmin+"/service", icon: <LibraryIcon /> },
      { id: 61, label: "Division", link: enaAdmin+"/division", icon: <LibraryIcon /> },
      { id: 65, label: "Bâtiments", link: enaAdmin+"/batiments", icon: <LibraryIcon /> },
      { id: 66, label: "Salles", link: enaAdmin+"/salleBatiment", icon: <LibraryIcon /> },
      { id: 67, label: "Equipements", link: enaAdmin+"/equipementSalle", icon: <LibraryIcon /> },
      { id: 87, label: "Gestion des classes", link: enaAdmin+"/GestionClasse", icon: <LibraryIcon /> },
      { id: 88, label: "Gestion des modules", link: enaAdmin+"/GestionModules", icon: <LibraryIcon /> },
    ],
  },
  {
    id: 68,
    label: "Ressources humaines",
    // link: "/app/ui",
    icon: <KeyboardArrowDown />,
    children: [
      // { id: 1, label: "Utilisateurs", link: "/app/gestionUtilisateur", icon: <Group /> },
      { id: 69, label: "Spécialités et fonctions", link: enaAdmin+"/speciality-fonction", icon: <LibraryIcon /> },
    ],
  },
  {
    id: 76,
    label: "Statistiques",
    // link: "/app/ui",
    icon: <KeyboardArrowDown />,
    children: [
      { id: 77, label: "Candidatures", link: enaAdmin+"/StateCandidature", icon: <LibraryIcon /> },
      { id: 77, label: "Inscriptions", link: enaAdmin+"/StateInscription", icon: <LibraryIcon /> },
    ],
  },
  { id: 5, type: "divider" },
  { id: 12, type: "title", label: "Comptabilité" },
  {
    id: 78,
    label: "Gestion des Frais",
    icon: <KeyboardArrowDown />,
    children: [
      { id: 79, label: "Frais", link: enaAdmin+"/GestionFrais", icon: <LibraryIcon /> },
    ],
  },
  {
    id: 80,
    label: "Gestion des Paiements",
    icon: <KeyboardArrowDown />,
    children: [
      { id: 81, label: "Paiements en attente", link: enaAdmin+"/GestionPaiements", icon: <LibraryIcon /> },
      { id: 82, label: "Paiements validés", link: enaAdmin+"/PaiementsFaits", icon: <LibraryIcon /> },
    ],
  },
  { id: 11, type: "divider" },
  { id: 14, type: "title", label: "Secrétariat"},
  {
    id: 83,
    label: "Gestion des Inscriptions",
    icon: <KeyboardArrowDown />,
    children: [
      { id: 84, label: "Inscriptions en attente", link: enaAdmin+"/InscriptionsAttente", icon: <LibraryIcon /> },
      { id: 85, label: "Inscriptions validées", link: enaAdmin+"/InscriptionsFaites", icon: <LibraryIcon /> },
    ],
  },
  { id: 13, type: "divider" },
  { id: 4, type: "title", label: "Utilisateurs" },
  {
    id: 50,
    label: "PR",
    // link: "/app/ui",
    icon: <KeyboardArrowDown />,
    children: [
      { id: 3, label: "Personnels", link: enaAdmin+"/personnels", icon: <DesktopWindows /> },
    ],
  },


  // { id: 6, type: "divider" },
  // { id: 7, type: "title", label: "Formateurs" },
  // { id: 9, type: "divider" },
  // { id: 10, type: "title", label: "Elevé fonctionnaire" },

  
  // { id: 6, type: "title", label: "Globale" },
  // { id: 33, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  // {
  //   id: 22,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: enaAdmin+"/app/ui/maps" },
  //   ],
  // },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      // style={{width: 500}}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
