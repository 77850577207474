import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select, Switch } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let search = "&search="
let multiple = true;
let user = JSON.parse(localStorage.getItem("user"));
export default function Repartitions() {
  const classes = useStyles();

  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [apivalide, setApiValide] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [typeconcours, setTypeconcours] = useState([]);
  const [salleList, setSalleList] = useState([]);
  const [show, setShow] = useState(true);
  const [centreconcours, setCentreconcours] = useState([]);
  const [profesion, setProfesion] = useState([]);
  var [open, setOpen] = useState(false);
  var [stateEtape, setStateEtape] = useState(0);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    schoolYear: "",
    step: 0,
    classroom__id: "",
    classroom__competitionCenter__id: "",
    candidature__programmationCategoryProfession__profession__id: "",
    candidature__programmationCategoryProfession__competitionCategory__id: ""
  });
  const [state, setState] = React.useState({
    schoolYear: {
      id: "",
    },
    competitionCategory: {
      id: "",
    },
    profession: {
      id: "",
    },
    step: 0,
    cleanCenterRepartition: true,
    competitionCenter: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    schoolYear: false,
    competitionCategory: false,
    profession: false,
    cleanCenterRepartition: false,
    step: false,
    competitionCenter: false,
  });

  React.useEffect(() => {
    (()=>{
      GetProfession(-1, -1)
      GetCategoryConcours(-1, -1)
      GetCentreConcours(-1, -1)
      GetSalleCentres(-1, -1)
      bindAnnees()
    })();
  }, []);

  const GetSalleCentres = async (page, size) => {
    Service.GetSalleCentres(page, size).then(
      async (response) => {
        setSalleList(response.data)
      })
}

  const GetCategoryConcours = async (page, size) => {
    Service.GetCategoryConcours(page, size).then(
      async (response) => {
        setTypeconcours(response.data)
      })
}


const GetCentreConcours = async (page, size) => {
  Service.GetCentreConcours(page, size).then(
    async (response) => {
      setCentreconcours(response.data)
    })
  }

const GetRepartionsSalle = async (page, size, search) => {
  setSpinner(true)
  Service.GetRepartionsSalle(page, size, recherche, search).then(
    async (response) => {
      console.log(response.data.content);
      setSpinner(false)
      setPage({
        page: page,
        size: size,
        totalElements: response.data.totalElements
      })
      // setReponse(response.data)
      setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
      });
    }
  const GetProfession = async (page, size) => {
    Service.GetCorps(page, size).then(
      async (response) => {
        setProfesion(response.data)
      })
}

const genererNumPlace = async () => {
  setSpinner(true);
  let data = {
    schoolYearID: state.schoolYear.id,
    step: state.step
  }
  Service.genererNumPlace(data).then(
    async (response) => {
      setSpinner(false);
      setOpen(false)
      toast.success(Constant.reponsesServeur.generationeffectuer, {position: "top-center", autoClose: 10000,})
    }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    }
  )
}

const OpenModal = async (etape) => {
  let d = []
  let multiple = true;
  setStateEtape(etape);
  setState(
    {
      schoolYear: {
        id: "",
      },
      competitionCategory: {
        id: "",
      },
      profession: {
        id: "",
      },
      step: 0,
      cleanCenterRepartition: true,
      competitionCenter: {
        id: "",
      },
    }
  )
  setOpen(true)
}


  const columns = [
      {
        name: "candidature.applicationNumber",
        label: "Numéro Dossier",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
        }
      },
      {
        name: "candidature.numPlace",
        label: "Numéro de place",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
        }
      },
      {
        name: "candidature.candidate.firstName",
        label: "Prénom(s)",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
        }
      },
      {
        name: "candidature.candidate.lastName",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
        }
      },
      {
        name: "surveillant.firstName",
        label: "Prénom(s)",
        options: {
          filter: true,
          sort: true,
          display: !show ? true: false
        }
      },
      {
        name: "surveillant.lastName",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
          display: !show ? true: false
        }
      },
      {
        label: "Père",
        name: "candidature.candidate",
        options: {
          filter: false,
          sort: false,
          display: show ? true: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{value !== undefined && value.fatherFirstName} {value !== undefined && value.fatherLastName}</div>;
          }
        }
      },
      {
        label: "Mère",
        name: "candidature.candidate",
        options: {
          filter: false,
          sort: false,display: show ? true: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{value !== undefined && value.motherFirstName} {value !== undefined && value.motherLastName}</div>;
          }
        }
      },
      {
        name: "candidature.candidate.birthdate",
        label: "Date de naissance",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false,
        }
      },
      {
        name: "candidature.candidate.birthplace",
        label: "Lieu de naissance",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false,
        }
      },
      {
        name: "candidature.candidate.gender",
        label: "Genre",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false,
        }
      },
      {
        name: "candidature.candidate.phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false,
        }
      },
      {
        name: "surveillant.phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
          display: !show ? true: false,
        }
      },
      {
        name: "candidature.formationGrade",
        label: "Diplôme",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
        }
      },
      {
        name: "classroom.acronym",
        label: "Salle",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "classroom.competitionCenter.name",
        label: "Centre",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "classroom.competitionCenter.academy.name",
        label: "Académie",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "step",
        label: "Etape",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidature.programmationCategoryProfession.schoolYear.year",
        label: "Année",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
         }
      },
      {
        name: "candidature.programmationCategoryProfession.competitionCategory.name",
        label: "Type de concours",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
         }
      },
      {
        name: "candidature.programmationCategoryProfession.profession.name",
        label: "Corps",
        options: {
          filter: true,
          sort: true,
          display: show ? true: false
         }
      },
      // {
      //   label: "Action",
      //   name: "id",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <div>
      //         <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteProgrammationConcours(tableMeta.rowData)}}> <Delete /> Supprimer </IconButton>
      //         {/* <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta)}}> <Edit /> Modifier </IconButton> */}
      //       </div>;
      //     }
      //   }
      // }
    ];


  const cliquer = async () => {
    if(state.schoolYear.id === ""){
      setError({...error, schoolYear: true})
    } else if(state.competitionCategory.id === ""){
      setError({...error, competitionCategory: true, schoolYear: false})
    } else if(state.profession.id === ""){
      setError({...error, profession: true, competitionCategory: false, schoolYear: false})
    } else if(state.competitionCenter.id === ""){
      setError({...error, profession: false, competitionCategory: false, schoolYear: false, competitionCenter: true})
    } else if(state.step <= 0){
      setError({...error, profession: false, competitionCategory: false, schoolYear: false, competitionCenter: false, step: true})
    } else {
        setError({...error, profession: false, competitionCategory: false, schoolYear: false, competitionCenter: false, step: false})
        setSpinner(true);
        let data = []
          state.competitionCenter.id.map((e)=>{
            if(e !== false){
              let d = {}
              d.cleanCenterRepartition = state.cleanCenterRepartition
              d.competitionCategory = state.competitionCategory
              d.profession = state.profession
              d.schoolYear = state.schoolYear
              d.step = parseInt(state.step)
              d.competitionCenter = {
                id: e
              }
              data.push(d)
            }
          })
          Service.PostRepartitionCompetionCenter(data).then(
            async (response) => {
              // GetRepartionsSalle(page.page, page.size, (search !== "&search=" ? search : ""))
              setSpinner(false);
              setOpen(false)
              toast.success(Constant.reponsesServeur.repartitioneffectuer, {position: "top-center", autoClose: 10000,})
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
    }
}



const afficher = async () => {
  search = "&search="
  if(recherche.candidature__programmationCategoryProfession__competitionCategory__id  !== ""){
    search = `${search}candidature__programmationCategoryProfession__competitionCategory__id%3D${recherche.candidature__programmationCategoryProfession__competitionCategory__id} AND `
  }
  if(recherche.candidature__programmationCategoryProfession__profession__id  !== ""){
    search = `${search}candidature__programmationCategoryProfession__profession__id%3D${recherche.candidature__programmationCategoryProfession__profession__id} AND `
  }
  if(recherche.classroom__id  !== ""){
    search =`${search}classroom__id%3D${recherche.classroom__id} AND `
  }
  if(recherche.classroom__competitionCenter__id  !== ""){
    search =`${search}classroom__competitionCenter__id%3D${recherche.classroom__competitionCenter__id} AND `
  }
  if(!show){
    search =`${search}candidature%3Dnull`
  } else {
    search =`${search}surveillant%3Dnull`
  }
  console.log(recherche);

  GetRepartionsSalle(page.page, page.size, (search !== "&search=" ? search : ""))
}

  return (
    <>
      <ToastContainer />
      <PageTitle title="Répartition des candidats dans les centres" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal(1)}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Générer les numéros de place
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={(e)=>{OpenModal(2)}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Nouvelle répartition
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={3}>
            <Select
                placeholder="Année"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, schoolYear: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  annee.length > 0 && annee.map((element) => {
                    return { "value": element.year, "label": element.year }
                  })
                }
              />
        </Grid>
        <Grid item xs={3}>
            <Select
                placeholder="Etape"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, step: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {Constant.etape}
              />
        </Grid>
        <Grid item xs={3}>
            <Select
                placeholder="Type de retour"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setShow(e)}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  [
                    {value: true, label: 'Liste des candidats'},
                    {value: false, label: 'Liste des surveillants'},
                  ]
                }
              />
        </Grid>
        <Grid item xs={3}>
            <Select
                placeholder="Salle"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, classroom__id: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  salleList.length > 0 && salleList.map((element) => {
                    return { "value": element.id, "label": element.acronym + " ("+element.numberPlace+")" }
                  })
                }
              />
        </Grid>
        <Grid item xs={3}>
            <Select
                placeholder="Type de concours"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, candidature__programmationCategoryProfession__competitionCategory__id: e})}
                options=
                {
                  typeconcours.length > 0 && typeconcours.map((element) => {
                    return { "value": element.id, "label": element.name }
                  })
                }
              />
        </Grid>
        <Grid item xs={3}>
            <Select
                placeholder="Corps"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, candidature__programmationCategoryProfession__profession__id: e})}
                options=
                {
                  profesion.length > 0 && profesion.map((element) => {
                    return { "value": element.id, "label": element.name + " ("+element.acronym+")" }
                  })
                }
              />
        </Grid>
        <Grid item xs={3}>
            <Select
                placeholder="Centre"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, classroom__competitionCenter__id: e})}
                options=
                {
                  centreconcours.length > 0 && centreconcours.map((element) => {
                    return { "value": element.id, "label": element.name + " ("+element.acronym+")" }
                  })
                }
              />
        </Grid>
        
        <Grid item xs={2}>
          <Button onClick={(e)=>afficher()} variant="contained" startIcon={<Add />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher
            </Button>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des candidats répartis"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetRepartionsSalle(currentPage, page.size, (search !== "&search=" ? search : ""))
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetRepartionsSalle(page.page, numberOfRows, (search !== "&search=" ? search : ""))
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Nouvelle répartition
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Année</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, schoolYear: {...state.schoolYear, id: e}})}
                                  defaultValue={state.schoolYear.id !== "" && state.schoolYear.id}
                                  options=
                                  {
                                    annee.length > 0 && annee.map((element) => {
                                      return { "value": element.id, "label": element.year }
                                    })
                                  }
                                />
                                {error.schoolYear && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                  <span>Etape</span>
                                  <br/>
                                  <Select
                                      showSearch
                                      filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                      }
                                      style={{ width: "100%", zIndex: 1}}
                                      onChange={(e)=>setState({...state, step: e})}
                                      defaultValue={state.step > 0 && state.step}
                                      options=
                                      {Constant.etape}
                                    />
                              </Grid>
                            {stateEtape === 2 &&
                            <>
                              <Grid item xs={12}>
                                  <span>Type du concours</span>
                                  <br/>
                                  <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{ width: "100%", zIndex: 1}}
                                    onChange={(e)=>setState({...state, competitionCategory: {...state.competitionCategory, id: e}})}
                                    defaultValue={state.competitionCategory.id !== "" && state.competitionCategory.id}
                                    options=
                                    {
                                      typeconcours.length > 0 && typeconcours.map((element) => {
                                        return { "value": element.id, "label": element.name }
                                      })
                                    }
                                  />
                                  {error.competitionCategory && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                              </Grid>
                              <Grid item xs={12}>
                                  <span>Corps</span>
                                  <br/>
                                  <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{ width: "100%", zIndex: 1}}
                                    onChange={(e)=>setState({...state, profession: {...state.profession, id: e}})}
                                    defaultValue={state.profession.id !== "" && state.profession.id}
                                    options=
                                    {
                                      profesion.length > 0 && profesion.map((element) => {
                                        return { "value": element.id, "label": element.name + " ("+element.acronym+")" }
                                      })
                                    }
                                  />
                                  {error.profession && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                              </Grid>
                              <Grid item xs={12}>
                                  <span>Centre</span>
                                  <br/>
                                  <Select
                                    showSearch
                                    mode={multiple ? "multiple" : ""}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{ width: "100%", zIndex: 1}}
                                    onChange={(e)=>setState({...state, competitionCenter: {...state.competitionCenter, id: e}})}
                                    defaultValue={state.competitionCenter.id !== "" && state.competitionCenter.id}
                                    options=
                                    {
                                      centreconcours.length > 0 && centreconcours.map((element) => {
                                        return { "value": element.id, "label": element.name + " ("+element.acronym+")" }
                                      })
                                    }
                                  />
                                  {error.competitionCenter && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                              </Grid>
                            </>
                            }
                            <Grid item xs={6}>
                            {stateEtape === 2 ?
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                Appliquer la répartition
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                              :
                              <Button variant="outlined" color="secondary" onClick={(e)=>genererNumPlace()} disabled={spinner}>
                                Générer les numéros
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            }
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
