import React, { useMemo, useState } from "react";
import { Button as ButtonMat, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Button, Divider, Select, Steps, theme, Image as ImageAntd  } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit, History, Search, Smartphone, Home, Image, LocalHospital, Mail, Person, PictureAsPdf, } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import countryList from 'react-select-country-list'
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../../components/Header/Header";

dayjs.extend(customParseFormat);

let search = ""
function ResultatCandidature() {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [annee, setAnnee] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [typeconcours, setTypeconcours] = useState([]);
  const [profession, setProfession] = useState([]);
  var [stateCherche, setStateCherche] = useState({
    id_year: 0,
    id_profession: 0,
    id_category: 0,
    status: "",
  });
  var [error, setError] = useState({
    annee: false,
    typeconcours: false,
    profession: false,
    status: false,
  });
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});

  React.useEffect(() => {
    (()=>{
      GetCurrentYear()
      GetCategoryConcours(-1, -1)
    })();
  }, []);


const GetCandidatures = async (page, size) => {
  if(stateCherche.id_profession <= 0) {
    setError({...error, profession: true})
  } else {
    setError({...error, profession: false})
    setSpinner(true)
    Service.GetCandidaturesListForCompetion(page, size, stateCherche).then(
      async (response) => {
        setSpinner(false)
          setPage({
            page: page,
            size: size,
            totalElements: response.data.totalElements
          })
          setReponse(response.data.content)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
  }
}

const getStatut = (value) => {
  if(value === "WAITING"){
    return "En attente";
  }
  if(value === "ACCEPT"){
    return "Accepté";
  }
  if(value === "REJECT_TEMPORARILY"){
    return "Rejet temporaire";
  }
  if(value === "REJECT_PERMANENT"){
    return "Rejet définitif";
  }
  if(value === "ADMITTED_PHASE_1"){
    return "Admis à la phase 1";
  }
  if(value === "ADMITTED_DEFINITIVELY"){
    return "Admis à la phase 2";
  }
}


  const columns = [
      {
        name: "applicationNumber",
        label: "Numéro Dossier",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.firstName",
        label: "Prénom(s)",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "candidate.lastName",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        label: "Père",
        name: "candidate",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{value.fatherFirstName} {value.fatherLastName}</div>;
          }
        }
      },
      {
        label: "Mère",
        name: "candidate",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{value.motherFirstName} {value.motherLastName}</div>;
          }
        }
      },
      {
        name: "candidate.birthdate",
        label: "Date de naissance",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return `${value.birthdate}`;
          // }
         }
      },
      {
        name: "candidate.birthplace",
        label: "Lieu de naissance",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return `${value.birthplace}`;
          // }
         }
      },
      {
        name: "candidate.gender",
        label: "Genre",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return `${value.gender}`;
          // }
         }
      },
      {
        name: "candidate.phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return `${value.phone}`;
          // }
         }
      },
      {
        name: "candidate.email",
        label: "Email",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return `${value.email}`;
          // }
         }
      },
      {
        name: "status",
        label: "Etat",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return getStatut(value);
          }
         }
      },
      {
        name: "id",
        label: "Motif",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let d = tableMeta.tableData[tableMeta.rowIndex];
            return (
              <div>
                {(d.typeRejection !== null && d.typeRejection !== undefined) && <p><strong>Type du rejet: </strong> {d.typeRejection.name} ({d.typeRejection.acronym})</p>}
                {(d.reasonRejection !== null && d.reasonRejection !== undefined) && <p><strong>Raison du rejet: </strong> {d.reasonRejection}</p>}
              </div>
            );
          }
         }
      },
    ];


const GetCategoryConcours = async (page, size) => {
  Service.GetCategoryConcours(page, size).then(
    async (response) => {
      setTypeconcours(response.data)
    })
}

const GetProfession = async () => {
  if(stateCherche.status.trim() === ""){
    setError({...error, status: true})
  } else if(stateCherche.id_year<= 0){
    setError({...error, annee: true, status: false})
  } else if(stateCherche.id_category<= 0){
    setError({...error, annee: false, typeconcours: true, status: false})
  } else {
    setError({...error, annee: false, typeconcours: false, status: false})
    setSpinner(true);
    Service.GetProgrammationParAnneeEtType(stateCherche.id_year, stateCherche.id_category).then(
      async (response) => {
        setSpinner(false);
        setProfession(response.data)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    )
  }
}

const GetCurrentYear = async () => {
  Service.GetCurrentYear().then(
    async (response) => {
      let data = []
      data.push(response.data)
      setAnnee(data);
    })
}


  return (
    <>
      <ToastContainer />
      <Header />
      <Grid container className={classes.container}>
          <Grid item sm={1}></Grid>
          <Grid item sm={10}>
            <PageTitle title="Résultats" />
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Select
                    showSearch
                    placeholder="Statut"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    dropdownStyle={{zIndex: 1300}}
                    onChange={(e)=>setStateCherche({...stateCherche, status: e})}
                    options=
                    {
                      [
                        { "value": "WAITING", "label": "En attente" },
                        { "value": "ACCEPT", "label": "Accepté" },
                        { "value": "REJECT_TEMPORARILY", "label": "Rejet temporaire" },
                        { "value": "REJECT_PERMANENT", "label": "Rejet définitif" },
                        { "value": "ADMITTED_PHASE_1", "label": "Admis à la phase 1" },
                        { "value": "ADMITTED_DEFINITIVELY", "label": "Admis à la phase 2" }
                      ]
                    }
                  />
                  {error.status && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
              </Grid>
              <Grid item sm={2}>
                <Select
                    showSearch
                    placeholder="Année"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    dropdownStyle={{zIndex: 1300}}
                    onChange={(e)=>setStateCherche({...stateCherche, id_year: e})}
                    options=
                    {
                      annee.length > 0 && annee.map((element) => {
                        return { "value": element.id, "label": element.year }
                      })
                    }
                  />
                  {error.annee && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
              </Grid>
              <Grid item sm={3}>
                    <Select
                      showSearch
                      placeholder="Type du concours"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setStateCherche({...stateCherche, id_category: e})}
                      options=
                      {
                        typeconcours.length > 0 && typeconcours.map((element) => {
                          return { "value": element.id, "label": element.name }
                        })
                      }
                    />
                    {error.typeconcours && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
              </Grid>
              <Grid item sm={3}>
                <ButtonMat size="small" onClick={(e)=>{GetProfession()}} variant="outlined" startIcon={<Search />} style={{color: 'white', borderColor: 'white'}}>
                    Afficher les corps
                </ButtonMat>
              </Grid>
              {profession.length > 0 &&
                <Grid item sm={3}>
                      <Select
                        showSearch
                        placeholder="Corps"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", zIndex: 1}}
                        onChange={(e)=>setStateCherche({...stateCherche, id_profession: e})}
                        options=
                        {
                          profession.length > 0 && profession.map((element) => {
                            return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                          })
                        }
                      />
                      {error.profession && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
                </Grid>
              }
              {profession.length > 0 &&
                <Grid item sm={2}>
                  <ButtonMat size="small" onClick={(e)=>{GetCandidatures(page.page, page.size)}} variant="outlined" startIcon={<Search />} style={{color: 'white', borderColor: 'white'}}>
                      Afficher
                  </ButtonMat>
                </Grid>
              }
              <Grid item xs={12}>
                {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
                <MUIDataTable
                  title="Résultats"
                  data={reponse}
                  columns={columns}
                  options={{
                    filter: true,
                    draggableColumns: { enabled: true },
                    enableNestedDataAccess: '.',
                    filterType: "multiselect",
                    jumpToPage: true,
                    serverSide: serverSide,
                    rowsPerPageOptions:[50, 100, 500, 1000],
                    rowsPerPage: page.size,
                    count: page.totalElements,
                    textLabels: Constant.textLabels,
                    selectableRows: "none",
                    setFilterChipProps: (colIdx, colName, data) => {
                      return {
                        label: `${colName}: ${data}`,
                      }
                    },
                    onTableChange(action, tableState) {
                      if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                        setServerSide(false)
                      }
                    },
                    onChangePage(currentPage) {
                      setServerSide(true)
                      GetCandidatures(currentPage, page.size)
                    },
                    onChangeRowsPerPage(numberOfRows) {
                      setServerSide(true)
                      GetCandidatures(page.page, numberOfRows)
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={1}></Grid>
      </Grid>
    </>
  );
}

export default withRouter(ResultatCandidature);