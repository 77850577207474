import React, { useMemo, useState } from "react";
import { Button as ButtonMat, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Button, Divider, Select, Steps, theme, Image as ImageAntd  } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit, History, Search, Smartphone, Home, Image, LocalHospital, Mail, Person, PictureAsPdf, } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";
import logo from '../../../assets/logo.png'
import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import countryList from 'react-select-country-list'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const dateFormat = 'DD-MM-YYYY';

const steps = [
  {
    title: 'Informations personnelles',
    description: "Ce sont les informations concernant le candidat lui même",
  },
  {
    title: 'Parcours professionnel',
    description: "Ce sont les informations concernant le parcours du candidat",
  },
  {
    title: 'Corps',
    description: "C'est la catégorie de profession à laquelle le candidat souhaite postuler",
  },
  {
    title: 'Documents',
    description: "Le candidat doit soumettre des documents pour le traitement de son dossier",
  },
  {
    title: 'Récapitulatif',
    description: "Vérifiez les renseignements que vous fournissez avant de les envoyer.",
  },
  {
    title: 'Félicitation',
    description: "",
  },
];

let typeFiles = {
  idPhoto: "idPhoto",
  birthCertificate: "birthCertificate",
  nationalityCertificate: "nationalityCertificate",
  diploma: "diploma",
  referenceEquivalencyLetter: "referenceEquivalencyLetter",
  integrationOrder: "integrationOrder",
  authorizationParticipate: "authorizationParticipate",
  medicalCertificate: "medicalCertificate",
}

let user = JSON.parse(localStorage.getItem("user"));
let type = "SERVICE"
let type2 = "Candidatures"
let autreService= "autre"
let autreProfession= "autre"
let reasonRejection= ""
let statusRejet= ""
let typeRejectionId= 0
let search = ""
export default function GestionCandidature() {
  const classes = useStyles();
  const { token } = theme.useToken();
  let http = process.env.REACT_APP_API_HTTP
  const contentStyle: React.CSSProperties = {
    minHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: "white",
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    // marginTop: 16,
  };
  const [spinner, setSpinner] = useState(false);
  const [serverSide, setServerSide] = useState(true);
  const [rejetConfirm, setRejetConfirm] = useState(false);
  const [reponse, setReponse] = useState([]);
  var [stateCherche, setStateCherche] = useState({
    firstName: "",
    applicationNumber: "",
    lastName: "",
    phone: "",
    status: "",
  });
  var [open, setOpen] = useState({modal: false, action: 0});
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [state, setState] = React.useState({
    id: "",
    description: "",
    name: "",
    phone: "",
    email: "",
    type: type,
    service: {
      id: 0
    }
  });


  // Pour postuler
  var formdata = new FormData();
  var [corps, setCorps] = useState({
    schoolYear: "",
    competitionCategory: "",
    profession: ""
  });
  const options = useMemo(() => countryList().getData(), [])
  const [annee, setAnnee] = useState([]);
  const [typeconcours, setTypeconcours] = useState([]);
  const [service, setService] = useState([]);
  const [profession, setProfession] = useState([]);
  var [concerns, setConcerns] = useState("");
  const [programmation, setProgrammation] = useState([]);

  var [stateCandidatureFind, setStateCandidatureFind] = useState({
      firstName: "",
      lastName: "",
      birthdate: "",
      applicationReference: ""
  })
  var [stateCandidature, setStateCandidature] = useState({
    applicationReference: "",
    candidate: {
      id: ""
    }
  })
  var [stateCandidate, setstateCandidate] = useState({
    firstName: "",
    lastName: "",
    birthdate: "",
    birthplace: "",
    gender: "",
    phone: "",
    email: "",
    address: "",
    fatherFirstName: "",
    fatherLastName: "",
    motherFirstName: "",
    motherLastName: "",
    emergencyContactFullName: "",
    emergencyContactPhone: "",
    emergencyContactAddress: "",
    handicap: '',
    // matriculeNumber: "",
    // handicapNature: "",
    // handicapAssistance: "",
    // office: {
    //   id: 0
    // }
  });
  var [error, setError] = useState({
    firstName: false,
    lastName: false,
    birthdate: false,
    birthplace: false,
    gender: false,
    phone: false,
    email: false,
    address: false,
    fatherFirstName: false,
    fatherLastName: false,
    motherFirstName: false,
    motherLastName: false,
    emergencyContactFullName: false,
    emergencyContactPhone: false,
    referenceEquivalencyLetter: false,
    emergencyContactAddress: false,
    handicap: false,
    handicapNature: false,
    applicationReference: false,
    formationGrade: false,
    formationSpeciality: false,
    formationInstitution: false,
    formationCountry: false,
    diplomaDateIssue: false,
    schoolYear: false,
    competitionCategory: false,
    profession: false,
    matriculeNumber: false,
    office: false,
    serviceName: false,
    professionName: false,
    servicePhone: false,
    typeRejectionId: false,
    reasonRejection: false,
    statusRejet: false,
    corps: false
  });
  var [current, setCurrent] = useState(0);
  var [handicapAssistance, setHandicapAssistance] = useState([]);
  var [stateCorps, setStateCorps] = useState([]);
  var [typeRejet, setTypeRejet] = useState([]);
  var [stateHandicapNature, setStateHandicapNature] = useState([]);


  React.useEffect(() => {
    (()=>{
      // GetCandidatures(page.page, page.size, "")
      // Pour postuler
      GetCurrentYear()
      GetCategoryConcours(-1, -1)
      GetService(-1, -1)
      GetHandicapAssistance(-1, -1)
      GetHandicapNature(-1, -1)
      GetCorps(-1, -1)
      GetTypeRejet(-1, -1)
    })();
  }, []);

  const GetTypeRejet = async (page, size) => {
    Service.GetTypeRejet(page, size).then(
      async (response) => {
        setTypeRejet(response.data)
      })
}

  const GetCandidatures = async (page, size, search) => {
    setSpinner(true)
    Service.GetCandidatures(page, size, search).then(
      async (response) => {
        setSpinner(false)
          setPage({
            page: page,
            size: size,
            totalElements: response.data.totalElements
          })
          setReponse(response.data.content)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}

const afficher = async () => {
  search = "&search="
  if(stateCherche.status.trim() !== ""){
    search = `${search}status%3D${stateCherche.status} AND `
  }
  if(stateCherche.applicationNumber.trim() !== ""){
    search = `${search}applicationNumber%3D${stateCherche.applicationNumber} AND `
  }
  if(stateCherche.firstName.trim() !== ""){
    search =`${search}candidate__firstName%3D${stateCherche.firstName} AND `
  }
  if(stateCherche.lastName.trim() !== ""){
    search =`${search}candidate__lastName%3D${stateCherche.lastName} AND `
  }
  if(stateCherche.phone.trim() !== ""){
    search =`${search}candidate__phone%3D${stateCherche.phone} AND `
  }
  GetCandidatures(page.page, page.size, (search !== "&search=" ? search.slice(0, -5) : ""))
}

const OpenModal = async (action, value) => {
  if(action === 0){
    initialisation()
    setCurrent(0)
  } else if(action === 1){
    remplirCandidature(reponse.filter(x => x.id === value)[0])
    setCurrent(4)
  } else if(action === 2){
    setCurrent(0)
    remplirCandidature(reponse.filter(x => x.id === value)[0])
  }
  setOpen({...open, modal: true, action: action})
}

const initialisation = () => {
  setStateCandidature({
    applicationReference: "",
    candidate: {
      id: ""
    }
  })
  setstateCandidate({
    firstName: "",
    lastName: "",
    birthdate: "",
    birthplace: "",
    gender: "",
    phone: "",
    email: "",
    address: "",
    fatherFirstName: "",
    fatherLastName: "",
    motherFirstName: "",
    motherLastName: "",
    emergencyContactFullName: "",
    emergencyContactPhone: "",
    emergencyContactAddress: "",
    handicap: '',
    // matriculeNumber: "",
    // handicapNature: "",
    // handicapAssistance: "",
    // office: {
    //   id: 0
    // }
  });
  setCorps({
    schoolYear: "",
    competitionCategory: "",
    profession: ""
  })
};

  const deleteCandidatures = async (id) => {
    setSpinner(true)
    Service.deleteCandidatures(id).then(
      async (response) => {
        afficher()
        setSpinner(false)
        toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}

  const columns = [
      {
        name: "applicationNumber",
        label: "Numéro Dossier",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.fullName",
        label: "Nom complet",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.birthdate",
        label: "Date de naissance",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.birthplace",
        label: "Lieu de naissance",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.gender",
        label: "Genre",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.email",
        label: "Email",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <ButtonMat onClick={(e)=>{OpenModal(1, value)}} variant="outlined" startIcon={<Add />} style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>
                Détails
              </ButtonMat>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(Constant.cornfirmation))deleteCandidatures(value)}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {OpenModal(2, value)}}> <Edit /> Modifier </IconButton>
            </div>;
          }
        }
      }
    ];



// Pour postuler


const GetCorps = async (page, size) => {
  Service.GetProfession(page, size).then(
    async (response) => {
      let data = [
        {"value": "autre", "label": `Autre profession`}
      ]
      response.data.length > 0 && response.data.map((element, index) => {
        data.push({ "value": element.id, "label": `${element.name}` })
        if((index + 1) === response.data.length) {
          setProfession(data)
        }
      })
    })
}

const GetHandicapAssistance = async (page, size) => {
  Service.GetHandicapAssistance(page, size).then(
    async (response) => {
      setHandicapAssistance(response.data)
    })
}
const GetHandicapNature = async (page, size) => {
Service.GetHandicapNature(page, size).then(
  async (response) => {
    setStateHandicapNature(response.data)
  })
}

const GetService = async (page, size) => {
  Service.GetServiceCandidats(page, size).then(
    async (response) => {
      let data = [
        {"value": "autre", "label": `Autre service`}
      ]
      response.data.length > 0 && response.data.map((element, index) => {
        data.push({ "value": element.id, "label": `${element.name}` })
        if((index + 1) === response.data.length) {
          setService(data)
        }
      })
    })
}

const GetCategoryConcours = async (page, size) => {
  Service.GetCategoryConcours(page, size).then(
    async (response) => {
      setTypeconcours(response.data)
    })
}

const GetCurrentYear = async () => {
  Service.GetCurrentYear().then(
    async (response) => {
      let data = []
      data.push(response.data)
      setAnnee(data);
    })
}



const envoieCandidature = () => {
  setSpinner(true);
  Service.PutCandidatures(stateCandidature).then(
    async (response) => {
      remplirCandidature(response.data);
      setSpinner(false);
      setCurrent(current + 1);
      toast.success("Votre candidature à été envoyé avec succès", {position: "top-center", autoClose: 10000,})
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    }
  )
};


const next = () => {
  if(current === 0 || current === 1 || current === 2) {
    candidat()
  } else if (current === 3) {
    uploadFiles(-1)
  }
};

const changeStepPut = (current) => {
  if (current === 3) {
    uploadFiles(current)
  } else if(verify() === true) {
    setCurrent(current);
  }
};

const verify = () => {
  if(stateCandidate.firstName.trim() === ""){
    setError({...error, firstName: true})
  }else if(stateCandidate.lastName.trim() === ""){
    setError({...error, firstName: false, lastName: true})
  }
  else if(stateCandidate.birthdate.trim() === ""){
    setError({...error, birthdate: true, firstName: false, lastName: false})
  }
  else if(stateCandidate.birthplace.trim() === ""){
    setError({...error, birthplace:true, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.gender.trim() === ""){
    setError({...error, gender: true, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.phone.trim() === ""){
    setError({...error, phone: true, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.email.trim() === "" || !stateCandidate.email.includes("@")){
    setError({...error, email: true, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.address.trim() === ""){
    setError({...error, address: true, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.fatherFirstName.trim() === ""){
    setError({...error, fatherFirstName: true, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.fatherLastName.trim() === ""){
    setError({...error, fatherLastName: true, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.motherFirstName.trim() === ""){
    setError({...error, motherFirstName: true, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.motherLastName.trim() === ""){
    setError({...error, motherLastName: true, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.emergencyContactFullName.trim() === ""){
    setError({...error, emergencyContactFullName: true, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.emergencyContactPhone.trim() === ""){
    setError({...error, emergencyContactPhone: true, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.emergencyContactAddress.trim() === ""){
    setError({...error, emergencyContactAddress: true, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.handicap === ""){
    setError({...error, handicap: true, emergencyContactAddress: false, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.handicap && (stateCandidate.handicapNature === undefined || stateCandidate.handicapNature === null || stateCandidate.handicapNature.id <=0)){
    setError({...error, handicapNature: true, handicap: false, emergencyContactAddress: false, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  } 
  else if(current === 1 && stateCandidature.formationGrade === null){
    setError({...error, formationGrade: true, matriculeNumber: false})
  } else if(current === 1 && (stateCandidature.formationSpeciality === null || stateCandidature.formationSpeciality.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: true})
  } else if(current === 1 && (stateCandidature.formationInstitution === null || stateCandidature.formationInstitution.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: false, formationInstitution: true})
  } else if(current === 1 && (stateCandidature.diplomaDateIssue === null || stateCandidature.diplomaDateIssue.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: false, formationInstitution: false, diplomaDateIssue: true})
  } else if(current === 1 && (stateCandidature.formationCountry === null || stateCandidature.formationCountry.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: false, formationInstitution: false, diplomaDateIssue: false, formationCountry: true})
  } else if(current === 2 && corps.profession === ""){
    setError({...error, profession: true})
  } 
  else if(current === 2 && stateCandidate.corps !== undefined && stateCandidate.corps !== null && stateCandidate.corps.id === autreProfession && stateCandidate.corps.name === undefined){
    setError({...error, professionName: true})
  }
  else if(current === 2 && stateCandidate.office !== undefined && stateCandidate.office !== null && stateCandidate.office.id === autreService && stateCandidate.office.name === undefined){
    setError({...error, serviceName: true})
  } 
  else if(current === 2 && stateCandidate.office !== undefined && stateCandidate.office !== null && stateCandidate.office.id === autreService && stateCandidate.office.phone === undefined){
    setError({...error, servicePhone: true, serviceName: false})
  } 
  else {
    setError({...error, professionName: false, servicePhone: false, serviceName: false, office: false, matriculeNumber: false, profession: false, handicapNature: false, handicap: false, emergencyContactAddress: false, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false, formationGrade: false, formationSpeciality: false, formationInstitution: false, diplomaDateIssue: false, formationCountry: false})
    return true;
  }
}

const candidat = () => {
  if(stateCandidate.firstName.trim() === ""){
    setError({...error, firstName: true})
  }else if(stateCandidate.lastName.trim() === ""){
    setError({...error, firstName: false, lastName: true})
  }
  else if(stateCandidate.birthdate.trim() === ""){
    setError({...error, birthdate: true, firstName: false, lastName: false})
  }
  else if(stateCandidate.birthplace.trim() === ""){
    setError({...error, birthplace:true, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.gender.trim() === ""){
    setError({...error, gender: true, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.phone.trim() === ""){
    setError({...error, phone: true, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.email.trim() === "" || !stateCandidate.email.includes("@")){
    setError({...error, email: true, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.address.trim() === ""){
    setError({...error, address: true, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.fatherFirstName.trim() === ""){
    setError({...error, fatherFirstName: true, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.fatherLastName.trim() === ""){
    setError({...error, fatherLastName: true, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.motherFirstName.trim() === ""){
    setError({...error, motherFirstName: true, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.motherLastName.trim() === ""){
    setError({...error, motherLastName: true, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.emergencyContactFullName.trim() === ""){
    setError({...error, emergencyContactFullName: true, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.emergencyContactPhone.trim() === ""){
    setError({...error, emergencyContactPhone: true, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.emergencyContactAddress.trim() === ""){
    setError({...error, emergencyContactAddress: true, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.handicap === ""){
    setError({...error, handicap: true, emergencyContactAddress: false, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  }
  else if(stateCandidate.handicap && (stateCandidate.handicapNature === undefined || stateCandidate.handicapNature === null || stateCandidate.handicapNature.id <=0)){
    setError({...error, handicapNature: true, handicap: false, emergencyContactAddress: false, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false})
  } 
  else if(current === 1 && stateCandidature.formationGrade === null){
    setError({...error, formationGrade: true, matriculeNumber: false})
  } else if(current === 1 && (stateCandidature.formationSpeciality === null || stateCandidature.formationSpeciality.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: true})
  } else if(current === 1 && (stateCandidature.formationInstitution === null || stateCandidature.formationInstitution.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: false, formationInstitution: true})
  } else if(current === 1 && (stateCandidature.diplomaDateIssue === null || stateCandidature.diplomaDateIssue.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: false, formationInstitution: false, diplomaDateIssue: true})
  } else if(current === 1 && (stateCandidature.formationCountry === null || stateCandidature.formationCountry.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: false, formationInstitution: false, diplomaDateIssue: false, formationCountry: true})
  } else if(current === 1 && stateCandidature.formationCountry.trim() !== "Mali" && (stateCandidature.referenceEquivalencyLetter === null || stateCandidature.referenceEquivalencyLetter.trim() === "")){
    setError({...error, matriculeNumber: false, formationGrade: false, formationSpeciality: false, formationInstitution: false, diplomaDateIssue: false, formationCountry: false, referenceEquivalencyLetter: true})
  } else if(current === 2 && corps.profession === ""){
    setError({...error, profession: true})
  } else if(current === 2 && concerns === Constant.concernFonctionnaire && stateCandidate.matriculeNumber === null){
    setError({...error, profession: false, matriculeNumber: true})
  } else if((current === 2 && concerns === Constant.concernFonctionnaire && stateCandidate.corps === null)){
    setError({...error, profession: false, matriculeNumber: false, corps: true})
  } else if((current === 2 && concerns === Constant.concernFonctionnaire && stateCandidate.office === null)){
    setError({...error, profession: false, matriculeNumber: false, corps: false, office: true})
  }
  else if(current === 2 && stateCandidate.office !== undefined && stateCandidate.office !== null && stateCandidate.office.id === autreService && stateCandidate.office.name === undefined){
    setError({...error, serviceName: true})
  } 
  else if(current === 2 && stateCandidate.office !== undefined && stateCandidate.office !== null && stateCandidate.office.id === autreService && stateCandidate.office.phone === undefined){
    setError({...error, servicePhone: true, serviceName: false})
  } 
  else {
    setError({...error, servicePhone: false, serviceName: false, office: false, matriculeNumber: false, profession: false, handicapNature: true, handicap: false, emergencyContactAddress: false, emergencyContactPhone: false, emergencyContactFullName: false, motherLastName: false, motherFirstName: false, fatherLastName: false, fatherFirstName: false, address: false, email: false, phone: false, gender: false, birthplace:false, birthdate: false, firstName: false, lastName: false, formationGrade: false, formationSpeciality: false, formationInstitution: false, diplomaDateIssue: false, formationCountry: false, referenceEquivalencyLetter: false})
    setSpinner(true);
    // setStateCandidature({...stateCandidature, candidate: stateCandidate})
    let data = {}
    if((stateCandidature.id !== undefined)) {
      data = stateCandidature
      data.candidate = stateCandidate
      if(current === 2){
        if(stateCandidate.office !== null && stateCandidate.office !== undefined && stateCandidate.office.id === autreService) {
          delete stateCandidate.office.id;
        }
        if(stateCandidate.corps !== null && stateCandidate.corps !== undefined && stateCandidate.corps.id === autreProfession) {
          // data.candidate.corps.id = "";
          delete stateCandidate.corps.id;
          delete stateCandidate.corps.description;
        }
        data.programmationCategoryProfession = {
          competitionCategory: {
            id: corps.competitionCategory
          },
          schoolYear: {
            id: corps.schoolYear
          },
          profession: {
            id: corps.profession
          }
        }
      }
    } else {
      data = {
        candidate: stateCandidate
      }
    }
    // convert Date
    if(stateCandidature.diplomaDateIssue !== null && stateCandidature.diplomaDateIssue !== undefined && stateCandidature.diplomaDateIssue.trim() !== "") {
      data.diplomaDateIssue = convertDatePourEnvoie(stateCandidature.diplomaDateIssue)
    }
    data.candidate.birthdate = convertDatePourEnvoie(stateCandidate.birthdate)
    
    
    Service.candidates(data).then(
      async (response) => {
        remplirCandidature(response.data);
        setSpinner(false);
        setCurrent(current + 1);
        afficher();
      }, error => {
        setSpinner(false);
        if(error.response.data.status === 422) {
          toast.error("Ce numéro de téléphone est déjà utilisé", {position: "top-center", autoClose: 10000,})
        } else {
          toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
        }
      }
    )
  }
};

const modificationCandidature = () => {
  if(verify() === true) {
    setSpinner(true);
    let data = {}
    if((stateCandidature.id !== undefined)) {
      data = stateCandidature
      data.candidate = stateCandidate
      if(current === 2){
        if(stateCandidate.office !== null && stateCandidate.office !== undefined && stateCandidate.office.id === autreService) {
          delete stateCandidate.office.id;
        }
        data.programmationCategoryProfession = {
          competitionCategory: {
            id: corps.competitionCategory
          },
          schoolYear: {
            id: corps.schoolYear
          },
          profession: {
            id: corps.profession
          }
        }
      }
    } else {
      data = {
        candidate: stateCandidate
      }
    }
    Service.PutCandidatures(data).then(
      async (response) => {
        setSpinner(false);
        afficher();
        setOpen({...open, modal: false})
        toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    )
  }
};

const uploadFiles = (currentUp) => {
  if(formdata.getAll("files_type").length > 0) {
    setSpinner(true);
    Service.uploadFiles(formdata).then(
      async (response) => {
        response.data.forEach((value, index) => {
          if(value.type === typeFiles.idPhoto) {
            stateCandidature.idPhotoFile= value.url
          } else if(value.type === typeFiles.birthCertificate) {
            stateCandidature.birthCertificateFile= value.url
          } else if(value.type === typeFiles.nationalityCertificate) {
            stateCandidature.nationalityCertificateFile= value.url
          } else if(value.type === typeFiles.diploma) {
            stateCandidature.diplomaFile= value.url
          } else if(value.type === typeFiles.referenceEquivalencyLetter) {
            stateCandidature.referenceEquivalencyLetterFile= value.url
          } else if(value.type === typeFiles.integrationOrder) {
            stateCandidature.integrationOrderFile= value.url
          } else if(value.type === typeFiles.authorizationParticipate) {
            stateCandidature.authorizationParticipateFile= value.url
          } else if(value.type === typeFiles.medicalCertificate) {
            stateCandidature.medicalCertificateFile= value.url
          }
          if((index + 1) === response.data.length) {
            if(currentUp !== -1) {
              setCurrent(currentUp);
            } else {
              candidat()
            }
          }
        })
      }, error => {
          setSpinner(false);
          toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    )
  } else {
    setCurrent(current + 1);
  }
};

const accepter = (status, idRejet, reasonRejection) => {
  let data = {}
  data = stateCandidature
  data.status = status
  if(status.includes("REJECT")) {
    if(idRejet <= 0) {
      setError({...error, typeRejectionId: true})
      return null;
    } else if(reasonRejection.trim() === "") {
      setError({...error, typeRejectionId: false, reasonRejection: true})
      return null;
    } else if(status.trim() === "") {
      setError({...error, typeRejectionId: false, reasonRejection: false, statusRejet: true})
      return null;
    }
    setError({...error, typeRejectionId: false, reasonRejection: false, statusRejet: false})
    data.typeRejection= {
      id: idRejet
    }
    data.reasonRejection= reasonRejection
  }
  modificationStatus(data)
};

const modificationStatus = (data) => {
    setSpinner(true);
    if((stateCandidature.id !== undefined)) {
      data.candidate = stateCandidate
      if(current === 2){
        if(stateCandidate.office !== null && stateCandidate.office !== undefined && stateCandidate.office.id === autreService) {
          delete stateCandidate.office.id;
        }
        data.programmationCategoryProfession = {
          competitionCategory: {
            id: corps.competitionCategory
          },
          schoolYear: {
            id: corps.schoolYear
          },
          profession: {
            id: corps.profession
          }
        }
      }
    } else {
      data = {
        candidate: stateCandidate
      }
    }
    Service.PutCandidaturesProcessing(data).then(
      async (response) => {
        setSpinner(false);
        afficher();
        setOpen({...open, modal: false})
        toast.success(data.status.includes("REJECT") ? Constant.reponsesServeur.rejeteffectuer : Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    )
};

const prev = () => {
  setCurrent(current - 1);
};

const convertDate = (date) => {
  if(date.split('-')[0].length === 4) {
    return dayjs(date.split('-')[2]+"-"+date.split('-')[1]+"-"+date.split('-')[0], dateFormat)
  } else {
    return dayjs(date, dateFormat)
  }
};

const convertDatePourEnvoie = (date) => {
  let d = "";
  if(typeof(date) === "string") {
    d = date
  } else {
    d = date.toISOString().slice(0,10).replace(/-/g,"-")
  }
  if(d.split('-')[2].length === 4) {
    return d.split('-')[2]+"-"+d.split('-')[1]+"-"+d.split('-')[0]
  } else {
    return d
  }
};

const onChange = (date, dateString) => {
  // let d = dateString.split('-')[2]+"-"+dateString.split('-')[1]+"-"+dateString.split('-')[0]
  setstateCandidate({...stateCandidate, birthdate: dateString})
};

const onChangeProfessionDate = (date, dateString) => {
  // let d = dateString.split('-')[2]+"-"+dateString.split('-')[1]+"-"+dateString.split('-')[0]
  setStateCandidature({...stateCandidature, diplomaDateIssue: dateString})
};

const onChange2 = (date, dateString) => {
  // let d = dateString.split('-')[2]+"-"+dateString.split('-')[1]+"-"+dateString.split('-')[0]
  setStateCandidatureFind({...stateCandidatureFind, birthdate: dateString})
};

const handleChange = (value) => {
  setstateCandidate({...stateCandidate, gender: value})
};

const handleChangeFormationGrade = (value) => {
  setStateCandidature({...stateCandidature, formationGrade: value})
};

const handleChangeHandicap = (value) => {
  setstateCandidate({...stateCandidate, handicap: value === "Oui" ? true : false})
};

const remplirCandidature = (data) => {
  setStateCandidature(data);
  setstateCandidate(data.candidate);
  setConcerns((data.programmationCategoryProfession!== null && data.programmationCategoryProfession!== undefined) ? data.programmationCategoryProfession.competitionCategory.concerns : "")
  setCorps({...corps,
    schoolYear: data.programmationCategoryProfession === null ? "" : data.programmationCategoryProfession.schoolYear.id, 
    competitionCategory: data.programmationCategoryProfession === null ? "" : data.programmationCategoryProfession.competitionCategory.id,
    profession: data.programmationCategoryProfession === null ? "" : data.programmationCategoryProfession.profession.id})
};

const Chercher = () => {
  setSpinner(true);
  let data = {}
    data.firstName = stateCandidatureFind.firstName.trim()
    data.lastName = stateCandidatureFind.lastName.trim()
    data.applicationReference = stateCandidatureFind.applicationReference.trim()
    data.birthdate = stateCandidatureFind.birthdate.split('-')[2]+"-"+stateCandidatureFind.birthdate.split('-')[1]+"-"+stateCandidatureFind.birthdate.split('-')[0]
     
  Service.CandidatureFind(data).then(
    async (response) => {
      remplirCandidature(response.data);
      setSpinner(false);
      toast.success("Candidat trouvé avec succès", {position: "top-center", autoClose: 10000,})
      // getAPIsList()
    
    }, error => {
      setSpinner(false);
      if(error.response !== undefined && error.response.data.status === 404) {
        toast.error("Aucun candidat trouvé avec les informations fournies", {position: "top-center", autoClose: 10000,})
      } else {
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    }
  )
};

const ChercherProgrammation = () => {
  if(corps.schoolYear === ""){
    setError({...error, schoolYear: true})
  } else if(corps.competitionCategory === ""){
    setError({...error, schoolYear: false, competitionCategory: true})
  } else {
      setError({...error, schoolYear: false, competitionCategory: false})
      setSpinner(true);
      Service.GetProgrammationParAnneeEtType(corps.schoolYear, corps.competitionCategory).then(
        async (response) => {
          // setCorps({...corps, profession: 0})
          setProgrammation(response.data)
          setSpinner(false);
          setConcerns(typeconcours.filter((element) => element.id === corps.competitionCategory)[0].concerns)
        }, error => {
          setSpinner(false);
          toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
        }
      )
  }
};

const changeHandlerFormationPays = value => {
  setStateCandidature({...stateCandidature, formationCountry: countryList().getLabel(value)})
}

const chargeFormData = (files, type) => {
  if(files.target.files[0] !== undefined) {
    let extention = files.target.files[0].type.split("/").pop();
    if(formdata.getAll("files_type").includes(type)){
      formdata.set("files_type", type);
      formdata.set("files", files.target.files[0], `${type}.${extention}`);
    } else {
      formdata.append("files_type", type);
      formdata.append("files", files.target.files[0], `${type}.${extention}`);
    }
  }
}

const printDocument = () => {
      setSpinner(true);
      let data = {
        applicationReference: stateCandidature.applicationNumber,
        firstName: stateCandidature.candidate.firstName,
        lastName: stateCandidature.candidate.lastName,
        birthdate: stateCandidature.candidate.birthdate
    }
      Service.GetCandidaturePdfFile(data).then(
        async (response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${data.firstName}_${data.lastName}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setSpinner(false);
        }, error => {
          setSpinner(false);
          toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
        }
      )

  // const input = document.getElementById('divToPrint');
  // html2canvas(input, {useCORS: true, allowTaint: true, scrollY: 0 })
  //   .then((canvas) => {
  //     const image = { type: 'jpeg', quality: 0.98 };
  //     const margin = [0.5, 0.5];
  //     const filename = `${prenom}_${nom}.pdf`;

  //     var imgWidth = 8.5;
  //     var pageHeight = 11;

  //     var innerPageWidth = imgWidth - margin[0] * 2;
  //     var innerPageHeight = pageHeight - margin[1] * 2;

  //     // Calculate the number of pages.
  //     var pxFullHeight = canvas.height;
  //     var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
  //     var nPages = Math.ceil(pxFullHeight / pxPageHeight);

  //     // Define pageHeight separately so it can be trimmed on the final page.
  //     var pageHeight = innerPageHeight;

  //     // Create a one-page canvas to split up the full image.
  //     var pageCanvas = document.createElement('canvas');
  //     var pageCtx = pageCanvas.getContext('2d');
  //     pageCanvas.width = canvas.width;
  //     pageCanvas.height = pxPageHeight;

  //     // Initialize the PDF.
  //     var pdf = new jsPDF('p', 'in', [8.5, 11]);

  //     for (var page = 0; page < nPages; page++) {
  //       // Trim the final page to reduce file size.
  //       if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
  //         pageCanvas.height = pxFullHeight % pxPageHeight;
  //         pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
  //       }

  //       // Display the page.
  //       var w = pageCanvas.width;
  //       var h = pageCanvas.height;
  //       pageCtx.fillStyle = 'white';
  //       pageCtx.fillRect(0, 0, w, h);
  //       pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

  //       // Add the page to the PDF.
  //       if (page > 0) pdf.addPage();
  //       // debugger;
  //       var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
  //       pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
  //     }
  //     pdf.text("pierre diarra", 20, 20)

  //     pdf.save(filename);
  //   });
}


  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des candidatures" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <ButtonMat onClick={(e)=>{OpenModal(0, 0)}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Ajouter une candidature
          </ButtonMat>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={2}>
          <Select
              showSearch
              placeholder="Statut"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: "100%", zIndex: 1}}
              dropdownStyle={{zIndex: 1300}}
              onChange={(e)=>setStateCherche({...stateCherche, status: e})}
              options=
              {
                [
                  { "value": "WAITING", "label": "En attente" },
                  { "value": "ACCEPT", "label": "Accepté" },
                  { "value": "REJECT_TEMPORARILY", "label": "Rejet temporaire" },
                  { "value": "REJECT_PERMANENT", "label": "Rejet définitif" }
                ]
              }
            />
        </Grid>
        <Grid item sm={2}>
              <TextField onChange={(e)=>setStateCherche({...stateCherche, applicationNumber: e.target.value})} size="medium" placeholder="Numéro Dossier"  fullWidth
                  InputProps={{
                    style: {background: 'white'},
                    startAdornment: (
                      <InputAdornment position="start">
                        <Edit />
                      </InputAdornment>
                    ),
                  }}
                />
          </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, firstName: e.target.value})} size="medium" placeholder="Prénom"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, lastName: e.target.value})} size="medium" placeholder="Nom"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, phone: e.target.value})} size="medium" placeholder="Téléphone"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item sm={2}>
          <ButtonMat size="small" onClick={(e)=>{afficher()}} variant="outlined" startIcon={<Search />} style={{color: 'white', borderColor: 'white'}}>
              Afficher
          </ButtonMat>
        </Grid>
        <Grid item sm={2}>
          <ButtonMat size="small" onClick={(e)=>{GetCandidatures(page.page, page.size, "")}} variant="outlined" startIcon={<History />} style={{color: 'white', borderColor: 'white'}}>
              Actualiser
          </ButtonMat>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Listes des candidatures"
            data={reponse}
            columns={columns}
            options={{
              filter: true,
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              serverSide: serverSide,
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetCandidatures(currentPage, page.size, (search !== "search=" ? search : ""))
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetCandidatures(page.page, numberOfRows, (search !== "search=" ? search : ""))
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open.modal}
        onClose={(e)=>setOpen({...open, modal: false})}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200, overflow: 'scroll'}}
      >
        <div>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                  <Paper square style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                      <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                        Nouvelle candidature
                      </h1>
                      <div>
                          <Grid container>
                                <Grid item sm={1}></Grid>
                                <Grid item sm={2}>
                                  <div className={classes.steps}>
                                    <Steps
                                        direction="vertical"
                                        current={current}
                                        items={steps}
                                      />
                                  </div>
                                </Grid>
                                {/* <Grid item sm={1}></Grid> */}
                                <Grid item sm={8} style={{paddingLeft: 20}}>
                                {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
                                <div style={contentStyle}>
                                  <div style={{lineHeight: 0.5, paddingTop: 10}}>
                                    <h1>{steps[current].title}</h1>
                                    <small>{steps[current].description}</small>
                                  </div>
                      {/* Pour afficher la reference du candidat */}
                                {(stateCandidature.applicationReference !== null && stateCandidature.applicationReference !== undefined && stateCandidature.applicationReference.trim() !== "") &&
                                    <div style={{backgroundColor: "rgba(0,255,0, 0.2)", padding: 10, marginTop: 10}}>
                                        <Grid container spacing={3}>
                                            <Grid item sm={12}>
                                                <p>La référence de votre candidature est : <strong style={{color: 'red'}}>{stateCandidature.applicationReference}</strong></p>
                                                <p><strong>Veuillez bien le garder, vous pouvez en avoir besoin</strong></p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                      {/* POUR CHERCHER UN CANDIDAT */}
                                {(current === 0 && stateCandidature.applicationReference !== undefined && stateCandidature.applicationReference.trim() === "") &&
                                    <div style={{backgroundColor: "rgba(255,0,0, 0.2)", padding: 10, marginTop: 10}}>
                                        <Grid container spacing={3}>
                                            <Grid item sm={12}>
                                                <p><strong>Si vous avez déjà envoyé vos informations une fois. Veuillez saisir la référence de votre candidature pour retrouver vos informations</strong></p>
                                            </Grid>
                                            <Grid item sm={3}>
                                                    <TextField value={stateCandidatureFind.firstName} onChange={(e)=> setStateCandidatureFind({...stateCandidatureFind, firstName: e.target.value})} size="small" label="* Prénom" variant="outlined" fullWidth
                                                      InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment position="start">
                                                            <Person />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <TextField value={stateCandidatureFind.lastName} onChange={(e)=> setStateCandidatureFind({...stateCandidatureFind, lastName: e.target.value})} size="small" label="* Nom de famille" variant="outlined" fullWidth
                                                      InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment position="start">
                                                            <Person />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <DatePicker onChange={onChange2} placeholder="* Date de naissance" style={{ width: '100%' }} popupStyle={{ zIndex: 1300}} size="large" format='DD-MM-YYYY'/>
                                                </Grid>
                                                <Grid item sm={3}>
                                                  <TextField onChange={(e)=> setStateCandidatureFind({...stateCandidatureFind, applicationReference: e.target.value})} size="small" label="Référence" variant="outlined" fullWidth/>
                                                </Grid>
                                                <Grid item sm={12}>
                                                  <Button onClick={(e)=>{Chercher()}} variant="outlined" style={{color: 'white', backgroundColor: 'black'}}>
                                                    Lancer la recherche
                                                  </Button>
                                                </Grid>
                                        </Grid>
                                    </div>
                                }

                      {/* Si c'est les info personnelles  */}
                                  {current === 0 && 
                                      <Grid container spacing={3} style={{padding: 20}}>
                                        <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Identification</Divider>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.firstName} error={error.firstName} helperText={error.firstName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, firstName: e.target.value})} size="small" label="* Prénom" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.lastName} error={error.lastName} helperText={error.lastName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, lastName: e.target.value})} size="small" label="* Nom de famille" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <DatePicker value={stateCandidate.birthdate !== "" && convertDate(stateCandidate.birthdate)} onChange={onChange} placeholder="* Date de naissance" style={{ width: '100%', }} popupStyle={{ zIndex: 1300}} size="large" format='DD-MM-YYYY'/>
                                                <small style={{color: 'red'}}>{error.birthdate && Constant.champObligatoire}</small>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.birthplace} error={error.birthplace} helperText={error.birthplace && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, birthplace: e.target.value})} size="small" label="* Lieu de naissance" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Home />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <div style={{textAlign: 'initial'}}>* Genre</div>
                                                <Select
                                                    value={stateCandidate.gender === "" ? null : stateCandidate.gender}
                                                    size="large"
                                                    style={{ width: "100%" }}
                                                    dropdownStyle={{zIndex: 1300}}
                                                    onChange={handleChange}
                                                    options={[
                                                      { value: 'HOMME', label: 'HOMME' },
                                                      { value: 'FEMME', label: 'FEMME' },
                                                    ]}
                                                  />
                                                  <small style={{color: 'red'}}>{error.gender && Constant.champObligatoire}</small>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.phone} error={error.phone} helperText={error.phone && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, phone: e.target.value})} size="small" label="* Téléphone" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Smartphone />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.email} error={error.email} helperText={error.email && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, email: e.target.value})} size="small" label="* Email" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Mail />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.address} error={error.address} helperText={error.address && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, address: e.target.value})} size="small" label="* Adresse" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Home />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>

                                        <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>Parents</Divider>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.fatherFirstName} error={error.fatherFirstName} helperText={error.fatherFirstName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, fatherFirstName: e.target.value})} size="small" label="* Prénom du père" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.fatherLastName} error={error.fatherLastName} helperText={error.fatherLastName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, fatherLastName: e.target.value})} size="small" label="* Nom du père" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.motherFirstName} error={error.motherFirstName} helperText={error.motherFirstName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, motherFirstName: e.target.value})} size="small" label="* Prénom de la mère" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.motherLastName} error={error.motherLastName} helperText={error.motherLastName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, motherLastName: e.target.value})} size="small" label="* Nom de la mère" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>

                                        <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>Personne à contacter en cas d'urgence</Divider>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.emergencyContactFullName} error={error.emergencyContactFullName} helperText={error.emergencyContactFullName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, emergencyContactFullName: e.target.value})} size="small" label="* Nom complèt" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.emergencyContactPhone} error={error.emergencyContactPhone} helperText={error.emergencyContactPhone && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, emergencyContactPhone: e.target.value})} size="small" label="* Téléphone" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Smartphone />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField value={stateCandidate.emergencyContactAddress} error={error.emergencyContactAddress} helperText={error.emergencyContactAddress && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, emergencyContactAddress: e.target.value})} size="small" label="* Adresse" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Home />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>

                                        <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>Handicap</Divider>
                                            <Grid item sm={3}>
                                                <div style={{textAlign: 'initial'}}>* Avez-vous un handicap</div>
                                                <Select
                                                    value={stateCandidate.handicap === "" ? null : (stateCandidate.handicap ? "Oui" : "Non")}
                                                    size="large"
                                                    style={{ width: "100%" }}
                                                    dropdownStyle={{zIndex: 1300}}
                                                    onChange={handleChangeHandicap}
                                                    options={[
                                                      { value: 'Oui', label: 'Oui' },
                                                      { value: 'Non', label: 'Non' },
                                                    ]}
                                                  />
                                                  <small style={{color: 'red'}}>{error.handicap && Constant.champObligatoire}</small>
                                            </Grid>
                                            {stateCandidate.handicap &&
                                              <Grid item sm={3}>
                                                    <div style={{textAlign: 'initial'}}>* Nature de l'handicap</div>
                                                    <Select
                                                        value={(stateCandidate.handicapNature !== undefined && stateCandidate.handicapNature !== null) ? stateCandidate.handicapNature.id : null}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        style={{ width: "100%", zIndex: 1}}
                                                        dropdownStyle={{zIndex: 1300}}
                                                        onChange={(e)=>setstateCandidate({...stateCandidate, handicapNature: {...stateCandidate.handicapNature, id: e}})}
                                                        options=
                                                        {
                                                          stateHandicapNature.length > 0 && stateHandicapNature.map((element) => {
                                                            return { "value": element.id, "label": `${element.name}` }
                                                          })
                                                        }
                                                      />
                                                    {error.handicapNature && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                              </Grid>
                                            }
                                            {stateCandidate.handicap &&
                                              <Grid item sm={3}>
                                                <div style={{textAlign: 'initial'}}>Assistant pour l'handicap</div>
                                                <Select
                                                        value={(stateCandidate.handicapAssistance !== undefined && stateCandidate.handicapAssistance !== null) ? stateCandidate.handicapAssistance.id : null}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        style={{ width: "100%", zIndex: 1}}
                                                        dropdownStyle={{zIndex: 1300}}
                                                        onChange={(e)=>setstateCandidate({...stateCandidate, handicapAssistance: {...stateCandidate.handicapAssistance, id: e}})}
                                                        options=
                                                        {
                                                          handicapAssistance.length > 0 && handicapAssistance.map((element) => {
                                                            return { "value": element.id, "label": `${element.name}` }
                                                          })
                                                        }
                                                      />
                                                    {error.handicapAssistance && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                              </Grid>
                                            }
                                      
                                      </Grid>
                                  }
                      {/* Si c'est le parcours  */}
                                  {current === 1 && 
                                    <Grid container spacing={3} style={{padding: 20}}>
                                            <Grid item sm={6}>
                                                <div style={{textAlign: 'initial'}}>* Niveau de la formation</div>
                                                <Select
                                                    showSearch
                                                    value={(stateCandidature.formationGrade === "" || stateCandidature.formationGrade === null) ? null : stateCandidature.formationGrade}
                                                    size="large"
                                                    style={{ width: "100%" }}
                                                    dropdownStyle={{zIndex: 1300}}
                                                    onChange={handleChangeFormationGrade}
                                                    options={[
                                                      { value: 'CAP', label: 'CAP' },
                                                      { value: 'DUT', label: 'DUT' },
                                                      { value: 'BT2', label: 'BT2' },
                                                      { value: 'LICENCE', label: 'LICENCE' },
                                                      { value: 'MAITRISE', label: 'MAITRISE' },
                                                      { value: 'MASTER', label: 'MASTER' },
                                                      { value: 'DOCTORAT', label: 'DOCTORAT' },
                                                    ]}
                                                  />
                                                  <small style={{color: 'red'}}>{error.formationGrade && Constant.champObligatoire}</small>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <TextField value={stateCandidature.formationSpeciality !== null ? stateCandidature.formationSpeciality : ""} error={error.formationSpeciality} helperText={error.formationSpeciality && Constant.champObligatoire} onChange={(e)=> setStateCandidature({...stateCandidature, formationSpeciality: e.target.value})} size="small" label="* Spécialité de la formation" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <TextField value={stateCandidature.formationInstitution !== null ? stateCandidature.formationInstitution : ""} error={error.formationInstitution} helperText={error.formationInstitution && Constant.champObligatoire} onChange={(e)=> setStateCandidature({...stateCandidature, formationInstitution: e.target.value})} size="small" label="* Etablissement de formation" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <DatePicker value={(stateCandidature.diplomaDateIssue !== "" && stateCandidature.diplomaDateIssue !== null) && convertDate(stateCandidature.diplomaDateIssue)} onChange={onChangeProfessionDate} placeholder="* Date d'obtention du diplôme" style={{ width: '100%' }} popupStyle={{ zIndex: 1300}} size="large" format='DD-MM-YYYY'/>
                                                <small style={{color: 'red'}}>{error.diplomaDateIssue && Constant.champObligatoire}</small>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <div style={{textAlign: 'initial'}}>* Pays de la formation</div>
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    value={(stateCandidature.formationCountry === "" || stateCandidature.formationCountry === null) ? null : countryList().getValue(stateCandidature.formationCountry)}
                                                    size="large"
                                                    style={{ width: "100%" }}
                                                    options={options}
                                                    onChange={changeHandlerFormationPays}
                                                  />
                                                  <small style={{color: 'red'}}>{error.formationCountry && Constant.champObligatoire}</small>
                                            </Grid>
                                            {stateCandidature.formationCountry !== "Mali" && 
                                              <Grid item sm={6}>
                                                  <TextField value={stateCandidature.referenceEquivalencyLetter !== null ? stateCandidature.referenceEquivalencyLetter : ""} error={error.referenceEquivalencyLetter} helperText={error.referenceEquivalencyLetter && Constant.champObligatoire} onChange={(e)=> setStateCandidature({...stateCandidature, referenceEquivalencyLetter: e.target.value})} size="small" label="* Numéro de la lettre d'équivalence" variant="outlined" fullWidth
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          <Person />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                              </Grid>
                                            }
                                    </Grid>
                                  }
                      {/* Si c'est les corps  */}
                                  {current === 2 && 

                                    <Grid container spacing={3} style={{padding: 20}}>
                                        <Grid item sm={4}>
                                                  <div style={{textAlign: 'initial'}}>* Année</div>
                                                  <Select
                                                    value={corps.schoolYear !== "" ? corps.schoolYear : null}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    style={{ width: "100%", zIndex: 1}}
                                                    dropdownStyle={{zIndex: 1300}}
                                                    onChange={(e)=>{setCorps({...corps, schoolYear: e}); setConcerns(""); setProgrammation([])}}
                                                    options=
                                                    {
                                                      annee.length > 0 && annee.map((element) => {
                                                        return { "value": element.id, "label": element.year }
                                                      })
                                                    }
                                                  />
                                                  {error.schoolYear && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                        </Grid>
                                        <Grid item sm={4}>
                                              <div style={{textAlign: 'initial'}}>* Type du concours</div>
                                              <Select
                                                value={corps.competitionCategory !== "" ? corps.competitionCategory : null}
                                                showSearch
                                                filterOption={(input, option) =>
                                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ width: "100%", zIndex: 1}}
                                                dropdownStyle={{zIndex: 1300}}
                                                onChange={(e)=>{setCorps({...corps, competitionCategory: e}); setConcerns(""); setProgrammation([])}}
                                                options=
                                                {
                                                  typeconcours.length > 0 && typeconcours.map((element) => {
                                                    return { "value": element.id, "label": element.name }
                                                  })
                                                }
                                              />
                                              {error.competitionCategory && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Button onClick={(e)=>{ChercherProgrammation()}} variant="outlined" style={{color: 'white', backgroundColor: 'black'}}>
                                              Afficher les corps 
                                            </Button>
                                        </Grid>
                                        {programmation.length > 0 &&
                                          <Grid item sm={4}>
                                                <div style={{textAlign: 'initial'}}>* Corps</div>
                                                <Select
                                                  value={corps.profession !== "" ? corps.profession : null}
                                                  showSearch
                                                  filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                  }
                                                  style={{ width: "100%", zIndex: 1}}
                                                  dropdownStyle={{zIndex: 1300}}
                                                  onChange={(e)=>setCorps({...corps, profession: e})}
                                                  options=
                                                  {
                                                    programmation.length > 0 && programmation.map((element) => {
                                                      return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                                                    })
                                                  }
                                                />
                                                {error.profession && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                          </Grid>
                                        }
                                        {concerns === Constant.concernFonctionnaire &&
                                          <Grid item sm={4}>
                                                <TextField error={error.matriculeNumber} value={stateCandidate.matriculeNumber !== null ? stateCandidate.matriculeNumber : ""} helperText={error.matriculeNumber && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, matriculeNumber: e.target.value})} size="small" label="* Numéro matricule" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                        }
                                        {concerns === Constant.concernFonctionnaire &&
                                          <Grid item sm={4}>
                                                <div style={{textAlign: 'initial'}}>* Profession</div>
                                                <Select
                                                  value={(stateCandidate.corps !== undefined && stateCandidate.corps !== null) ? stateCandidate.corps.id : null}
                                                  showSearch
                                                  filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                  }
                                                  style={{ width: "100%", zIndex: 1}}
                                                  dropdownStyle={{zIndex: 1300}}
                                                  onChange={(e)=>setstateCandidate({...stateCandidate, corps: {...stateCandidate.corps, id: e}})}
                                                  options=
                                                  {
                                                    profession
                                                  }
                                                />
                                                {error.corps && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                          </Grid>
                                        }
                                        {(stateCandidate.corps !== undefined && stateCandidate.corps !== null && stateCandidate.corps.id=== autreProfession) &&
                                            <Grid item sm={4}>
                                                <TextField error={error.professionName} helperText={error.professionName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, corps: {...stateCandidate.corps, name: e.target.value}})} size="small" label="* Nom de la profession" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                        }
                                        {concerns === Constant.concernFonctionnaire &&
                                          <Grid item sm={4}>
                                                <div style={{textAlign: 'initial'}}>* Service</div>
                                                <Select
                                                  value={(stateCandidate.office !== undefined && stateCandidate.office !== null) ? stateCandidate.office.id : null}
                                                  showSearch
                                                  filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                  }
                                                  style={{ width: "100%", zIndex: 1}}
                                                  dropdownStyle={{zIndex: 1300}}
                                                  onChange={(e)=>setstateCandidate({...stateCandidate, office: {...stateCandidate.office, id: e}})}
                                                  options=
                                                  {
                                                    service
                                                  }
                                                />
                                                {error.office && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                          </Grid>
                                        }
                                        {(stateCandidate.office !== undefined && stateCandidate.office !== null && stateCandidate.office.id=== autreService) &&
                                            <Grid item sm={4}>
                                                <TextField error={error.serviceName} helperText={error.serviceName && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, office: {...stateCandidate.office, name: e.target.value}})} size="small" label="* Nom du service" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                        }
                                        {(stateCandidate.office !== undefined && stateCandidate.office !== null && stateCandidate.office.id=== autreService) &&
                                            <Grid item sm={4}>
                                                <TextField error={error.servicePhone} helperText={error.servicePhone && Constant.champObligatoire} onChange={(e)=> setstateCandidate({...stateCandidate, office: {...stateCandidate.office, phone: e.target.value}})} size="small" label="* Téléphone du service" variant="outlined" fullWidth
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Person />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                            </Grid>
                                        }
                                        
                                    </Grid>
                                  }
                      {/* Si c'est les dossiers  */}
                                  {current === 3 && 
                                    <Grid container spacing={3} style={{padding: 20}}>
                                      <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Identification</Divider>
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>* Photo d'identité</p>
                                            <TextField inputProps={{accept:"image/*"}} type="file" onChange={(e)=> chargeFormData(e, typeFiles.idPhoto)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.idPhotoFile !==  undefined && stateCandidature.idPhotoFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <Image />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>* Acte de naissance</p>
                                            <TextField inputProps={{accept:"application/pdf"}} type="file" error={error.birthCertificateFile} helperText={error.birthCertificateFile && Constant.champObligatoire} onChange={(e)=> chargeFormData(e, typeFiles.birthCertificate)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.birthCertificateFile !==  undefined && stateCandidature.birthCertificateFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <PictureAsPdf />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>* Certificat de nationalité</p>
                                            <TextField inputProps={{accept:"application/pdf"}} type="file" error={error.nationalityCertificateFile} helperText={error.nationalityCertificateFile && Constant.champObligatoire} onChange={(e)=> chargeFormData(e, typeFiles.nationalityCertificate)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.nationalityCertificateFile !==  undefined && stateCandidature.nationalityCertificateFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <PictureAsPdf />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                    <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>Parcours</Divider>
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>* Diplôme d'étude</p>
                                            <TextField inputProps={{accept:"application/pdf"}} type="file" error={error.diplomaFile} helperText={error.diplomaFile && Constant.champObligatoire} onChange={(e)=> chargeFormData(e, typeFiles.diploma)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.diplomaFile !==  undefined && stateCandidature.diplomaFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <PictureAsPdf />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                    {stateCandidature.formationCountry !== "Mali" && 
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>Lettre d’équivalence</p>
                                            <TextField inputProps={{accept:"application/pdf"}} type="file" helperText={<strong style={{color: 'red'}}>* Obligatoire pour les étrangers</strong>} onChange={(e)=> chargeFormData(e, typeFiles.referenceEquivalencyLetter)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.referenceEquivalencyLetterFile !==  undefined && stateCandidature.referenceEquivalencyLetterFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <PictureAsPdf />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                  }
                                  {concerns === Constant.concernFonctionnaire &&
                                    <>
                                      <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>Pour les fonctionnaires</Divider>
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>* Arrêter d’intégration ou d’avancement dans la catégorie B2</p>
                                            <TextField helperText={<strong style={{color: 'red'}}>{Constant.champObligatoireFonctionnaire}</strong>} inputProps={{accept:"application/pdf"}} type="file" onChange={(e)=> chargeFormData(e, typeFiles.integrationOrder)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.integrationOrderFile !==  undefined && stateCandidature.integrationOrderFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <PictureAsPdf />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>Autorisation de participation</p>
                                            <TextField inputProps={{accept:"application/pdf"}} type="file" helperText={<strong style={{color: 'red'}}>{Constant.champObligatoireFonctionnaire}</strong>} onChange={(e)=> chargeFormData(e, typeFiles.authorizationParticipate)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.authorizationParticipateFile !==  undefined && stateCandidature.authorizationParticipateFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <PictureAsPdf />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                    </>
                                  }
                                  {stateCandidate.handicap &&
                                  <>
                                    <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>Handicap</Divider>
                                        <Grid item sm={4}>
                                            <p style={{textAlign: 'left', marginBottom: 0}}>* Certificat de visite médicale</p>
                                            <TextField helperText={<strong style={{color: 'red'}}>* Obligatoire pour les personnes en situation d'handicap</strong>} inputProps={{accept:"application/pdf"}} type="file" onChange={(e)=> chargeFormData(e, typeFiles.medicalCertificate)} size="small" variant="outlined" fullWidth
                                              InputProps={{
                                                style: { background: (stateCandidature.medicalCertificateFile !==  undefined && stateCandidature.medicalCertificateFile !== null) ? "rgba(0,255,0,0.2)" : "" },
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <PictureAsPdf />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                        </Grid>
                                    </>
                                    }
                                  
                                  </Grid>
                                  }
                      {/* Pour le recapitulatif */}
                                  {current === 4 &&
                                    <Grid container spacing={3} style={{padding: 20, lineHeight:1, textAlign: 'left'}}>
                                      <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Informations personnelles</Divider>
                                        <Grid item sm={2}>
                                          <ImageAntd preview={{zIndex: 1300, src:`${http}://${stateCandidature.idPhotoFile}`}} src={`${http}://${stateCandidature.idPhotoFile}`} />
                                        </Grid>
                                        <Grid item sm={10}>
                                          <Grid container spacing={3} style={{left: 0, lineHeight:1, textAlign: 'left'}}>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Prénom:</strong> {stateCandidature.candidate.firstName}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Nom:</strong> {stateCandidature.candidate.lastName}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Date de naissance:</strong> {stateCandidature.candidate.birthdate}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Lieu de naissance:</strong> {stateCandidature.candidate.birthplace}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Genre:</strong> {stateCandidature.candidate.gender}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Téléphone:</strong> {stateCandidature.candidate.phone}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Email:</strong> {stateCandidature.candidate.email}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Adresse:</strong> {stateCandidature.candidate.address}</Grid>
                                              <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Parents</Divider>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Père:</strong> {stateCandidature.candidate.fatherFirstName} {stateCandidature.candidate.fatherLastName}</Grid>
                                            <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Mère:</strong> {stateCandidature.candidate.motherFirstName} {stateCandidature.candidate.motherLastName}</Grid>
                                              <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Personne à contacter</Divider>
                                            <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Nom:</strong> {stateCandidature.candidate.emergencyContactFullName}</Grid>
                                            <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Téléphone:</strong> {stateCandidature.candidate.emergencyContactPhone}</Grid>
                                            <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Adresse:</strong> {stateCandidature.candidate.emergencyContactAddress}</Grid>
                                              <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Handicap</Divider>
                                            <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Handicap:</strong> {stateCandidature.candidate.handicap ? "Oui" : "Non"}</Grid>
                                            {stateCandidature.candidate.handicap && <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Nature:</strong> {stateCandidature.candidate.handicapNature.name}</Grid>}
                                            {stateCandidature.candidate.handicap && <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Assistance:</strong> {stateCandidature.candidate.handicapAssistance.name}</Grid>}
                                          </Grid>
                                        </Grid>
                                      <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Parcours professionnel</Divider>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Niveau:</strong> {stateCandidature.formationGrade}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Spécialité:</strong> {stateCandidature.formationSpeciality}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Etablissement:</strong> {stateCandidature.formationInstitution}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Date d'obtention:</strong> {stateCandidature.diplomaDateIssue}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Pays:</strong> {stateCandidature.formationCountry}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Numéro matricule:</strong> {stateCandidature.candidate.matriculeNumber !== null && stateCandidature.candidate.matriculeNumber}</Grid>
                                      <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Corps</Divider>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Année:</strong> {stateCandidature.programmationCategoryProfession.schoolYear.year}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Type du concours:</strong> {stateCandidature.programmationCategoryProfession.competitionCategory.name}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Corps:</strong> {stateCandidature.programmationCategoryProfession.profession.name} ({stateCandidature.programmationCategoryProfession.profession.acronym})</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Service:</strong> {stateCandidature.candidate.office !== null && stateCandidature.candidate.office.name}</Grid>
                                        <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Spécialité:</strong> {stateCandidature.candidate.corps !== null && stateCandidature.candidate.corps.name}</Grid>
                                      <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Documents</Divider>
                                        {stateCandidature.birthCertificateFile !== null &&
                                          <Grid item sm={2} style={{textAlign: 'center'}}>
                                            <a href={`${http}://${stateCandidature.birthCertificateFile}`} target="_blank">
                                              <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                              <br/>
                                              <strong style={{textDecoration: 'underline'}}>Acte de naissance</strong>
                                            </a>
                                          </Grid>
                                        }
                                        {stateCandidature.nationalityCertificateFile !== null &&
                                          <Grid item sm={2} style={{textAlign: 'center'}}>
                                            <a href={`${http}://${stateCandidature.nationalityCertificateFile}`} target="_blank">
                                              <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                              <br/>
                                              <strong style={{textDecoration: 'underline'}}>Certificat de nationalité</strong>
                                            </a>
                                          </Grid>
                                        }
                                        {stateCandidature.diplomaFile !== null &&
                                          <Grid item sm={2} style={{textAlign: 'center'}}>
                                            <a href={`${http}://${stateCandidature.diplomaFile}`} target="_blank">
                                              <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                              <br/>
                                              <strong style={{textDecoration: 'underline'}}>Diplôme</strong>
                                            </a>
                                          </Grid>
                                        }
                                        {stateCandidature.referenceEquivalencyLetterFile !== null &&
                                          <Grid item sm={2} style={{textAlign: 'center'}}>
                                            <a href={`${http}://${stateCandidature.referenceEquivalencyLetterFile}`} target="_blank">
                                              <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                              <br/>
                                              <strong style={{textDecoration: 'underline'}}>Lettre d’équivalence</strong>
                                            </a>
                                          </Grid>
                                        }
                                        {stateCandidature.integrationOrderFile !== null &&
                                          <Grid item sm={2} style={{textAlign: 'center'}}>
                                            <a href={`${http}://${stateCandidature.integrationOrderFile}`} target="_blank">
                                              <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                              <br/>
                                              <strong style={{textDecoration: 'underline'}}>Arrêter d’intégration ou d’avancement dans la catégorie B2</strong>
                                            </a>
                                          </Grid>
                                        }
                                        {stateCandidature.authorizationParticipateFile !== null &&
                                          <Grid item sm={2} style={{textAlign: 'center'}}>
                                            <a href={`${http}://${stateCandidature.authorizationParticipateFile}`} target="_blank">
                                              <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                              <br/>
                                              <strong style={{textDecoration: 'underline'}}>Autorisation de participation</strong>
                                            </a>
                                          </Grid>
                                        }
                                        {stateCandidature.medicalCertificateFile !== null &&
                                          <Grid item sm={2} style={{textAlign: 'center'}}>
                                            <a href={`${http}://${stateCandidature.medicalCertificateFile}`} target="_blank">
                                              <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                              <br/>
                                              <strong style={{textDecoration: 'underline'}}>Certificat de visite médicale</strong>
                                            </a>
                                          </Grid>
                                        }
                                    </Grid>
                                  }

                      {/* Pour la terminaison */}
                                  {current === 5 &&
                                  <>
                                    <div style={{padding: 20, textAlign: 'center'}}>
                                      <h4>Votre candidature à été envoyé avec succès</h4>
                                      <h4>Votre numéro de dossier est <strong style={{color: 'red', fontSize: 20}}>{stateCandidature.applicationNumber}</strong></h4>
                                      <h4 style={{color: 'green'}}>Veuillez bien le garder, vous en aurez besoin</h4>
                                      <h4 style={{color: 'red'}}>NB : Veuillez télécharger, signer et ajouter votre fiche d'inscription à vos pièces physiques</h4>
                                      <Button type="primary" onClick={() => printDocument()} disabled={spinner}>
                                        Télécharger sa fiche d'inscription
                                      </Button>
                                    </div>
                                  </>
                                  }
                                </div>
                                {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
                                    <div style={{ marginTop: 24, marginBottom: 50}}>
                                        <Button style={{ margin: '0 8px', float: 'right' }} onClick={() => setOpen({...open, modal: false})}>
                                          Fermer
                                        </Button>
                                        {open.action === 0 &&
                                            <div>
                                                {(current > 0 && current < steps.length - 1) && (
                                                  <Button style={{ margin: '0 8px' }} onClick={() => prev()} disabled={spinner}>
                                                    Précédente
                                                  </Button>
                                                )}
                                                {current === steps.length - 2 && (
                                                  <Button type="primary" onClick={() => envoieCandidature()} disabled={spinner}>
                                                    Envoyer
                                                  </Button>
                                                )}
                                                {current < steps.length - 2 && (
                                                  <Button type="primary" onClick={() => next()} disabled={spinner}>
                                                    Suivante
                                                  </Button>
                                                )}
                                            </div>
                                        }
                                        {open.action ===  1 &&
                                            <div>
                                                  <Button style={{backgroundColor: 'green', color: 'white'}} onClick={() => {if(window.confirm(Constant.cornfirmation))accepter("ACCEPT", 0, "")}} >
                                                    Accepter
                                                  </Button>
                                                  <Button style={{margin: '0 8px', backgroundColor: 'red', color: 'white'}} onClick={() => {setRejetConfirm(!rejetConfirm)}} disabled={spinner}>
                                                    {!rejetConfirm ? "Rejeter" : "Annuler le rejet"}
                                                  </Button>
                                                  {rejetConfirm &&
                                                    <Grid container spacing={3} style={{margin: 10, border: "1px solid red"}}>
                                                      <Grid item sm={4}>
                                                          <div style={{textAlign: 'initial'}}>* Type de rejet</div>
                                                          <Select
                                                            showSearch
                                                            size="large"
                                                            filterOption={(input, option) =>
                                                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            style={{ width: "100%", zIndex: 1}}
                                                            dropdownStyle={{zIndex: 1300}}
                                                            onChange={(e)=>typeRejectionId = e}
                                                            options=
                                                            {
                                                              typeRejet.length > 0 && typeRejet.map((element) => {
                                                                return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                                                              })
                                                            }
                                                          />
                                                          {error.typeRejectionId && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                                        </Grid>
                                                        <Grid item sm={3}>
                                                          <TextField error={error.reasonRejection} helperText={error.reasonRejection && Constant.champObligatoire} onChange={(e)=> reasonRejection= e.target.value} size="small" label="* Raison du rejet" variant="outlined" fullWidth
                                                              InputProps={{
                                                                startAdornment: (
                                                                  <InputAdornment position="start">
                                                                    <Person />
                                                                  </InputAdornment>
                                                                ),
                                                              }}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={3}>
                                                            <div style={{textAlign: 'initial'}}>* Statut du rejet</div>
                                                            <Select
                                                                showSearch
                                                                size="large"
                                                                filterOption={(input, option) =>
                                                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                style={{ width: "100%", zIndex: 1}}
                                                                dropdownStyle={{zIndex: 1300}}
                                                                onChange={(e)=>statusRejet = e}
                                                                options=
                                                                {
                                                                  [
                                                                    { "value": "REJECT_TEMPORARILY", "label": "Rejet temporaire" },
                                                                    { "value": "REJECT_PERMANENT", "label": "Rejet définitif" }
                                                                  ]
                                                                }
                                                              />
                                                              {error.statusRejet && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                                                        </Grid>
                                                        <Grid item sm={2}>
                                                          <Button style={{margin: '0 8px', backgroundColor: 'red', color: 'white'}} onClick={() => {if(window.confirm(Constant.cornfirmation))accepter(statusRejet, typeRejectionId, reasonRejection)}} disabled={spinner}>
                                                            Valider le rejet
                                                          </Button>
                                                        </Grid>
                                                      </Grid>
                                                  }
                                            </div>
                                        }
                                        {open.action === 2 &&
                                            <div>
                                                {(current > 0 && current < steps.length - 1) && (
                                                  <Button onClick={() => setCurrent(current - 1)} >
                                                    Précédente
                                                  </Button>
                                                )}
                                                {
                                                  <Button style={{ margin: '0 8px' }} type="primary" onClick={() => modificationCandidature()} disabled={spinner}>
                                                    Modifier
                                                  </Button>
                                                }
                                                {current < steps.length - 2 && (
                                                  <Button onClick={() => changeStepPut(current + 1)}>
                                                    Suivante
                                                  </Button>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </Grid>
                                <Grid item sm={1}></Grid>
                            </Grid>
                      </div>
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                </Grid>
              </Grid>
        </div>
      </Modal>
    </>
  );
}
