import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Divider, Select, Image } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, CheckCircle, Delete, Edit, History, Money, PictureAsPdf } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let user = JSON.parse(localStorage.getItem("user"));
export default function InscriptionsFaites() {
  const classes = useStyles();
  let http = process.env.REACT_APP_API_HTTP
  let search = ""
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  var [stateCherche, setStateCherche] = useState({
    firstName: "",
    applicationNumber: "",
    lastName: "",
    phone: "",
  });
  const [recherche, setRecherche] = React.useState({
    annee: 0,
    search: ""
  });
  const [state, setState] = React.useState({
    id: "",
    schoolYear: {
      id: "",
    },
    competitionCategory: {
      id: "",
    },
    profession: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    frais: false,
    competitionCategory: false,
    profession: false,
  });
  var [stateCandidature, setStateCandidature] = useState({
    id: ""
  })

  React.useEffect(() => {
    (()=>{
      bindAnnees()
    })();
  }, []);


const GetInscriptionsFaites = async (page, size, year, search) => {
  setSpinner(true)
  setRecherche({...recherche, search: search})
  Service.GetInscriptionsFaites(page, size, year, search).then(
    async (response) => {
      setSpinner(false)
      setPage({
        page: page,
        size: size,
        totalElements: response.data.totalElements
      })
      setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          // GetInscriptionsFaites(page.page, page.size, year, recherche.search)
      });
    }
const OpenModal = async (data) => {
  remplirCandidature(reponse.filter(x => x.id === data)[0])
  setOpen(true)
}

const columns = [
  {
    name: "auditor.firstName",
    label: "Prénom",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "auditor.lastName",
    label: "Nom",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "auditor.birthdate",
    label: "Date de naissance",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "auditor.birthplace",
    label: "Lieu de naissance",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "auditor.gender",
    label: "Genre",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "auditor.phone",
    label: "Téléphone",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "classroom.name",
    label: "Classe",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "classroom.level",
    label: "Niveau",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "personnel.firstName",
    label: "Prénom agent",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    label: "Nom agent",
    name: "personnel.lastName",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    label: "Téléphone agent",
    name: "personnel.phone",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    label: "Action",
    name: "id",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <div>
          <Button onClick={(e)=>{OpenModal(value)}} variant="outlined" startIcon={<Add />} style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}}>
            Détails
          </Button>
          <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteInscriptionFaite(value)}}> <Delete /> Supprimer </IconButton>
        </div>;
      }
    }
  }
];

  const cliquer = async (id) => {
        setSpinner(true);
          let data = {
            candidature: {
              id: id
            }
          }
            Service.PostValiderInscription(data).then(
              async (response) => {
                GetInscriptionsFaites(page.page, page.size, recherche.annee, recherche.search)
                setSpinner(false);
                setOpen(false)
                toast.success(Constant.reponsesServeur.validationeffectuer, {position: "top-center", autoClose: 10000,})
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
}


const afficher = async () => {
  search = "&search="
  if(stateCherche.firstName.trim() !== ""){
    search =`${search}auditor__firstName%3D${stateCherche.firstName} AND `
  }
  if(stateCherche.lastName.trim() !== ""){
    search =`${search}auditor__lastName%3D${stateCherche.lastName} AND `
  }
  if(stateCherche.phone.trim() !== ""){
    search =`${search}auditor__phone%3D${stateCherche.phone} AND `
  }
  GetInscriptionsFaites(page.page, page.size, recherche.annee, (search !== "&search=" ? search.slice(0, -5) : ""))
}

const remplirCandidature = (data) => {
  setStateCandidature(data);
}
const deleteInscriptionFaite = async (id) => {
  setSpinner(true)
  Service.deleteInscriptionFaite(id).then(
    async (response) => {
      GetInscriptionsFaites(page.page, page.size, recherche.annee, recherche.search);
      setSpinner(false)
      toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}
  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des inscriptions validées" />
      <Grid container spacing={4}>
        <Grid item xs={2}>
            <Select
                placeholder="Année"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, annee: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  annee.length > 0 && annee.map((element) => {
                    return { "value": element.id, "label": element.year }
                  })
                }
              />
        </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, firstName: e.target.value})} size="medium" placeholder="Prénom"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, lastName: e.target.value})} size="medium" placeholder="Nom"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, phone: e.target.value})} size="medium" placeholder="Téléphone"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={(e)=>afficher()} variant="contained" startIcon={<Add />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher
            </Button>
        </Grid>
        <Grid item sm={2}>
          <Button size="small" onClick={(e)=>{GetInscriptionsFaites(page.page, page.size, recherche.annee, recherche.search)}} variant="outlined" startIcon={<History />} style={{color: 'white', borderColor: 'white'}}>
              Actualiser
          </Button>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des inscriptions validées"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetInscriptionsFaites(currentPage, page.size, recherche.annee, recherche.search)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetInscriptionsFaites(page.page, numberOfRows, recherche.annee, recherche.search)
              },
            }}
          />
        </Grid>
      </Grid>
        <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200, overflow: 'scroll'}}
      >
        <div>
            <Grid container spacing={3}>
            <Grid item sm={3}></Grid>
              <Grid item xs={8}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                  {stateCandidature.id !== "" &&
                      <Grid container spacing={3} style={{padding: 20, lineHeight:1, textAlign: 'left'}}>
                            <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Informations personnelles</Divider>
                              <Grid item sm={2}>
                                <Image preview={{zIndex: 1300, src:`${http}://${stateCandidature.auditor.candidature.idPhotoFile}`}} src={`${http}://${stateCandidature.auditor.candidature.idPhotoFile}`} />
                              </Grid>
                              <Grid item sm={10}>
                                <Grid container spacing={3} style={{left: 0, lineHeight:1, textAlign: 'left'}}>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Prénom:</strong> {stateCandidature.auditor.candidature.candidate.firstName}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Nom:</strong> {stateCandidature.auditor.candidature.candidate.lastName}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Date de naissance:</strong> {stateCandidature.auditor.candidature.candidate.birthdate}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Lieu de naissance:</strong> {stateCandidature.auditor.candidature.candidate.birthplace}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Genre:</strong> {stateCandidature.auditor.candidature.candidate.gender}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Téléphone:</strong> {stateCandidature.auditor.candidature.candidate.phone}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Email:</strong> {stateCandidature.auditor.candidature.candidate.email}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Adresse:</strong> {stateCandidature.auditor.candidature.candidate.address}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Mot de passe:</strong> {stateCandidature.password}</Grid>
                                    <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Parents</Divider>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Père:</strong> {stateCandidature.auditor.candidature.candidate.fatherFirstName} {stateCandidature.auditor.candidature.candidate.fatherLastName}</Grid>
                                  <Grid item sm={6}><strong style={{textDecoration: 'underline'}}>Mère:</strong> {stateCandidature.auditor.candidature.candidate.motherFirstName} {stateCandidature.auditor.candidature.candidate.motherLastName}</Grid>
                                    <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Personne à contacter</Divider>
                                  <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Nom:</strong> {stateCandidature.auditor.candidature.candidate.emergencyContactFullName}</Grid>
                                  <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Téléphone:</strong> {stateCandidature.auditor.candidature.candidate.emergencyContactPhone}</Grid>
                                  <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Adresse:</strong> {stateCandidature.auditor.candidature.candidate.emergencyContactAddress}</Grid>
                                    <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Handicap</Divider>
                                  <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Handicap:</strong> {stateCandidature.auditor.candidature.candidate.handicap ? "Oui" : "Non"}</Grid>
                                  {stateCandidature.auditor.candidature.candidate.handicap && <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Nature:</strong> {stateCandidature.auditor.candidature.candidate.handicapNature.name}</Grid>}
                                  {stateCandidature.auditor.candidature.candidate.handicap && <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Assistance:</strong> {stateCandidature.auditor.candidature.candidate.handicapAssistance.name}</Grid>}
                                </Grid>
                              </Grid>
                            <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Classe</Divider>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Nom:</strong> {stateCandidature.classroom.name}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Niveau:</strong> {stateCandidature.classroom.level}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Année:</strong> {stateCandidature.classroom.year}</Grid>
                            <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Parcours professionnel</Divider>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Niveau:</strong> {stateCandidature.auditor.candidature.formationGrade}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Spécialité:</strong> {stateCandidature.auditor.candidature.formationSpeciality}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Etablissement:</strong> {stateCandidature.auditor.candidature.formationInstitution}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Date d'obtention:</strong> {stateCandidature.auditor.candidature.diplomaDateIssue}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Pays:</strong> {stateCandidature.auditor.candidature.formationCountry}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Numéro matricule:</strong> {stateCandidature.auditor.candidature.candidate.matriculeNumber !== null && stateCandidature.auditor.candidature.candidate.matriculeNumber}</Grid>
                            <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Corps</Divider>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Année:</strong> {stateCandidature.auditor.candidature.programmationCategoryProfession.schoolYear.year}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Type du concours:</strong> {stateCandidature.auditor.candidature.programmationCategoryProfession.competitionCategory.name}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Corps:</strong> {stateCandidature.auditor.candidature.programmationCategoryProfession.profession.name} ({stateCandidature.auditor.candidature.programmationCategoryProfession.profession.acronym})</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Service:</strong> {stateCandidature.auditor.candidature.candidate.office !== null && stateCandidature.auditor.candidature.candidate.office.name}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Spécialité:</strong> {stateCandidature.auditor.candidature.candidate.corps !== null && stateCandidature.auditor.candidature.candidate.corps.name}</Grid>
                            <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Inscrit par</Divider>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Nom:</strong> {stateCandidature.personnel.firstName} {stateCandidature.personnel.lastName}</Grid>
                              <Grid item sm={4}><strong style={{textDecoration: 'underline'}}>Téléphone:</strong> {stateCandidature.personnel.phone}</Grid>
                            <Divider orientation="left" style={{color: 'rgba(0,0,255,0.4)', borderColor: 'rgba(0,0,255,0.4)'}} >Documents</Divider>
                              {stateCandidature.auditor.candidature.birthCertificateFile !== null &&
                                <Grid item sm={2} style={{textAlign: 'center'}}>
                                  <a href={`${http}://${stateCandidature.auditor.candidature.birthCertificateFile}`} target="_blank">
                                    <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                    <br/>
                                    <strong style={{textDecoration: 'underline'}}>Acte de naissance</strong>
                                  </a>
                                </Grid>
                              }
                              {stateCandidature.auditor.candidature.nationalityCertificateFile !== null &&
                                <Grid item sm={2} style={{textAlign: 'center'}}>
                                  <a href={`${http}://${stateCandidature.auditor.candidature.nationalityCertificateFile}`} target="_blank">
                                    <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                    <br/>
                                    <strong style={{textDecoration: 'underline'}}>Certificat de nationalité</strong>
                                  </a>
                                </Grid>
                              }
                              {stateCandidature.auditor.candidature.diplomaFile !== null &&
                                <Grid item sm={2} style={{textAlign: 'center'}}>
                                  <a href={`${http}://${stateCandidature.auditor.candidature.diplomaFile}`} target="_blank">
                                    <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                    <br/>
                                    <strong style={{textDecoration: 'underline'}}>Diplôme</strong>
                                  </a>
                                </Grid>
                              }
                              {stateCandidature.auditor.candidature.referenceEquivalencyLetterFile !== null &&
                                <Grid item sm={2} style={{textAlign: 'center'}}>
                                  <a href={`${http}://${stateCandidature.auditor.candidature.referenceEquivalencyLetterFile}`} target="_blank">
                                    <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                    <br/>
                                    <strong style={{textDecoration: 'underline'}}>Lettre d’équivalence</strong>
                                  </a>
                                </Grid>
                              }
                              {stateCandidature.auditor.candidature.integrationOrderFile !== null &&
                                <Grid item sm={2} style={{textAlign: 'center'}}>
                                  <a href={`${http}://${stateCandidature.auditor.candidature.integrationOrderFile}`} target="_blank">
                                    <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                    <br/>
                                    <strong style={{textDecoration: 'underline'}}>Arrêter d’intégration ou d’avancement dans la catégorie B2</strong>
                                  </a>
                                </Grid>
                              }
                              {stateCandidature.auditor.candidature.authorizationParticipateFile !== null &&
                                <Grid item sm={2} style={{textAlign: 'center'}}>
                                  <a href={`${http}://${stateCandidature.auditor.candidature.authorizationParticipateFile}`} target="_blank">
                                    <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                    <br/>
                                    <strong style={{textDecoration: 'underline'}}>Autorisation de participation</strong>
                                  </a>
                                </Grid>
                              }
                              {stateCandidature.auditor.candidature.medicalCertificateFile !== null &&
                                <Grid item sm={2} style={{textAlign: 'center'}}>
                                  <a href={`${http}://${stateCandidature.auditor.candidature.medicalCertificateFile}`} target="_blank">
                                    <PictureAsPdf style={{fontSize: '100', color: "grey"}}/>
                                    <br/>
                                    <strong style={{textDecoration: 'underline'}}>Certificat de visite médicale</strong>
                                  </a>
                                </Grid>
                              }
                              <Grid item sm={12}>
                                  {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
                                  <div style={{ marginTop: 24, marginBottom: 50}}>
                                      <Button onClick={() => setOpen(false)} style={{backgroundColor: 'red', color: 'white'}}>
                                        Fermer
                                      </Button>
                                  </div>
                              </Grid>
                      </Grid>
                  }
                </Paper>
              </Grid>
              <Grid item xs={1}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
