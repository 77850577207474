import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Select} from "antd";
import Methodes from "../../../utils";
import {Constant} from "../../../constant";
import Service from "../../../service";
import CommonFormErrorText from "../../../components/_Ena/CommonFormErrorText";
import {checkFieldValidity, checkFormValidity} from "../_hooks/hooks";
import CommonConfirmPop from "../../../components/_Ena/CommonConfirmPop";

const formElements = {
    //===============
    firstName: Methodes.fn("",  true),
    lastName: Methodes.fn("",  true),
    gender: Methodes.fn("",  true),
    birthdate: Methodes.fn("",  true),//date
    birthplace: Methodes.fn("",  true),
    phone: Methodes.fn("",  true, '', "[1-9]{1,3}"),
    country: Methodes.fn("",  true),
    //...
    matricule: Methodes.fn("",  true),
    nationality: Methodes.fn("",  false),
    city: Methodes.fn("",  true),
    neighborhood: Methodes.fn("",  true),
    // street: Methodes.fn("",  false),
    // door: Methodes.fn("",  false),
    studyLevel: Methodes.fn("",  true),

    // ============
    classe: Methodes.fn("",  false),
    category: Methodes.fn("",  false),
    echelon: Methodes.fn("",  false),
    corps: Methodes.fn("",  false),
    // grade: Methodes.fn("",  false),
    stateService: Methodes.fn("",  true),
    maritalStatus: Methodes.fn("",  true),

    // marriagePlan: Methodes.fn("",  false),
    nbrChildren: Methodes.fn(0,  false), //limit
    salaryIndex: Methodes.fn(0,  false),
    // numAmo: Methodes.fn("",  false),
    // numInps: Methodes.fn("",  false),
    hiringDate: Methodes.fn("",  true),//date,
    // rib: Methodes.fn("",  false),
    // bankAccount: Methodes.fn("",  false),

    specialtyFunction: Methodes.fn("",  true),
    service: Methodes.fn("",  true),
    // plainPassword: Methodes.fn("",  false), // -- ???

    type: Methodes.fn("",  true), //choix
    // active:  Methodes.fn( true,false),
    // id:  Methodes.fn( null,  false),
    // username: Methodes.fn("",  true),// Methodes.fn( 'azerty ',  true, 'Nom d\'utilisateur'),
    email:  Methodes.fn( null,  false, '', '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'),
    // password:  Methodes.fn( null,  true),
    // passwordConf:  Methodes.fn( null,  true),
    // roles:  Methodes.fn( null,  true),
}


export default function PersonnelsForm ({rowData, actionRef, close}) {
    //STATE
    const [form, setForm] = useState(formElements);
    const [roles, setRoles] = useState([]);
    const [specialityFonctions, setSpecialityFonctions] = useState([]);
    const [services, setServices] = useState([]);

    //VARS

    //INITIALISATION
    useEffect(()=>{
        bindRoles();
        bindSpecialityFonction();
        bindServices();
        if(actionRef === 'edit'){
            setFormElementValues();
        }
    }, []);

    //FUNCTIONS
    function bindRoles(){
        Service.GetRoles().then(rolesBinded => {
            const roleSelectOptions = rolesBinded.data.content.map(role => {
                return  {value: role.id.toString(), label: role.description}
            });
            setRoles( roleSelectOptions);

            console.log('role =>' , roleSelectOptions);
        });
    }
    function bindSpecialityFonction(){
        Service.getSpecialityFonction().then(specialityFonctionBinded => {
            const specialityFonctionSelectOptions = specialityFonctionBinded.data.content.map(sf => {
                return  {value: sf.id.toString(), label: sf.name}
            });
            setSpecialityFonctions(specialityFonctionSelectOptions);
            console.log('specialityFonction =>' , specialityFonctionSelectOptions);
        });
    }
    function bindServices(){
        Service.getServices().then(serviceBind => {
            const serviceBindSelectOptions = serviceBind.data.content.map(s => {
                return  {value: s.id.toString(), label: s.description}
            });
            setServices(serviceBindSelectOptions);
            console.log('services =>' , serviceBindSelectOptions);

        });
    }

    function setFormElementValues(){
        setForm((form) =>{
            form.type.value = rowData.type;
            form.firstName.value = rowData.firstName;
            form.lastName.value = rowData.lastName;
            form.gender.value = rowData.gender;
            form.birthdate.value = rowData.birthdate ;
            form.birthplace.value = rowData.birthplace;
            form.phone.value = rowData.phone;
            form.email.value = rowData.email;
            form.city.value = rowData.city;
            form.country.value = rowData.country;
            form.matricule.value = rowData.matricule;
            form.hiringDate.value = rowData.hiringDate;
            form.maritalStatus.value = rowData.maritalStatus;
            form.nationality.value = rowData.nationality;
            form.nbrChildren.value = +rowData.nbrChildren;
            form.neighborhood.value = rowData.neighborhood;
            form.stateService.value = rowData.stateService;
            form.studyLevel.value = rowData.studyLevel;
            form.type.value = rowData.type;
            form.category.value = rowData.category.toString();
            form.echelon.value = rowData.echelon;
            form.classe.value = rowData.classe;
            form.salaryIndex.value = rowData.salaryIndex;//
            form.corps.value = rowData.corps;
            form.service.value = rowData.service.id;
            form.specialtyFunction.value = rowData.specialtyFunction.id;
            return form;
        });

        console.log('form 1230 ==> ', form);
    }

    // ==> HANDLE ET CHANGEMENT
    const handleForm = (e) => {
        if (Methodes.isEmpty(e)) return;
        const { name, value } = e.target;
        setForm((prevForm) => {
            const result = checkFieldValidity(prevForm, name, value );
            return {...result}
        });
    };

    // function roleChange(selected){
    //     const roles = selected.map(role => {return {id: +role}});
    //     setForm(prevForm => {
    //         prevForm.roles.value = roles.length >= 1 ? roles : undefined;
    //         return {...prevForm}
    //     })
    // }

    // const paysHandler = value => {
    //     setForm(prevForm => {
    //         prevForm.country.value = value;
    //         return {...prevForm}
    //     })
    // }

    const changePersonnelDataHandler = (value, name) => {
        setForm(prevForm => {
            // prevForm[name].value = value;
            const result = checkFieldValidity(prevForm, name, value );
            return {...result}
        })

        console.log('change da => ',form);
    }

    // *===> SUBMIT PART
    function submitForm(e) {
        e.preventDefault();
        const bol = checkFormValidity(form);
        if (!bol) {
            // setNewRender({...newRender, bol: !newRender, val: 'sub'})
            Methodes.ToastTopCenter('info', Constant.formError.incomplet)
            return;
        }
        const body = buildBody();
        actionRef === 'add' ? save(body) : update(body)
        console.log('body ==> ', body);
    }

    function buildBody() {
        const sendBody = {
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            gender: form.gender.value,
            birthdate: form.birthdate.value ,
            birthplace: form.birthplace.value,
            phone: form.phone.value,
            email: form.email.value,
            city: form.city.value,
            country: form.country.value,
            matricule: form.matricule.value,
            hiringDate: form.hiringDate.value,
            maritalStatus: form.maritalStatus.value,
            nationality: form.nationality.value,
            nbrChildren: +form.nbrChildren.value,
            neighborhood: form.neighborhood.value,
            stateService: form.stateService.value,
            studyLevel: form.studyLevel.value,
            type: form.type.value,
            category: form.category.value,
            echelon: form.echelon.value,
            classe: form.classe.value,
            salaryIndex: +form.salaryIndex.value,
            corps: form.corps.value,
            service: {id: +form.service.value},
            specialtyFunction:{id: form.specialtyFunction.value}
        }
        if (actionRef === 'edit') sendBody.id = rowData.id;
        return sendBody;
    }

    function save(body){
        Service.savePersonnel(body).then(savePersonnel => {
            const msg = 'Personnel '+body.matricule+' : '+body.firstName+' '+body.lastName+' Créer avec succès !';
            Methodes.ToastTopCenter('ok',msg, 7000);
            close('ok');
        });
    }

    function update(body){
        Service.updatePersonnel(body).then(updatePersonnel => {
            const msg = 'Personnel '+body.matricule+' : '+body.firstName+' '+body.lastName+' Modifier avec succès !';
            Methodes.ToastTopCenter('ok',msg, 7000);
            close('ok');
        });
    }

    function elementValidator(element){ //ex
        return element === 'no';
    }


    return(
        <>
            <form>
                <h6 style={{textAlign: 'center'}}>Données générales</h6>
                <Row>
                    <Col span={12} style={{textAlign:"left"}}>
                        <div style={{padding:8}}>
                            <div style={{marginTop:10}}>
                                <label htmlFor="type">Type d'utilisateur <span style={{color: "red"}}>*</span></label>
                                <Select
                                    id={'type'}
                                    style={{ width: '100%' }}
                                    status={elementValidator(form.type.valided) &&  "error"}
                                    placeholder={'...'}
                                    name={'type'}
                                    value={form.type.value}
                                    onChange={(val) => changePersonnelDataHandler(val, 'type')}
                                    options={Constant.typePersonnel}
                                />
                                {elementValidator(form.type.valided) && <CommonFormErrorText/>}
                            </div>


                            <div style={{marginTop:10}}>
                                <label htmlFor="firstName">Prénom <span style={{color: "red"}}>*</span></label>
                                <Input
                                    id={'firstName'}
                                    placeholder={' ...'}
                                    name={'firstName'}
                                    value={form.firstName.value}
                                    onChange={handleForm}
                                />
                                {elementValidator(form.firstName.valided) && <CommonFormErrorText/>}
                            </div>

                            <div style={{marginTop:10}}>
                                <label htmlFor="lastName">Nom <span style={{color: "red"}}>*</span></label>
                                <Input
                                    id={'lastName'}
                                    placeholder={' ...'}
                                    name={'lastName'}
                                    value={form.lastName.value}
                                    onChange={handleForm}/>
                                {elementValidator(form.lastName.valided) && <CommonFormErrorText/>}
                            </div>

                            <div style={{marginTop:10}}>
                                <label htmlFor="lastName">Genre <span style={{color: "red"}}>*</span></label>
                                <Select
                                    id={'gender'}
                                    style={{ width: '100%' }}
                                    placeholder={'...'}
                                    name={'gender'}
                                    onChange={(val) => changePersonnelDataHandler(val, 'gender')}
                                    value={form.gender.value}
                                    options={Constant.Genres}
                                />
                                {elementValidator(form.gender.valided) && <CommonFormErrorText/>}
                            </div>

                            <div style={{marginTop:10}}>
                                <label htmlFor="birthdate">Date de naissance <span style={{color: "red"}}>*</span></label>
                                <Input
                                    type={'date'}
                                    id={'birthdate'}
                                    placeholder={' ...'}
                                    name={'birthdate'}
                                    value={form.birthdate.value}
                                    onChange={handleForm}
                                />
                                {elementValidator(form.birthdate.valided) && <CommonFormErrorText/>}
                            </div>

                            <div style={{marginTop:10}}>
                                <label htmlFor="birthplace">Lieu de naissance <span style={{color: "red"}}>*</span></label>
                                <Input
                                    id={'birthplace'}
                                    placeholder={' ...'}
                                    name={'birthplace'}
                                    value={form.birthplace.value}
                                    onChange={handleForm}
                                />
                                {elementValidator(form.birthplace.valided) && <CommonFormErrorText/>}
                            </div>
                        </div>
                    </Col>
                    {/*============================================*/}
                    {/* //<= = 2 = =>// */}
                    {/*============================================*/}
                    <Col span={12} style={{textAlign:"left"}}>
                       <div style={{padding:8}}>
                           <div style={{marginTop:10}}>
                               <label htmlFor="matricule">N° matricule <span style={{color: "red"}}>*</span></label>
                               <Input
                                   id={'matricule'}
                                   status={elementValidator(form.matricule.value) &&  "error"}
                                   placeholder={' ...'}
                                   name={'matricule'}
                                   value={form.matricule.value}
                                   onChange={handleForm}
                               />
                               {elementValidator(form.matricule.valided) && <CommonFormErrorText />}
                           </div>

                           <div style={{marginTop:10}}>
                               <label htmlFor="country">Pays <span style={{color: "red"}}>*</span></label>
                               <Select
                                   id={'country'}
                                   style={{ width: '100%' }}
                                   placeholder={'...'}
                                   name={'country'}
                                   onChange={(val) => changePersonnelDataHandler(val, 'country')}
                                   value={form.country.value}
                                   options={Methodes.allCountryOrNationalityList('pays')}
                               />
                               {/*{elementValidator(form.country.valided) && <CommonFormErrorText/>}*/}
                           </div>

                           <div style={{marginTop:10}}>
                               <label htmlFor="nationality">Nationalité <span style={{color: "red"}}>*</span></label>
                               <Select
                                   id={'nationality'}
                                   style={{ width: '100%' }}
                                   // status={elementValidator(form.nationality.valided) &&  "error"}
                                   placeholder={'...'}
                                   name={'nationality'}
                                   onChange={(val) => changePersonnelDataHandler(val, 'nationality')}
                                   value={form.nationality.value}
                                   options={Methodes.allCountryOrNationalityList('nationality')}
                               />
                               {/*{elementValidator(form.nationality.valided) && <CommonFormErrorText/>}*/}
                           </div>

                           <div style={{marginTop:10}}>
                               <label htmlFor="city">Ville <span style={{color: "red"}}>*</span></label>
                               <Input
                                   id={'city'}
                                   status={elementValidator(form.city.value) &&  "error"}
                                   placeholder={' ...'}
                                   name={'city'}
                                   value={form.city.value}
                                   onChange={handleForm}
                               />
                               {elementValidator(form.city.valided) && <CommonFormErrorText/>}
                           </div>

                           <div style={{marginTop:10}}>
                               <label htmlFor="neighborhood">Quartier <span style={{color: "red"}}>*</span></label>
                               <Input
                                   id={'neighborhood'}
                                   status={elementValidator(form.neighborhood.value) &&  "error"}
                                   placeholder={' ...'}
                                   name={'neighborhood'}
                                   value={form.neighborhood.value}
                                   onChange={handleForm}
                               />
                               {elementValidator(form.neighborhood.valided) && <CommonFormErrorText/>}
                           </div>

                           {/*<div style={{marginTop:10}}>*/}
                           {/*    <label htmlFor="street">Rue </label>*/}
                           {/*    <Input*/}
                           {/*        id={'street'}*/}
                           {/*        placeholder={' ...'}*/}
                           {/*        name={'street'}*/}
                           {/*        value={form.street.value}*/}
                           {/*        onChange={handleForm}*/}
                           {/*    />*/}
                           {/*</div>*/}

                           {/*<div style={{marginTop:10}}>*/}
                           {/*    <label htmlFor="door">Porte </label>*/}
                           {/*    <Input*/}
                           {/*        id={'door'}*/}
                           {/*        placeholder={' ...'}*/}
                           {/*        name={'door'}*/}
                           {/*        value={form.door.value}*/}
                           {/*        onChange={handleForm}*/}
                           {/*    />*/}
                           {/*</div>*/}

                           <div style={{marginTop:10}}>
                               <label htmlFor="studyLevel">Niveau d'étude <span style={{color: "red"}}>*</span></label>
                               <Select
                                   id={'studyLevel'}
                                   style={{ width: '100%' }}
                                   status={elementValidator(form.studyLevel.valided) &&  "error"}
                                   placeholder={'...'}
                                   name={'studyLevel'}
                                   onChange={(val) => changePersonnelDataHandler(val, 'studyLevel')}
                                   value={form.studyLevel.value}
                                   options={Methodes.getPersonnelsData('niveauxEtude')}
                               />
                               {elementValidator(form.studyLevel.valided) && <CommonFormErrorText/>}
                           </div>
                       </div>
                    </Col>
                </Row>
            {/* ----------------------------------------------------------------*/}
            {/* ROW 2 --------------------------------------------------------> */}
            {/* ----------------------------------------------------------------*/}
                <h6 style={{textAlign: 'center'}}>Données professionnelle</h6>
            <Row>
                <Col span={12} style={{textAlign:"left"}}>
                    <div style={{padding:8}}>
                        <div style={{marginTop:10}}>
                            <label htmlFor="email">Email <span style={{color: "red"}}>*</span></label>
                            <Input
                                id={'email'}
                                status={elementValidator(form.email.valided) &&  "error"}
                                placeholder={'...'}
                                name={'email'}
                                value={form.email.value}
                                onChange={handleForm}
                            />
                            {elementValidator(form.email) && <CommonFormErrorText text={Constant.formError.emailFormat}/>}
                        </div>

                        <div style={{marginTop:10}}>
                            <label htmlFor="phone">Téléphone </label>
                            <Input
                                id={'phone'}
                                status={elementValidator(form.phone.valided) &&  "error"}
                                placeholder={'...'}
                                name={'phone'}
                                value={form.phone.value}
                                onChange={handleForm}
                            />
                            {elementValidator(form.phone) && <CommonFormErrorText text={Constant.formError.phoneNumberFormat}/>}
                        </div>
+
                        <div style={{marginTop:10}}>
                            <label htmlFor="hiringDate">Date d'embauche </label>
                            <Input
                                type={'date'}
                                id={'hiringDate'}
                                placeholder={' ...'}
                                name={'hiringDate'}
                                value={form.hiringDate.value}
                                onChange={handleForm}
                            />
                        </div>


                        <div style={{marginTop:10}}>
                            <label htmlFor="classe">Classe </label>
                            <Select
                                id={'classe'}
                                style={{ width: '100%' }}
                                // status={elementValidator(form.classe.valided) &&  "error"}
                                placeholder={'...'}
                                name={'classe'}
                                onChange={(val) => changePersonnelDataHandler(val, 'classe')}
                                value={form.classe.value}
                                options={Methodes.getPersonnelsData('classes')}
                            />
                            {/*{elementValidator(form.classe.valided) && <CommonFormErrorText/>}*/}
                        </div>

                        <div style={{marginTop:10}}>
                            <label htmlFor="category">Categorie </label>
                            <Select
                                id={'category'}
                                style={{ width: '100%' }}
                                // status={elementValidator(form.category.valided) &&  "error"}
                                placeholder={'...'}
                                name={'category'}
                                onChange={(val) => changePersonnelDataHandler(val, 'category')}
                                value={form.category.value}
                                options={Methodes.getPersonnelsData('categorie')}
                            />
                            {/*{elementValidator(form.category.valided) && <CommonFormErrorText/>}*/}
                        </div>

                        <div style={{marginTop:10}}>
                            <label htmlFor="echelon">Echelon </label>
                            <Select
                                id={'echelon'}
                                style={{ width: '100%' }}
                                // status={elementValidator(form.echelon.valided) &&  "error"}
                                placeholder={'...'}
                                name={'echelon'}
                                onChange={(val) => changePersonnelDataHandler(val, 'echelon')}
                                value={form.echelon.value}
                                options={Methodes.getPersonnelsData('echelons')}
                            />
                            {/*{elementValidator(form.echelon.valided) && <CommonFormErrorText/>}*/}
                        </div>

                        <div style={{marginTop:10}}>
                            <label htmlFor="corps">Corps </label>
                            <Select
                                id={'corps'}
                                style={{ width: '100%' }}
                                // status={elementValidator(form.corps.valided) &&  "error"}
                                placeholder={'...'}
                                name={'corps'}
                                onChange={(val) => changePersonnelDataHandler(val, 'corps')}
                                value={form.corps.value}
                                options={Methodes.getPersonnelsData('corpsPersonnelAdministratif')}
                            />
                            {/*{elementValidator(form.corps.valided) && <CommonFormErrorText/>}*/}
                        </div>

                        {/*<div style={{marginTop:10}}>*/}
                        {/*    <label htmlFor="grade">Grade <span style={{color: "red"}}>*</span></label>*/}
                        {/*    <Select*/}
                        {/*        id={'grade'}*/}
                        {/*        style={{ width: '100%' }}*/}
                        {/*        // status={elementValidator(form.grade.valided) &&  "error"}*/}
                        {/*        placeholder={'...'}*/}
                        {/*        name={'corps'}*/}
                        {/*        onChange={(val) => changePersonnelDataHandler(val, 'echelon')}*/}
                        {/*        value={}*/}
                        {/*        options={Methodes.getPersonnelsData('grades')}*/}
                        {/*    />*/}
                        {/*    /!*{elementValidator(form.grade.valided) && <CommonFormErrorText/>}*!/*/}
                        {/*</div>*/}

                    </div>
                </Col>
                <Col span={12} style={{textAlign:"left"}}>
                    <div style={{padding:8}}>
                        {/*<div style={{marginTop:10}}>*/}
                        {/*    <label htmlFor="marriagePlan">Regime du mariage <span style={{color: "red"}}>*</span></label>*/}
                        {/*    <Select*/}
                        {/*        id={'marriagePlan'}*/}
                        {/*        style={{ width: '100%' }}*/}
                        {/*        status={elementValidator(form.marriagePlan.valided) &&  "error"}*/}
                        {/*        placeholder={'...'}*/}
                        {/*        name={'marriagePlan'}*/}
                        {/*        onChange={(val) => changePersonnelDataHandler(val, 'marriagePlan')}*/}
                        {/*        value={}*/}
                        {/*        options={Methodes.getPersonnelsData('regimeMariage')}*/}
                        {/*    />*/}
                        {/*    {elementValidator(form.marriagePlan.valided) && <CommonFormErrorText/>}*/}
                        {/*</div>*/}

                        <div style={{marginTop:10}}>
                            <label htmlFor="maritalStatus">Statut matrimoniale <span style={{color: "red"}}>*</span></label>
                            <Select
                                id={'maritalStatus'}
                                style={{ width: '100%' }}
                                status={elementValidator(form.maritalStatus.valided) &&  "error"}
                                placeholder={'...'}
                                name={'maritalStatus'}
                                onChange={(val) => changePersonnelDataHandler(val, 'maritalStatus')}
                                value={form.maritalStatus.value}
                                options={Methodes.getPersonnelsData('situationMatrimoniale')}
                            />
                            {elementValidator(form.maritalStatus.valided) && <CommonFormErrorText/>}
                        </div>

                        <div style={{marginTop:10}}>
                            <label htmlFor="nbrChildren">Nombre enfant </label>
                            <Input
                                type={'number'}
                                min={0}
                                id={'nbrChildren'}
                                placeholder={' ...'}
                                name={'nbrChildren'}
                                value={form.nbrChildren.value}
                                onChange={handleForm}
                            />
                        </div>
                        <div style={{marginTop:10}}>
                            <label htmlFor="salaryIndex">Salary Index </label>
                            <Input
                                type={'number'}
                                min={0}
                                id={'salaryIndex'}
                                placeholder={' ...'}
                                name={'salaryIndex'}
                                value={form.salaryIndex.value}
                                onChange={handleForm}
                            />
                        </div>

                        {/*<div style={{marginTop:10}}>*/}
                        {/*    <label htmlFor="numAmo">N° AMO </label>*/}
                        {/*    <Input*/}
                        {/*        type={'text'}*/}
                        {/*        id={'numAmo'}*/}
                        {/*        placeholder={' ...'}*/}
                        {/*        name={'numAmo'}*/}
                        {/*        value={form.numAmo.value}*/}
                        {/*        onChange={handleForm}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*<div style={{marginTop:10}}>*/}
                        {/*    <label htmlFor="numInps">N° INPS </label>*/}
                        {/*    <Input*/}
                        {/*        type={'text'}*/}
                        {/*        id={'numInps'}*/}
                        {/*        placeholder={' ...'}*/}
                        {/*        name={'numInps'}*/}
                        {/*        value={form.numInps.value}*/}
                        {/*        onChange={handleForm}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*<div style={{marginTop:10}}>*/}
                        {/*    <label htmlFor="rib">RIB </label>*/}
                        {/*    <Input*/}
                        {/*        type={'text'}*/}
                        {/*        id={'rib'}*/}
                        {/*        placeholder={' ...'}*/}
                        {/*        name={'rib'}*/}
                        {/*        value={form.rib.value}*/}
                        {/*        onChange={handleForm}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*<div style={{marginTop:10}}>*/}
                        {/*    <label htmlFor="bankAccount">Compte banquaire </label>*/}
                        {/*    <Input*/}
                        {/*        type={'text'}*/}
                        {/*        id={'bankAccount'}*/}
                        {/*        placeholder={' ...'}*/}
                        {/*        name={'bankAccount'}*/}
                        {/*        value={form.bankAccount.value}*/}
                        {/*        onChange={handleForm}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <div style={{marginTop:10}}>
                            <label htmlFor="specialtyFunction"> Spécialité ou Fonction <span style={{color: "red"}}>*</span></label>
                            <Select
                                id={'specialtyFunction'}
                                style={{ width: '100%' }}
                                status={elementValidator(form.specialtyFunction.valided) &&  "error"}
                                placeholder={'...'}
                                name={'specialtyFunction'}
                                onChange={(val) => changePersonnelDataHandler(val, 'specialtyFunction')}
                                value={form.specialtyFunction.value}
                                options={specialityFonctions}
                            />
                            {elementValidator(form.specialtyFunction.valided) && <CommonFormErrorText/>}
                        </div>

                        <div style={{marginTop:10}}>
                            <label htmlFor="stateService">Etat de service <span style={{color: "red"}}>*</span></label>
                            <Select
                                id={'stateService'}
                                style={{ width: '100%' }}
                                status={elementValidator(form.stateService.valided) &&  "error"}
                                placeholder={'...'}
                                name={'stateService'}
                                onChange={(val) => changePersonnelDataHandler(val, 'stateService')}
                                value={form.stateService.value}
                                options={Methodes.getPersonnelsData('etatsService')}
                            />
                            {elementValidator(form.stateService.valided) && <CommonFormErrorText/>}
                        </div>

                        <div style={{marginTop:10}}>
                            <label htmlFor="service"> Service <span style={{color: "red"}}>*</span></label>
                            <Select
                                id={'service'}
                                style={{ width: '100%' }}
                                status={elementValidator(form.service.valided) &&  "error"}
                                placeholder={'...'}
                                name={'service'}
                                onChange={(val) => changePersonnelDataHandler(val, 'service')}
                                value={form.service.value}
                                options={services}
                            />
                            {elementValidator(form.service.valided) && <CommonFormErrorText/>}
                        </div>

                    </div>
                </Col>
            </Row>

            {/*  <====== DUBMIT BTN  =====> */}
                <Row>
                    <Col span={12} style={{textAlign:"left"}}>
                            <Button style={{ margin: "0 8px" }} onClick={() =>close('cancel')}>
                                Annuler
                            </Button>
                    </Col>
                    <Col span={12} style={{textAlign:"right"}}>
                        <CommonConfirmPop confirm={(e) => submitForm(e)}>
                            <Button type="primary">
                                Enregistrer
                            </Button>
                        </CommonConfirmPop>
                    </Col>
                </Row>

            </form>

        </>
    )
}
