import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select, TimePicker } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, DeleteOutline, Edit, PictureAsPdf, Search } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {num2} from '../../../chant/num2'
// import HTMLtoDOCX from "html-to-docx";
// import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const format = 'HH:mm';
const list = 'list';

export default function GestionAnonimat() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [programmation, setProgrammation] = useState([]);
  const [epreuve, setEpreuve] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
    idEvaluation: ""
  });
  const [state, setState] = React.useState({
    id: "",
    schoolYear: {
      id: "",
    },
    competitionCategory: {
      id: "",
    },
    profession: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    date: false,
    heure: false,
    profession: false,
    programmation: false,
    coefficient: false,
    examination: false,
    numberCorrection: false,
  });

  React.useEffect(() => {
    (()=>{
      // pdf(betiba, "betiba")
      bindAnnees()
    })();
  }, []);


const GetEvaluations = async (page, size, year) => {
  Service.GetEvaluations(page, size, year).then(
    async (response) => {
      setProgrammation(response.data)
    }, error => {
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          GetEvaluations(-1, -1, year)
      });
    }

const OpenModal = async (type) => {
  setState({...state, type: type})
  let d = []
  setOpen(true)
}

const modifier = async (data) => {
  setState({
    programmation: data.programmationExamination.examination.id + "/2474/" + data.programmationExamination.programmationCategoryProfession.competitionCategory.id + "/2474/" +data.programmationExamination.programmationCategoryProfession.profession.id,
    code: data.code,
    date: data.date,
    endHour: data.endHour,
    startHour: data.startHour,
  })
  setOpen(true)
}

  const columns = [
      {
        name: "numPlace",
        label: "Nº Place",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "fullName",
        label: "Prénom(s) et Nom",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "numberAnonymity",
        label: "Anonymat",
        options: {
          filter: true,
          sort: true,
         }
      },
    ];


  const cliquer = async () => {
    if(state.programmation === undefined || state.programmation === null){
      setError({...error, programmation: true})
    } else{
        setError({...error, programmation: false})
        setSpinner(true);
        if(state.type === "Générer l'anonymat") {
            Service.PostAnonymatGererate(state.programmation).then(
              async (response) => {
                setSpinner(false);
                setOpen(false)
                toast.success(response.data, {position: "top-center", autoClose: 10000,})
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          Service.PostAnonymatCancel(state.programmation).then(
            async (response) => {
              setSpinner(false);
              setOpen(false)
              toast.success(response.data, {position: "top-center", autoClose: 10000,})
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    }
}

const GetAnonymats = async (page, size, type) => {
  setSpinner(true)
  let r = programmation.filter((e)=> e.id === recherche.idEvaluation)[0];
  let str = "Anonymats pour " + r.programmationExamination.examination.name + " (Etape: "+r.programmationExamination.examination.step+")" + " ("+r.programmationExamination.programmationCategoryProfession.competitionCategory.name+")"
  Service.GetAnonymats(page, size, recherche.idEvaluation, type).then(
    async (response) => {
      setSpinner(false)
      if(type === "list") {
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
      } else {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${str}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

  return (
    <>
    
      <ToastContainer />
      <PageTitle title="Gestion des anonymats" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={4}>
          <Button onClick={(e)=>{OpenModal("Générer l'anonymat")}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Génération des anonymats
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={(e)=>{OpenModal("Supprimer l'anonymat")}} variant="outlined" startIcon={<DeleteOutline />} style={{color: 'white', borderColor: 'white'}}>
            Supression des anonymats
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item sm={6}>
          <Select
            placeholder="Evaluation"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: "100%", zIndex: 1}}
              onChange={(e)=>setRecherche({...recherche, idEvaluation: e})}
              options=
              {
                programmation.length > 0 && programmation.map((element) => {
                  return { "value": element.id, "label": element.programmationExamination.examination.name + " (Etape: "+element.programmationExamination.examination.step+")" + " ("+element.programmationExamination.programmationCategoryProfession.competitionCategory.name+")" }
                })
              }
            />
        </Grid>
        <Grid item sm={2}>
          <Button onClick={(e)=>GetAnonymats(page.page, page.size, list)} variant="contained" startIcon={<Search />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher la liste
          </Button>
        </Grid>
        <Grid item sm={4}>
          <Button onClick={(e)=>GetAnonymats(page.page, page.size, "PDF")} variant="contained" startIcon={<PictureAsPdf />} style={{color: 'black', backgroundColor: 'white'}}>
              Télécharger le PDF
          </Button>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des candidats avec anonymats"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetAnonymats(currentPage, page.size, list)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetAnonymats(page.page, numberOfRows, list)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Génération des anonymats
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Evaluation</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, programmation: e})}
                                  defaultValue={(state.programmation !== null && state.programmation !== undefined) && state.programmation}
                                  options=
                                  {
                                    programmation.length > 0 && programmation.map((element) => {
                                      return { "value": element.id, "label": element.programmationExamination.examination.name + " (Corps: "+element.programmationExamination.programmationCategoryProfession.profession.acronym+")" + " ("+element.programmationExamination.programmationCategoryProfession.competitionCategory.name+")" }
                                    })
                                  }
                                />
                                {error.programmation && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.type}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
