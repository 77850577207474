import {Popconfirm} from "antd";


export default function CommonConfirmPop({confirm, children}){
    return <Popconfirm
        title={'Confirmation'}
        description="Êtes-vous sûr de continuer votre action ?"
        onConfirm={confirm}
        okText="Oui, continuer !"
        cancelText="Non"
    >
        {children}
    </Popconfirm>
}