import axios from 'axios'

export const header = {'Authorization': `Bearer ${localStorage.getItem('token') !== null ? localStorage.getItem('token') : ""}`};


const url = process.env.REACT_APP__ENA_API_URL;
const authUrl = url;// 'http://167.86.127.248:9090/api/v1'
const candidatureUrl = url;// 'http://167.86.127.248:9090/api/v1';
const rhUrl = url; //'http://167.86.127.248:9090/api/v1';
// const url = 'http://167.86.127.248:9999/api/v1' 
class Service {
      constructor() {}
  /* ***************************** 
PIERRE PART */
      static connexion(data) {
        // const HEADERS = {'Authorization' : `Basic ${token}`};
        return axios.post(authUrl + '/auth-server/auth', data);
      }

      // Année en cours
      static GetCurrentYear() {
        return axios.get(candidatureUrl + '/candidature/schools_years/current');
      }

      // Programmation des Concours
      static GetProgrammationConcours(page, size, year) {
        return axios.get(candidatureUrl + `/candidature/programmation_category_profession?page=${page}&size=${size}&search=schoolYear__id=${year}`, {headers:header});
      }
      static deleteProgrammationConcours(idAnnee, idTypeConcours, idProfession) {
        return axios.delete(candidatureUrl + `/candidature/programmation_category_profession?year_id=${idAnnee}&category_id=${idTypeConcours}&profession_id=${idProfession}`, {headers:header});
      }
      static PostProgrammationConcours(data) {
        return axios.post(candidatureUrl + '/candidature/programmation_category_profession/list', data, {headers:header});
      }
      static PutProgrammationConcours(data) {
        return axios.put(candidatureUrl + '/candidature/programmation_category_profession', data, {headers:header});
      }
      static GetProgrammationParAnneeEtType(year_id, category_id) {
        return axios.get(candidatureUrl + `/candidature/programmation_category_profession/profession?year_id=${year_id}&category_id=${category_id}`, {headers:header});
      }

      // Statistiques
      static GetStatistiqueCandidature(recherche) {
        return axios.get(candidatureUrl + `/candidature/statistics/candidature?id_school_year=${recherche.id_school_year}&gender=${recherche.gender}&profession=${recherche.profession}&category=${recherche.category}&oldYear=${recherche.oldYear}`, {headers:header});
      }
      static GetStatistiqueInscription(recherche) {
        return axios.get(candidatureUrl + `/auditors/statistics/registration?id_school_year=${recherche.id_school_year}&gender=${recherche.gender}&profession=${recherche.profession}&category=${recherche.category}&oldYear=${recherche.oldYear}&level=${recherche.level}`, {headers:header});
      }
      
      // Programmation des epreuves par corps
      static PostProgrammationEpreuve(data) {
        return axios.post(candidatureUrl + '/candidature/programmation_examination/list', data, {headers:header});
      }
      static GetProgrammationEpreuve(page, size, year) {
        return axios.get(candidatureUrl + `/candidature/programmation_examination?page=${page}&size=${size}&search=programmationCategoryProfession__schoolYear__id=${year}`, {headers:header});
      }
      static deleteProgrammationEpreuve(idAnnee, idTypeConcours, idProfession, examination_id) {
        return axios.delete(candidatureUrl + `/candidature/programmation_examination?year_id=${idAnnee}&category_id=${idTypeConcours}&profession_id=${idProfession}&examination_id=${examination_id}`, {headers:header});
      }
      static PutProgrammationEpreuve(data) {
        return axios.put(candidatureUrl + '/candidature/programmation_examination', data, {headers:header});
      }
      
      // Generation Anonymat
      static PostAnonymatGererate(idEvaluation) {
        return axios.post(candidatureUrl + `/candidature/anonymous_numbers/generate?idEvaluation=${idEvaluation}&emerged=false`, {}, {headers:header});
      }
      static PostAnonymatCancel(idEvaluation) {
        return axios.post(candidatureUrl + `/candidature/anonymous_numbers/cancel/${idEvaluation}`, {}, {headers:header});
      }
      static GetAnonymats(page, size, idEvaluation, type) {
        return axios.get(candidatureUrl + `/candidature/anonymous_numbers/list/formatted?idEvaluation=${idEvaluation}&type=${type}&page=${page}&size=${size}`, {headers:header, responseType: type==="PDF" ? 'blob': 'json'});
      }
      // Generation Notes avec Anonymat
      static GetNoteAnonymats(page, size, idEvaluation, type, numberCorrection, isBlank) {
        return axios.get(candidatureUrl + `/candidature/marks/evaluation/${idEvaluation}/formatted?numberCorrection=${numberCorrection}&type=${type}&isBlank=${isBlank}&page=${page}&size=${size}`, {headers:header, responseType: type==="PDF" ? 'blob': 'json'});
      }
      static GetNoteInformationPersonnelle(page, size, idEvaluation, numberCorrection) {
        return axios.get(candidatureUrl + `/candidature/marks/evaluation/${idEvaluation}?numberCorrection=${numberCorrection}&page=${page}&size=${size}`, {headers:header});
      }
      static GetCandidatSansNote(page, size, idEvaluation, type, numberCorrection) {
        return axios.get(candidatureUrl + `/candidature/marks/notYet/${idEvaluation}?numberCorrection=${numberCorrection}&type=${type}&page=${page}&size=${size}`, {headers:header});
      }
      static NoterCandidat(list) {
        return axios.post(candidatureUrl + `/candidature/marks/list`, list, {headers:header});
      }
      static ModifierNoteCandidat(list) {
        return axios.put(candidatureUrl + `/candidature/marks/list`, list, {headers:header});
      }

      // Programmation des evaluation
      static PostEvaluations(data) {
        return axios.post(candidatureUrl + '/candidature/evaluations', data, {headers:header});
      }
      static GetEvaluations(page, size, year) {
        return axios.get(candidatureUrl + `/candidature/evaluations?page=${page}&size=${size}&search=programmationExamination__programmationCategoryProfession__schoolYear__id=${year}`, {headers:header});
      }
      static deleteEvaluations(id) {
        return axios.delete(candidatureUrl + `/candidature/evaluations/${id}`, {headers:header});
      }
      static PutEvaluations(data) {
        return axios.put(candidatureUrl + '/candidature/evaluations', data, {headers:header});
      }
      
      // Obtenir le fichier d'une candidature
      static GetCandidaturePdfFile(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures/generate_summary', data,{responseType: 'blob'});
      }

      // competition Parametre
      static GetCompetionsParameters(page, size, year) {
        return axios.get(candidatureUrl + `/candidature/competition_parameters?page=${page}&size=${size}&search=programmationCategoryProfession__schoolYear__id=${year}`, {headers:header});
      }
      static deleteCompetionsParameters(id) {
        return axios.delete(candidatureUrl + `/candidature/competition_parameters/${id}`, {headers:header});
      }
      static PostCompetionParameters(data) {
        return axios.post(candidatureUrl + '/candidature/competition_parameters', data, {headers:header});
      }
      static PutCompetionParameters(data) {
        return axios.put(candidatureUrl + '/candidature/competition_parameters', data, {headers:header});
      }     
      static PutCompetionParametersApply(data) {
        return axios.put(candidatureUrl + '/candidature/candidatures/processing_admitted', data, {headers:header});
      }

       // Gestion des écarts
        static GetEcartCandidat(page, size, idEvaluation) {
          return axios.get(candidatureUrl + `/candidature/marks/differences?page=${page}&size=${size}&search=idEvaluation=${idEvaluation}`, {headers:header});
        }
        static GetEcart() {
          return axios.get(candidatureUrl + `/candidature/settings`, {headers:header});
        }
        static DeleteEcart(id) {
          return axios.delete(candidatureUrl + `/candidature/settings/${id}`, {headers:header});
        }
        static PostEcart(data) {
          return axios.post(candidatureUrl + '/candidature/settings', data, {headers:header});
        }
        static PutEcart(data) {
          return axios.put(candidatureUrl + '/candidature/settings', data, {headers:header});
        }     

       // Gestion des classes
        static GetClasse(page, size, idProfession) {
          return axios.get(candidatureUrl + `/auditors/classrooms?page=${page}&size=${size}&search=professionID=${idProfession}`, {headers:header});
        }
        static deleteClasse(id) {
          return axios.delete(candidatureUrl + `/auditors/classrooms/${id}`, {headers:header});
        }
        static PostClasse(data) {
          return axios.post(candidatureUrl + '/auditors/classrooms', data, {headers:header});
        }
        static PutClasse(data) {
          return axios.put(candidatureUrl + '/auditors/classrooms', data, {headers:header});
        }     

       // Gestion des module
        static GetModule(page, size) {
          return axios.get(candidatureUrl + `/auditors/modules?page=${page}&size=${size}`, {headers:header});
        }
        static deleteModule(id) {
          return axios.delete(candidatureUrl + `/auditors/modules/${id}`, {headers:header});
        }
        static PostModule(data) {
          return axios.post(candidatureUrl + '/auditors/modules', data, {headers:header});
        }
        static PutModule(data) {
          return axios.put(candidatureUrl + '/auditors/modules', data, {headers:header});
        }     
        static PutModuleAddcorps(id, data) {
          return axios.put(candidatureUrl + `/auditors/modules/${id}/add_professions`, data, {headers:header});
        }     
        static PutModuleRemovecorps(id, data) {
          return axios.put(candidatureUrl + `/auditors/modules/${id}/remove_professions`, data, {headers:header});
        }     

      // Repartition competition center
      static PostRepartitionCompetionCenter(data) {
        return axios.post(candidatureUrl + '/candidature/repartitions/competition_center/list', data, {headers:header});
      }
      static GetRepartionsSalle(page, size, data, search) {
        return axios.get(candidatureUrl + `/candidature/repartitions/list?page=${page}&size=${size}&schoolYear=${data.schoolYear}&step=${data.step}${search}`, {headers:header});
      }
      static GetRepartionsSalleAnonime(page, size, data, search) {
        return axios.get(candidatureUrl + `/candidature/repartitions/list?page=${page}&size=${size}&schoolYear=${data.schoolYear}&step=${data.step}${search}`);
      }

      // Generés les numero de place
      static genererNumPlace(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures/generate_num_places', data, {headers:header});
      }

      // Corps
      static GetCorps(page, size) {
        return axios.get(candidatureUrl + `/candidature/professions?page=${page}&size=${size}`, {headers:header});
      }
      static deleteCorps(id) {
        return axios.delete(candidatureUrl + '/candidature/professions/'+id, {headers:header});
      }
      static PostCorps(data) {
        return axios.post(candidatureUrl + '/candidature/professions', data, {headers:header});
      }
      static PutCorps(data) {
        return axios.put(candidatureUrl + '/candidature/professions', data, {headers:header});
      }


      // Spécialité
      static GetProfession(page, size) {
        return axios.get(candidatureUrl + `/candidature/corps?page=${page}&size=${size}`, {headers:header});
      }
      static deleteProfession(id) {
        return axios.delete(candidatureUrl + '/candidature/corps/'+id, {headers:header});
      }
      static PostProfession(data) {
        return axios.post(candidatureUrl + '/candidature/corps', data, {headers:header});
      }
      static PutProfession(data) {
        return axios.put(candidatureUrl + '/candidature/corps', data, {headers:header});
      }

      // CategoryConcours
      static GetCategoryConcours(page, size) {
        return axios.get(candidatureUrl + `/candidature/competition_categories?page=${page}&size=${size}`, {headers:header});
      }
      static deleteCategoryConcours(id) {
        return axios.delete(candidatureUrl + '/candidature/competition_categories/'+id, {headers:header});
      }
      static PostCategoryConcours(data) {
        return axios.post(candidatureUrl + '/candidature/competition_categories', data, {headers:header});
      }
      static PutCategoryConcours(data) {
        return axios.put(candidatureUrl + '/candidature/competition_categories', data, {headers:header});
      }
      
      // Gestion des frais
      static GetFrais(page, size) {
        return axios.get(candidatureUrl + `/auditors/fees?page=${page}&size=${size}`, {headers:header});
      }
      static deleteFrais(id) {
        return axios.delete(candidatureUrl + '/auditors/fees/'+id, {headers:header});
      }
      static PostFrais(data) {
        return axios.post(candidatureUrl + '/auditors/fees', data, {headers:header});
      }
      static PutFrais(data) {
        return axios.put(candidatureUrl + '/auditors/fees', data, {headers:header});
      }
      
      // Gestion des paiements
      static GetCandidatNotPaid(page, size, year, search) {
        return axios.get(candidatureUrl + `/auditors/payments/candidate_for_paiement?id_school_year=${year}&page=${page}&size=${size}${search}`, {headers:header});
      }
      static GetPaiementFaite(page, size, search) {
        return axios.get(candidatureUrl + `/auditors/payments?page=${page}&size=${size}${search}`, {headers:header});
      }
      static PostPaiements(data) {
        return axios.post(candidatureUrl + '/auditors/payments', data, {headers:header});
      }
      static PutPaiementFaite(data) {
        return axios.put(candidatureUrl + '/auditors/payments', data, {headers:header});
      }
      static deletePaiementFaite(id) {
        return axios.delete(candidatureUrl + `/auditors/payments/${id}`, {headers:header});
      }
      
      // Gestion des Inscription
      static GetInscriptionsAttente(page, size, year, search) {
        return axios.get(candidatureUrl + `/auditors/registrations/candidate_for_registration?id_school_year=${year}&page=${page}&size=${size}${search}`, {headers:header});
      }
      static GetInscriptionsFaites(page, size, year, search) {
        return axios.get(candidatureUrl + `/auditors/registrations?id_school_year=${year}&page=${page}&size=${size}${search}`, {headers:header});
      }
      static PostValiderInscription(data) {
        return axios.post(candidatureUrl + '/auditors/registrations', data, {headers:header});
      }
      static deleteInscriptionFaite(id) {
        return axios.delete(candidatureUrl + `/auditors/registrations/${id}`, {headers:header});
      }

      // HandicapNature
      static GetHandicapNature(page, size) {
        return axios.get(candidatureUrl + `/candidature/handicap-natures?page=${page}&size=${size}`, {headers:header});
      }
      static deleteHandicapNature(id) {
        return axios.delete(candidatureUrl + '/candidature/handicap-natures/'+id, {headers:header});
      }
      static PostHandicapNature(data) {
        return axios.post(candidatureUrl + '/candidature/handicap-natures', data, {headers:header});
      }
      static PutHandicapNature(data) {
        return axios.put(candidatureUrl + '/candidature/handicap-natures', data, {headers:header});
      }

      // HandicapAssistance
      static GetHandicapAssistance(page, size) {
        return axios.get(candidatureUrl + `/candidature/handicap-assistances?page=${page}&size=${size}`, {headers:header});
      }
      static deleteHandicapAssistance(id) {
        return axios.delete(candidatureUrl + '/candidature/handicap-assistances/'+id, {headers:header});
      }
      static PostHandicapAssistance(data) {
        return axios.post(candidatureUrl + '/candidature/handicap-assistances', data, {headers:header});
      }
      static PutHandicapAssistance(data) {
        return axios.put(candidatureUrl + '/candidature/handicap-assistances', data, {headers:header});
      }

      
      // Academies
      static GetAcademy(page, size) {
        return axios.get(candidatureUrl + `/candidature/academies?page=${page}&size=${size}`, {headers:header});
      }
      static deleteAcademy(id) {
        return axios.delete(candidatureUrl + '/candidature/academies/'+id, {headers:header});
      }
      static PostAcademy(data) {
        return axios.post(candidatureUrl + '/candidature/academies', data, {headers:header});
      }
      static PutAcademy(data) {
        return axios.put(candidatureUrl + '/candidature/academies', data, {headers:header});
      }
      
      
      // Surveillants
      static GetSurveillants(page, size) {
        return axios.get(candidatureUrl + `/candidature/surveillants?page=${page}&size=${size}`, {headers:header});
      }
      
      
      // Spécialité et fonction
      static GetSpecialityFonction(page, size) {
        return axios.get(rhUrl + `/rh/specialty-functions?page=${page}&size=${size}`, {headers:header});
      }
      static deleteSpecialityFonction(id) {
        return axios.delete(rhUrl + '/rh/specialty-functions/'+id, {headers:header});
      }
      static PostSpecialityFonction(data) {
        return axios.post(rhUrl + '/rh/specialty-functions', data, {headers:header});
      }
      static PutSpecialityFonction(data) {
        return axios.put(rhUrl + '/rh/specialty-functions', data, {headers:header});
      }
      
      // Candidatures
      static GetCandidatures(page, size, search) {
        return axios.get(candidatureUrl + `/candidature/candidatures?page=${page}&size=${size}&sort=candidate.lastName%2Casc${search}`, {headers:header});
      }
      static GetCandidaturesListForCompetion(page, size, data) {
        return axios.get(candidatureUrl + `/candidature/candidatures/list_for_competition?page=${page}&size=${size}&sort=candidate.lastName%2Casc&id_year=${data.id_year}&id_profession=${data.id_profession}&id_category=${data.id_category}&status=${data.status}`);
      }
      static GetCandidaturesListForCompetionWithMoyenne(page, size, data) {
        return axios.get(candidatureUrl + `/candidature/candidatures/ranking_list?page=${page}&size=${size}&sort=overallAverage,desc&id_year=${data.id_year}&id_profession=${data.id_profession}&id_category=${data.id_category}&search=status=${data.status}`);
      }
      static deleteCandidatures(id) {
        return axios.delete(candidatureUrl + '/candidature/candidatures/'+id, {headers:header});
      }
      static PostCandidatures(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures', data, {headers:header});
      }
      static PutCandidatures(data) {
        return axios.put(candidatureUrl + '/candidature/candidatures', data, {headers:header});
      }
      static PutCandidaturesProcessing(data) {
        return axios.put(candidatureUrl + '/candidature/candidatures/processing', data, {headers:header});
      }
      static envoieCandidatureTemp(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures/temp/submit', data);
      }
      static candidates(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures/temp', data);
      }
      static CandidatureFind(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures/temp/find', data);
      }
      static CandidatureFindForUpdate(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures/find_for_update', data);
      }
      
      static uploadFiles(data) {
        return axios.post(candidatureUrl + '/candidature/candidatures/upload_files', data);
      }
      
      // Departement
      static GetDepartement(page, size, type) {
        return axios.get(rhUrl + `/rh/services?page=${page}&size=${size}&search=type=${type}`, {headers:header});
      }
      static deleteDepartement(id) {
        return axios.delete(rhUrl + '/rh/services/'+id, {headers:header});
      }
      static PostDepartement(data) {
        return axios.post(rhUrl + '/rh/services', data, {headers:header});
      }
      static PutDepartement(data) {
        return axios.put(rhUrl + '/rh/services', data, {headers:header});
      }
      
      // Departement
      static GetDepartement(page, size, type) {
        return axios.get(rhUrl + `/rh/services?page=${page}&size=${size}&search=type=${type}`, {headers:header});
      }
      static deleteDepartement(id) {
        return axios.delete(rhUrl + '/rh/services/'+id, {headers:header});
      }
      static PostDepartement(data) {
        return axios.post(rhUrl + '/rh/services', data, {headers:header});
      }
      static PutDepartement(data) {
        return axios.put(rhUrl + '/rh/services', data, {headers:header});
      }
      
      // Batiments
      static GetBatiments(page, size) {
        return axios.get(rhUrl + `/rh/buildings?page=${page}&size=${size}`, {headers:header});
      }
      static deleteBatiments(id) {
        return axios.delete(rhUrl + '/rh/buildings/'+id, {headers:header});
      }
      static PostBatiments(data) {
        return axios.post(rhUrl + '/rh/buildings', data, {headers:header});
      }
      static PutBatiments(data) {
        return axios.put(rhUrl + '/rh/buildings', data, {headers:header});
      }
      
      // Epreuve Concours
      static GetEpreuveConcours(page, size) {
        return axios.get(candidatureUrl + `/candidature/examinations?page=${page}&size=${size}`, {headers:header});
      }
      static deleteEpreuveConcours(id) {
        return axios.delete(candidatureUrl + '/candidature/examinations/'+id, {headers:header});
      }
      static PostEpreuveConcours(data) {
        return axios.post(candidatureUrl + '/candidature/examinations', data, {headers:header});
      }
      static PutEpreuveConcours(data) {
        return axios.put(candidatureUrl + '/candidature/examinations', data, {headers:header});
      }
      
      // Salle Centres
      static GetSalleCentres(page, size) {
        return axios.get(candidatureUrl + `/candidature/classrooms?page=${page}&size=${size}`, {headers:header});
      }
      static deleteSalleCentres(id) {
        return axios.delete(candidatureUrl + '/candidature/classrooms/'+id, {headers:header});
      }
      static PostSalleCentres(data) {
        return axios.post(candidatureUrl + '/candidature/classrooms/list', data, {headers:header});
      }
      static PutSalleCentres(data) {
        return axios.put(candidatureUrl + '/candidature/classrooms', data, {headers:header});
      }
      
      
      // Salle Batiments
      static GetSalleBatiments(page, size) {
        return axios.get(rhUrl + `/rh/rooms?page=${page}&size=${size}`, {headers:header});
      }
      static deleteSalleBatiments(id) {
        return axios.delete(rhUrl + '/rh/rooms/'+id, {headers:header});
      }
      static PostSalleBatiments(data) {
        return axios.post(rhUrl + '/rh/rooms', data, {headers:header});
      }
      static PutSalleBatiments(data) {
        return axios.put(rhUrl + '/rh/rooms', data, {headers:header});
      }
      
      
      // Equipement Salles
      static GetEquipementSalles(page, size) {
        return axios.get(rhUrl + `/rh/equipments?page=${page}&size=${size}`, {headers:header});
      }
      static deleteEquipementSalles(id) {
        return axios.delete(rhUrl + '/rh/equipments/'+id, {headers:header});
      }
      static PostEquipementSalles(data) {
        return axios.post(rhUrl + '/rh/equipments', data, {headers:header});
      }
      static PutEquipementSalles(data) {
        return axios.put(rhUrl + '/rh/equipments', data, {headers:header});
      }
      
      // Centre concours
      static GetCentreConcours(page, size) {
        return axios.get(candidatureUrl + `/candidature/competition_centers?page=${page}&size=${size}`, {headers:header});
      }
      static deleteCentreConcours(id) {
        return axios.delete(candidatureUrl + '/candidature/competition_centers/'+id, {headers:header});
      }
      static PostCentreConcours(data) {
        return axios.post(candidatureUrl + '/candidature/competition_centers', data, {headers:header});
      }
      static PutCentreConcours(data) {
        return axios.put(candidatureUrl + '/candidature/competition_centers', data, {headers:header});
      }
      
      // ServiceCandidats
      static GetServiceCandidats(page, size) {
        return axios.get(candidatureUrl + `/candidature/offices?page=${page}&size=${size}`, {headers:header});
      }
      static deleteServiceCandidats(id) {
        return axios.delete(candidatureUrl + '/candidature/offices/'+id, {headers:header});
      }
      static PostServiceCandidats(data) {
        return axios.post(candidatureUrl + '/candidature/offices', data, {headers:header});
      }
      static PutServiceCandidats(data) {
        return axios.put(candidatureUrl + '/candidature/offices', data, {headers:header});
      }
      
      // Type rejet
      static GetTypeRejet(page, size) {
        return axios.get(candidatureUrl + `/candidature/types_rejections?page=${page}&size=${size}`, {headers:header});
      }
      static deleteTypeRejet(id) {
        return axios.delete(candidatureUrl + '/candidature/types_rejections/'+id, {headers:header});
      }
      static PostTypeRejet(data) {
        return axios.post(candidatureUrl + '/candidature/types_rejections', data, {headers:header});
      }
      static PutTypeRejet(data) {
        return axios.put(candidatureUrl + '/candidature/types_rejections', data, {headers:header});
      }

      
      // Events
      static GetCandidatureEvents(page, size) {
        return axios.get(candidatureUrl + `/candidature/events?page=${page}&size=${size}`, {headers:header});
      }
      static deleteEvent(id) {
        return axios.delete(candidatureUrl + '/candidature/events/'+id, {headers:header});
      }
      static PostEvents(data) {
        return axios.post(candidatureUrl + '/candidature/events', data, {headers:header});
      }
      static PutEvents(data) {
        return axios.put(candidatureUrl + '/candidature/events', data, {headers:header});
      }


      static ModificationMotDePasse(data) {
        return axios.post(url + '/auth-server/users/updatePassword', data);
      }

      // Evenement type list
      static GetEventList() {
        return axios.get(candidatureUrl + '/candidature/events/types', {headers:header});
      }


      // static async updateUser(data) {
      //   return axios.put(url + '/users/updateUser', data, {headers: header});
      // }

      
      // static Listpub() {
      //   return axios.get(url + '/anonyme/Listpub').catch(function (error) {
      //     if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
      //       return axios.get(url + '/users/Listpub');
      //     }
      //   });
      // }

/* ***************************** 
PAUL PART */
    //ANNEE
    static GetAnneeScolaire() {
        return axios.get(candidatureUrl + `/candidature/schools_years`, {headers:header});
    }
    static saveAnneeScolaire(body) {
        return axios.post(candidatureUrl + `/candidature/schools_years`, body,{headers:header});
    }
    static updateAnneeScolaire(id,body) {
        return axios.put(candidatureUrl + `/candidature/schools_years`, body,{headers:header});
    }
    static deleteAnneeScolaire(id) {
        return axios.delete(candidatureUrl + `/candidature/schools_years/`+id,{headers:header});
    }
    static activeAnneeScolaire(id, body) {
        return axios.put(candidatureUrl + `/candidature/schools_years/`+id+`/active`, body,{headers:header});
    }

    // Personnels
    static GetPersonnels() {
        return axios.get(rhUrl + `/rh/personnels`, {headers:header});
    }
    static savePersonnel(body) {
        return axios.post(rhUrl + `/rh/personnels`, body,{headers:header});
    }

    static updatePersonnel(body) {
        return axios.put(rhUrl + `/rh/personnels`, body,{headers:header});
    }
    static deletePersonnel(id) {
        return axios.delete(rhUrl + `/rh/personnels/`+id,{headers:header});
    }

    static activeAndUnactivePersonnel(id, body) {
        return axios.put(rhUrl + `/rh/personnels/${id}/active`, body,{headers:header});
    }
    //SpecialeFont
    static getSpecialityFonction() {
        return axios.get(rhUrl + `/rh/specialty-functions`, {headers:header});
    }
    static getServices() {
        return axios.get(rhUrl + `/rh/services`,{headers:header});
    }

    //Role
    static GetRoles() {
        return axios.get(authUrl + `/auth-server/roles`, {headers:header});//.then(resp => resp);
    }
    static saveRole(body) {
        return axios.post(authUrl + `/auth-server/roles`, body,{headers:header});
    }
    static updateRole(body) {
        return axios.put(authUrl + `/auth-server/roles`, body,{headers:header});
    }
    static deleteRole(id) {
        return axios.delete(authUrl + `/auth-server/roles/`+id,{headers:header});
    }
    static activeRole(id, body) {
        return axios.put(authUrl + `/auth-server/roles/`+id+`/active`, body,{headers:header});
    }





}

export default Service;