import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let user = JSON.parse(localStorage.getItem("user"));
let type = "SERVICE"
let type2 = "DEPARTEMENT"
export default function Services() {
  const classes = useStyles();

  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [departement, setDepartement] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [state, setState] = React.useState({
    id: "",
    description: "",
    name: "",
    phone: "",
    email: "",
    type: type,
    service: {
      id: ""
    }
  });
  const [error, setError] = React.useState({
    description: false,
    name: false,
    phone: false,
    email: false,
    type: false,
    service: false,
  });

  React.useEffect(() => {
    (()=>{
      GetDepartement(page.page, page.size, type)
      GetDepartement(-1, -1, type2)
    })();
  }, []);


  const GetDepartement = async (page, size, typ) => {
    setSpinner(true)
    Service.GetDepartement(page, size, typ).then(
      async (response) => {
        setSpinner(false)
        if(typ === type) {
          setPage({
            page: page,
            size: size,
            totalElements: response.data.totalElements
          })
          setReponse(response.data.content)
        } else {
          setDepartement(response.data)

        }
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}

const OpenModal = async () => {
  let d = []
  setState(
    {
      id: "",
      description: "",
      name: "",
      phone: "",
      email: "",
      type: type,
      service: {
        id: ""
      }
    }
  )
  setOpen(true)
}
const modifier = async (data) => {
  setState({
    ...state,
    id: data.id,
    name: data.name,
    description: data.description,
    phone: data.phone,
    email: data.email,
    service: {
      id: data.service.id
    }
  })
  setOpen(true)
}
  const deleteDepartement = async (id) => {
    setSpinner(true)
    Service.deleteDepartement(id).then(
      async (response) => {
        GetDepartement(page.page, page.size, type);
        setSpinner(false)
        toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}



  const columns = [
      {
        name: "name",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "description",
        label: "Description",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "service.name",
        label: "Département",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return `${value.name}`;
          // }
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteDepartement(value)}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex])}}> <Edit /> Modifier </IconButton>
            </div>;
          }
        }
      }
    ];


  const cliquer = async () => {
    if(state.service.id === ""){
      setError({...error, service: true})
    } else if(state.name.trim() === ""){
      setError({...error, name: true, service: false})
    } else if(state.description.trim() === ""){
      setError({...error, description: true, name: false, service: false})
    } else if(state.phone.trim() === ""){
      setError({...error, phone: true, description: false, name: false, service: false})
    } else if(state.email.trim() === "" && !state.email.trim().includes("@")){
      setError({...error, email: true, phone: false, description: false, name: false, service: false})
    } else {
        setError({...error, email: false, phone: false, description: false, name: false, service: false})
        setSpinner(true);
        if(state.id === "") {
            Service.PostDepartement(state).then(
              async (response) => {
                GetDepartement(page.page, page.size, type)
                setSpinner(false);
                setOpen(false)
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
                // getAPIsList()
              
              }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          Service.PutDepartement(state).then(
            async (response) => {
              GetDepartement(page.page, page.size, type)
              setSpinner(false);
              setOpen(false)
              toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
              // getAPIsList()
            
            }, error => {
              setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    }
}

  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des services" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Ajouter un service
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Listes des services"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                GetDepartement(currentPage, page.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                GetDepartement(page.page, numberOfRows)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={4}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Nouveau service
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                          <Grid item xs={12}>
                                <span>Département</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, service: {...state.service, id: e}})}
                                  defaultValue={(state.service.id !== "") && state.service.id}
                                  options=
                                  {
                                    departement.length > 0 && departement.map((element) => {
                                      return { "value": element.id, "label": `${element.name}` }
                                    })
                                  }
                                />
                                {error.service && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.name} error={error.name} helperText={error.name && Constant.champObligatoire} onChange={(e)=>setState({...state, name: e.target.value})} size="small" label="Nom" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.description} error={error.description} helperText={error.description && Constant.champObligatoire} onChange={(e)=>setState({...state, description: e.target.value})} size="small" label="description" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.phone} error={error.phone} helperText={error.phone && Constant.champObligatoire} onChange={(e)=>setState({...state, phone: e.target.value})} size="small" label="Téléphone" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.email} error={error.email} helperText={error.email && Constant.champObligatoireEmail} onChange={(e)=>setState({...state, email: e.target.value})} size="small" label="Email" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
