export const Constant = {
    lienFichier: "/uploads/candidature",
    couleurPrimaire: "#6D859E",
    couleurSpinnerLine: "secondary",
    concernNonFonctionnaire: "NON_FONCTIONNAIRE",
    concernFonctionnaire: "FONCTIONNAIRE",
    fraisTab: [
      { label: "Frais d'inscription", value: "REGISTRATION_FEES" },
    ],
    ecarts: [
      { label: "Différence des notes", value: "MARK_DIFFERENCE" },
    ],
    niveauTab: [
      { label: "Tronc commun", value: "Tronc commun" },
      { label: "Spécialisation", value: "Spécialisation" },
    ],
    semester: [
      { label: "Tronc commun", value: "Tronc commun" },
      { label: "Spécialisation", value: "Spécialisation" },
    ],
    nbrCorrection: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
    ],
    etape: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
    ],
    cornfirmation: "Etes-vous sûr de votre action ?",
    champObligatoire: "Ce champs est obligatoire",
    champObligatoireFonctionnaire: "* Obligatoire pour les fonctionnaires",
    champObligatoireEmail: "Ce champs est obligatoire. Et l'email doit être valide.",
    typeEvenement: {
        candidat: "CANDIDATURE",
        traitement_dossier: "TRAITEMENT_DOSSIER",
        reclamation_dossier: "RECLAMATION_TRAITEMENT_DOSSIER",
        traitement_reclamation: "TRAITEMENT_RECLAMATION",
        LISTE_TEMPORAIRE_CONCOURS: "LISTE_TEMPORAIRE_CONCOURS",
        LISTE_DEFINITIVE_CONCOURS: "LISTE_DEFINITIVE_CONCOURS",
    },
    statusAnnee: {
        en_cours: "EN_COURS",
        passe: "PASSEE"
    },
    reponsesServeur:{
        suppressionEffectuer: "Suppression effectuée avec succès",
        ajouteffectuer: "Ajout effectué avec succès",
        paiementeffectuer: "Paiement effectué avec succès",
        validationeffectuer: "Validation effectuée avec succès",
        generationeffectuer: "Génération effectuée avec succès",
        repartitioneffectuer: "Répartition effectuée avec succès",
        rejeteffectuer: "Rejet effectué avec succès",
        modificationeffectuer: "Modification effectuée avec succès",
        erreurServeur: "Erreur du serveur. Merci de nous contacter si l'erreur persiste",
        nomExiste: "Ce nom existe déjà",
    },
    pagination: {
        Suivante: "Suivante >",
        Precedente: "< Précédente",
        ligneParPage: "Lignes par page:",
        sur: "sur",
    },
    textLabels: {
        body: {
          noMatch: "Rien à afficher pour le moment",
          toolTip: "Trier",
        },
        pagination: {
          next: "Suivante >",
          previous: "< Précédente",
          rowsPerPage: "Lignes par page:",
          displayRows: "sur",
        },
        toolbar: {
          search: "Recherche",
          downloadCsv: "Télécharger CSV",
          print: "Imprimer",
          viewColumns: "Afficher les colonnes",
          filterTable: "Tableau de filtrage",
        },
        filter: {
          all: "Tout",
          title: "FILTRES",
          reset: "RÉINITIALISER",
        },
        viewColumns: {
          title: "Afficher les colonnes",
          titleAria: "Afficher/Masquer les colonnes du tableau",
        },
        selectedRows: {
          text: "ligne(s) sélectionnée(s)",
          delete: "Supprimer",
          deleteAria: "Supprimer les lignes sélectionnées",
        },
      },
      //Paul part -->
       deleteMessage: "Suppression de ",
        formError:{
            incomplet: 'les champs avec (*) sont obligatoire !',
            emailFormat: 'Le format email est attendu. expl: monemail@gmail.com',
            phoneNumberFormat: '8 chiffres sont attendus.',
        },
       anneeStatus : {
        EN_COURS: { value:'EN_COURS', label: 'En cours'},
        PASSEE: { value:'PASSEE', label: 'Passée'},
       },
       Genres: [
            { value:'Homme', label: 'Homme'},
            { value:'Femme', label: 'Femme'},
       ],
       typePersonnel: [
           { value:'PERSONNEL ADMINISTRATIF', label: 'PERSONNEL ADMINISTRATIF'},
           { value:'FORMATEUR', label: 'FORMATEUR'},
       ],
}
