import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Select} from "antd";
import Methodes from "../../../utils";
import {Constant} from "../../../constant";
import Service from "../../../service";

const anneeFormData = {
    year: null,
    status: null
}

export default function AnneeForm ({rowData, actionRef, close}) {
    const [anneesForm, setAnneesForm] = useState(anneeFormData);
    console.log("rowData ", rowData);
    useEffect(() => {
       !Methodes.isEmpty(rowData) && setAnneesForm({...anneesForm, ...rowData});
    },[rowData]);

    //Functions
    function initForm(){
        setAnneesForm(anneeFormData)
    }

    function handleForm(e, name){
        console.log("e = ", e);
        let value = name === "status" ? e : e.target.value;
        setAnneesForm({...anneesForm, [name]: value});
    }
    //SUBMIT
    function submitForm(e){
        e.preventDefault();
        actionRef === 'add' ? save() : update();
    }

    function save(){
        Service.saveAnneeScolaire(anneesForm).then(response => {
            const msg = "Année "+anneesForm.year+" crée avec succès !";
            Methodes.ToastTopCenter('ok', msg , 7000);
            close('ok');
        }).catch(error =>{
            console.log("error ; ", error);
            Methodes.ToastTopCenter('error', Constant.reponsesServeur.erreurServeur, 7000)
        })
    }

    function update(){
        const updateBody = {...anneesForm}
        // delete updateBody.id;
        Service.updateAnneeScolaire(anneesForm.id, updateBody).then(response => {
            const msg = "Année "+anneesForm.year+" crée avec succès !";
            Methodes.ToastTopCenter('ok', msg , 7000)
            close('ok');
        }).catch(error =>{
            console.log("error ; ", error);
            Methodes.ToastTopCenter('error', Constant.reponsesServeur.erreurServeur, 7000)
        })
    }

    return(
        <>
            <form>
                <div style={{marginTop:10}}>
                    <label htmlFor="year">Année scolaire <span style={{color: "red"}}>*</span></label>
                    <Input
                        status={Methodes.isEmpty(anneesForm.year) &&  "error"}
                        placeholder="Année scolaire"
                        name={'year'}
                        value={anneesForm.year}
                        onChange={(e)=> handleForm(e, 'year')}
                    />
                    {Methodes.isEmpty(anneesForm.year) && <span style={{color: "red", fontSize:12, paddingLeft:10}}>{Constant.champObligatoire}</span>}
                </div>

                <div style={{marginTop:10}}>
                    <label htmlFor="year">Statut <span style={{color: "red"}}>*</span></label>
                    <Select
                        // defaultValue={actionRef === 'edit' && anneesForm.status}
                        value={anneesForm.status}
                        style={{ width: "100%" }}
                        showSearch
                        onSelect={(e) => handleForm(e, 'status')}
                        options={[Constant.anneeStatus.EN_COURS, Constant.anneeStatus.PASSEE]}
                    />
                    {Methodes.isEmpty(anneesForm.year) && <span style={{color: "red", fontSize:12, paddingLeft:10}}>{Constant.champObligatoire}</span>}
                </div>

                <div style={{marginTop:20}}>
                    <Row>
                        <Col span={12} style={{textAlign:"left"}}>
                            <Button  key="submit" type="primary" onClick={submitForm}>
                                {actionRef === 'add' ? 'Créer une nouvelle année' : 'Modifier'}
                            </Button>,
                        </Col>
                        <Col span={12} style={{textAlign:"right"}}>
                            <Button key="back"  onClick={() => close('cancel')}>
                                Fermer
                            </Button>
                        </Col>
                    </Row>
                </div>

            </form>

        </>
    )
}
