import React from "react";



export default function Dashboard() {
const userData = JSON.parse(localStorage.getItem("user"));
const design = {
    textAlign: 'center'
}

    return (
        <div style={design}>
            <h1>Bienvenue dans votre session d'administration ENA</h1>
            <h3>{userData.username} : {userData.email}</h3>
        </div>
    )
}