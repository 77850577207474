import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Radio, Select, TimePicker } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, DeleteOutline, Edit, PictureAsPdf, Search } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {num2} from '../../../chant/num2'
// import HTMLtoDOCX from "html-to-docx";
// import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const format = 'HH:mm';
const list = 'LIST';

export default function GestionNoteAnonimat() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [programmation, setProgrammation] = useState([]);
  const [epreuve, setEpreuve] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
    idEvaluation: ""
  });
  const [value, setValue] = useState("");

  
  React.useEffect(() => {
    (()=>{
      // pdf(betiba, "betiba")
      bindAnnees()
    })();
  }, []);

  const onChange = (e) => {
    setValue(e);
  };

const GetEvaluations = async (page, size, year) => {
  Service.GetEvaluations(page, size, year).then(
    async (response) => {
      setProgrammation(response.data)
    }, error => {
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          GetEvaluations(-1, -1, year)
      });
    }


  const columns = [
      {
        name: "anonymous",
        label: "Anonymat",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "mark",
        label: "Note",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "candidate.fullName",
        label: "Nom complet",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
         }
      },
      {
        name: "candidate.birthdate",
        label: "Date de naissance",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
         }
      },
      {
        name: "candidate.birthplace",
        label: "Lieu de naissance",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
         }
      },
      {
        name: "candidate.gender",
        label: "Genre",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
         }
      },
      {
        name: "candidate.phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
         }
      },
      {
        name: "candidate.email",
        label: "Email",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
         }
      },
      {
        name: "personnel.firstName",
        label: "Prénom agent",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
         }
      },
      {
        label: "Nom agent",
        name: "personnel.lastName",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
        }
      },
      {
        label: "Téléphone agent",
        name: "personnel.phone",
        options: {
          filter: true,
          sort: true,
          display: value !== "Informations" ? false : true,
        }
      }
    ];


const GetNoteAnonymats = async (page, size, type) => {
  setSpinner(true)
  if(value === "Informations"){
    Service.GetNoteInformationPersonnelle(page, size, recherche.idEvaluation, recherche.numberCorrection).then(
      async (response) => {
        setSpinner(false)
          setPage({
            page: page,
            size: size,
            totalElements: response.data.totalElements
          })
          setReponse(response.data.content)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
  } else {
      let r = programmation.filter((e)=> e.id === recherche.idEvaluation)[0];
      let str = "Note pour " + r.programmationExamination.examination.name + " (Etape: "+r.programmationExamination.examination.step+")" + " ("+r.programmationExamination.programmationCategoryProfession.competitionCategory.name+")"
      Service.GetNoteAnonymats(page, size, recherche.idEvaluation, type, recherche.numberCorrection, value).then(
        async (response) => {
          setSpinner(false)
          if(type === "LIST") {
            setPage({
              page: page,
              size: size,
              totalElements: response.data.totalElements
            })
            setReponse(response.data.content)
          } else {
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${str}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        }, error => {
          setSpinner(false);
          toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      })
    }
}

  return (
    <>
    
      <ToastContainer />
      <PageTitle title="Gestion des notes avec anonymats" />
      <Grid container spacing={4}>
        <Grid item sm={6}>
          <Select
            placeholder="Evaluation"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: "100%", zIndex: 1}}
              onChange={(e)=>setRecherche({...recherche, idEvaluation: e})}
              options=
              {
                programmation.length > 0 && programmation.map((element) => {
                  return { "value": element.id, "label": element.programmationExamination.examination.name + " (Etape: "+element.programmationExamination.examination.step+")" + " ("+element.programmationExamination.programmationCategoryProfession.competitionCategory.name+")" }
                })
              }
            />
        </Grid>
        <Grid item sm={3}>
            <Select
            placeholder="Nombre de correction"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, numberCorrection: e})}
                options=
                {Constant.nbrCorrection}
              />
        </Grid>
        <Grid item sm={3}>
            <Select
                placeholder="Type de retour"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: "100%", zIndex: 1}}
                  onChange={(e)=>onChange(e)}
                  options={
                    [
                      {value: "true", label: "Anonimats sans les notes"},
                      {value: "false", label: "Anonimats avec les notes"},
                      {value: "Informations", label: "Informations personnelles avec les notes"},
                    ]
                  }
                />
        </Grid>
        <Grid item sm={3} style={{flexDirection: 'row'}}>
          <Button onClick={(e)=>GetNoteAnonymats(page.page, page.size, list)} variant="contained" startIcon={<Search />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher la liste
          </Button>
        </Grid>
        {value !== "Informations" && value !== "" &&
          <Grid item sm={4} style={{flexDirection: 'row'}}>
            <Button onClick={(e)=>GetNoteAnonymats(page.page, page.size, "PDF")} variant="contained" startIcon={<PictureAsPdf />} style={{color: 'black', backgroundColor: 'white'}}>
              Télécharger le PDF
            </Button>
          </Grid>
        }
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des notes avec anonymats"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetNoteAnonymats(currentPage, page.size, list)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetNoteAnonymats(page.page, numberOfRows, list)
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
