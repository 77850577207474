import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let user = JSON.parse(localStorage.getItem("user"));
export default function SalleCentres() {
  const classes = useStyles();

  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [centreConcours, setCentreConcours] = useState([]);
  const [surveillant, setSurveillant] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [act, setAct] = useState("salle");
  const [surveillantSelect, setSurveillantSelect] = useState([]);
  let multiple = true;
  const [state, setState] = React.useState({
    id: "",
    acronym: "",
    numberPlace: 0,
    competitionCenter: {
      id: "",
    }
  });
  const [error, setError] = React.useState({
    acronym: false,
    numberPlace: false,
    competitionCenter: false,
  });

  React.useEffect(() => {
    (()=>{
      GetSalleCentres(page.page, page.size)
      GetCentreConcours(-1, -1)
      GetSurveillants(-1, -1)
    })();
  }, []);


  const GetSurveillants = async (page, size) => {
      setSpinner(true)
      Service.GetSurveillants(page, size).then(
        async (response) => {
          setSurveillant(response.data)
        })
  }

  const GetCentreConcours = async (page, size) => {
      setSpinner(true)
      Service.GetCentreConcours(page, size).then(
        async (response) => {
          setCentreConcours(response.data)
        })
  }

  const GetSalleCentres = async (page, size) => {
    setSpinner(true)
    Service.GetSalleCentres(page, size).then(
      async (response) => {
        setSpinner(false)
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}

const OpenModal = async () => {
  multiple = true
  let d = []
  setState(
    {
      id: "",
      acronym: "",
      numberPlace: 0,
      competitionCenter: {
        id: "",
      }
    }
  )
  setOpen(true)
}
const modifier = async (data, act) => {
  multiple = false
  setAct(act)
  setState({
    id: data.id,
    acronym: data.acronym,
    numberPlace: data.numberPlace,
    competitionCenter: {
      id: data.competitionCenter.id,
    },
  })
  if(act === "addsurveillant") {
    // profession.map((e, index) => {
    //   if(!data.moduleProfession.some((a)=> a.profession.id === e.id)) {
    //     d.push(e)
    //   }
    //   if((index+1)===profession.length){
    //     setSurveillantSelect(d)
    //     console.log("");
    //   }
    // })
  } else if(act === "removesurveillant") {
    // data.moduleProfession.map((e, index) => {
    //     d.push(e.profession)
    //   if((index+1)===data.moduleProfession.length){
    //     setSurveillantSelect(d)
    //     console.log("");
    //   }
    // })
  }
  setOpen(true)
}
  const deleteSalleCentres = async (id) => {
    setSpinner(true)
    Service.deleteSalleCentres(id).then(
      async (response) => {
        GetSalleCentres(page.page, page.size);
        setSpinner(false)
        toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}

const actionSurveillant = async () => {
  let d = [];
  state.profession.map((e)=> {
    d.push({id: e})
  })
  setSpinner(true);
  if(act === "addsurveillant") {
      Service.PutModuleAddcorps(state.id, d).then(
        async (response) => {
          toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
          setSpinner(false);
          setOpen(false)
          // GetModule(page.page, page.size)
        }, error => {
            setSpinner(false);
            toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
        }
      )
  } else {
    Service.PutModuleRemovecorps(state.id, d).then(
      async (response) => {
        toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
        setSpinner(false);
        setOpen(false)
        // GetModule(page.page, page.size)
      }, error => {
          setSpinner(false);
          toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    )
  }
// }
}



  const columns = [
      {
        name: "acronym",
        label: "Acronyme",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "numberPlace",
        label: "Nombre de place",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "competitionCenter.name",
        label: "Centre du concours",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return `(${value.acronym}) ${value.name}`;
          // }
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteSalleCentres(value)}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex], 'salle')}}> <Edit /> Modifier </IconButton>
              {/* <br/>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex], "removesurveillant")}}> <Delete /> Supprimer des surveillants</IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex], "addsurveillant")}}> <Add /> Ajouter des surveillants </IconButton> */}
            </div>;
          }
        }
      }
    ];


  const cliquer = async () => {
    if(state.competitionCenter.id === ""){
      setError({...error, competitionCenter: true})
    } else if(state.acronym.trim() === ""){
      setError({...error, acronym: true, competitionCenter: false})
    } if(state.numberPlace <= 0){
      setError({...error, numberPlace: true, acronym: false, competitionCenter: false})
    } else {
        setError({...error, numberPlace: false, acronym: false, competitionCenter: false})
        setSpinner(true);
        if(state.id === "") {
          let data = []
            state.competitionCenter.id.map((e)=>{
              if(e !== false){
                let d = {}
                d.acronym = state.acronym
                d.numberPlace = parseInt(state.numberPlace)
                d.competitionCenter = {
                  id: e
                }
                data.push(d)
              }
            })
            Service.PostSalleCentres(data).then(
              async (response) => {
                GetSalleCentres(page.page, page.size)
                setSpinner(false);
                setOpen(false)
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
                // getAPIsList()
              
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          Service.PutSalleCentres(state).then(
            async (response) => {
              GetSalleCentres(page.page, page.size)
              setSpinner(false);
              setOpen(false)
              toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
              // getAPIsList()
            
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    }
}

  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des salles des centres" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Ajouter une salle
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Listes des salles des centres"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetSalleCentres(currentPage, page.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetSalleCentres(page.page, numberOfRows)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={4}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       {act==="salle" && (state.id === "" ? "Nouvelle salle" : "Modification de la salle")}
                      {(act==="removesurveillant") && "Suppression des surveillants"}
                      {(act==="addsurveillant") && "Ajout des surveillants"}
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                        {act==="salle" ?
                          <Grid container spacing={3}>
                          <Grid item xs={12}>
                                <span>Centre du concours</span>
                                <br/>
                                <Select
                                  mode={multiple ? "multiple" : ""}
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, competitionCenter: {...state.competitionCenter, id: e}})}
                                  defaultValue={state.competitionCenter.id !== "" && state.competitionCenter.id}
                                  options=
                                  {
                                    centreConcours.length > 0 && centreConcours.map((element) => {
                                      return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                                    })
                                  }
                                />
                                {error.competitionCenter && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.acronym} error={error.acronym} helperText={error.acronym && Constant.champObligatoire} onChange={(e)=>setState({...state, acronym: e.target.value})} size="small" label="Acronyme" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField type="number" defaultValue={state.numberPlace} error={error.numberPlace} helperText={error.numberPlace && Constant.champObligatoire} onChange={(e)=>setState({...state, numberPlace: parseInt(e.target.value)})} size="small" label="Nombre de place" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        :
                        <Grid container spacing={3}>
                          <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Select
                                    mode="multiple"
                                    placeholder="Surveillants"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{ width: "100%", zIndex: 1}}
                                    onChange={(e)=>setState({...state, profession: e})}
                                    options=
                                    {
                                      surveillant.length > 0 && surveillant.map((element) => {
                                        return { "value": element.id, "label": `${element.firstName} ${element.lastName} (${element.phone})` }
                                      })
                                    }
                                  />
                              </Grid>
                                <Grid item xs={6}>
                                  <Button variant="outlined" color="secondary" onClick={(e)=>actionSurveillant()} disabled={spinner}>
                                    {act==="addsurveillant" ? "Ajouter" : "Supprimer"}
                                    {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                                  </Button>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                  <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                    Fermer
                                  </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
