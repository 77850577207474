import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select, TimePicker } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, DeleteOutline, Edit, PictureAsPdf, Search } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {num2} from '../../../chant/num2'
// import HTMLtoDOCX from "html-to-docx";
// import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const format = 'HH:mm';
const list = 'list';

export default function GestionModules() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [reponse2, setReponse2] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [act, setAct] = useState("module");
  const [profession, setProfesion] = useState([]);
  const [professionSelect, setProfesionSelect] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
    idEvaluation: ""
  });
  const [state, setState] = React.useState({
    id: "",
    type: "Candidats",
  });
  const [error, setError] = React.useState({
    level: false,
    name: false,
    profession: false,
    year: false,
  });

  React.useEffect(() => {
    (()=>{
      GetModule(page.page, page.size)
      GetProfession(-1, -1)
    })();
  }, []);



const OpenModal = async (type) => {
  setAct("module")
  setOpen(true)
}

const modifier = async (data, act) => {
  setAct(act)
  setState({
    ...state,
    id: data.id,
    name: data.name,
    code: data.code,
    semester: data.semester,
    hourNumber: data.hourNumber,
    credit: data.credit,
  })
  let d = []
  if(act === "addcorps") {
    profession.map((e, index) => {
      if(!data.moduleProfession.some((a)=> a.profession.id === e.id)) {
        d.push(e)
      }
      if((index+1)===profession.length){
        setProfesionSelect(d)
        console.log("");
      }
    })
  } else if(act === "removecorps") {
    data.moduleProfession.map((e, index) => {
        d.push(e.profession)
      if((index+1)===data.moduleProfession.length){
        setProfesionSelect(d)
        console.log("");
      }
    })
  }
  setOpen(true)
}

const deleteModule = async (data) => {
  setSpinner(true);
  Service.deleteModule(data.id).then(
    async (response) => {
      toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      GetModule(page.page, page.size)
      setSpinner(false);
    }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    }
  )
}


const GetProfession = async (page, size) => {
  Service.GetCorps(page, size).then(
    async (response) => {
      setProfesion(response.data)
    })
}

  const columns = [
      {
        name: "name",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "code",
        label: "Code",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "semester",
        label: "Formation",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "hourNumber",
        label: "Nombre d'heure",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "credit",
        label: "Crédit",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "moduleProfession",
        label: "Corps",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <>
              {value.map((e, index)=>{
                return <div key={index}>{e.profession.name}<hr/></div>
              })}
              </>;
          }
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteModule(tableMeta.tableData[tableMeta.rowIndex])}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex], "module")}}> <Edit /> Modifier </IconButton>
              <br/>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex], "removecorps")}}> <Delete /> Supprimer des corps</IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex], "addcorps")}}> <Add /> Ajouter des corps </IconButton>
            </div>;
          }
        }
      }
    ];

  const cliquer = async () => {
    // if(state.name === undefined || state.name === null){
    //   setError({...error, name: true})
    // } else if(state.code === undefined || state.code === null){
    //   setError({...error, code: true, name: false})
    // } else if(state.semester === undefined || state.semester === null){
    //   setError({...error, code: false, name: false, semester: true})
    // } else if(state.level === undefined || state.level === null){
    //   setError({...error, profession: false, year: false, level: true, name: false})
    // } else{
    //     setError({...error, profession: false, year: false, level: false, name: false})
        setSpinner(true);
        let data ={
          id: state.id,
          name: state.name,
          code: state.code,
          semester: state.semester,
          hourNumber: state.hourNumber,
          credit: state.credit,
        }
        if(state.id === "") {
            delete data.id
            Service.PostModule(data).then(
              async (response) => {
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
                setSpinner(false);
                setOpen(false)
                GetModule(page.page, page.size)
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          Service.PutModule(data).then(
            async (response) => {
              toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
              setSpinner(false);
              setOpen(false)
              GetModule(page.page, page.size)
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    // }
}

  const actionCorps = async () => {
        let d = [];
        state.profession.map((e)=> {
          d.push({id: e})
        })
        setSpinner(true);
        if(act === "addcorps") {
            Service.PutModuleAddcorps(state.id, d).then(
              async (response) => {
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
                setSpinner(false);
                setOpen(false)
                GetModule(page.page, page.size)
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          Service.PutModuleRemovecorps(state.id, d).then(
            async (response) => {
              toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
              setSpinner(false);
              setOpen(false)
              GetModule(page.page, page.size)
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    // }
}

const GetModule = async (page, size) => {
  setSpinner(true)
  Service.GetModule(page, size).then(
    async (response) => {
      setSpinner(false)
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

  return (
    <>
    
      <ToastContainer />
      <PageTitle title="Gestion des modules" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={4}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Nouveau module
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des modules"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetModule(currentPage, page.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetModule(page.page, numberOfRows)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                      {act==="module" && (state.id === "" ? "Nouveau module" : "Modification du module")}
                      {(act==="removecorps") && "Suppression des corps"}
                      {(act==="addcorps") && "Ajout des corps"}
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          {act==="module" ?
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <TextField error={error.name} helperText={error.name && Constant.champObligatoire} defaultValue={state.name} onChange={(e)=>setState({...state, name: e.target.value})} size="small" label="Nom du module" variant="outlined" fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Edit />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField error={error.code} helperText={error.code && Constant.champObligatoire} defaultValue={state.code} onChange={(e)=>setState({...state, code: e.target.value})} size="small" label="Code du module" variant="outlined" fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Edit />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                  <Select
                                      allowClear
                                      placeholder="Formation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                      }
                                      style={{ width: "100%", zIndex: 1}}
                                      onChange={(e)=>setState({...state, semester: e})}
                                      options={Constant.semester}
                                    />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField type="number" error={error.hourNumber} helperText={error.hourNumber && Constant.champObligatoire} defaultValue={state.hourNumber} onChange={(e)=>setState({...state, hourNumber: e.target.value})} size="small" label="Nombre d'heure" variant="outlined" fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Edit />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField type="number" error={error.credit} helperText={error.credit && Constant.champObligatoire} defaultValue={state.credit} onChange={(e)=>setState({...state, credit: e.target.value})} size="small" label="Crédit" variant="outlined" fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Edit />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                              </Grid>
                              <Grid item xs={6}>
                                <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                  {state.id === "" ? "Ajouter" : "Modifier"}
                                  {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                                </Button>
                              </Grid>
                              <Grid item xs={6} style={{textAlign: 'right'}}>
                                <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                  Fermer
                                </Button>
                              </Grid>
                            </Grid>
                          :
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                              <Select
                                  mode="multiple"
                                  placeholder="Corps"
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, profession: e})}
                                  options=
                                  {
                                    professionSelect.length > 0 && professionSelect.map((element) => {
                                      return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                                    })
                                  }
                                />
                              </Grid>
                                <Grid item xs={6}>
                                  <Button variant="outlined" color="secondary" onClick={(e)=>actionCorps()} disabled={spinner}>
                                    {act==="addcorps" ? "Ajouter" : "Supprimer"}
                                    {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                                  </Button>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'right'}}>
                                  <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                    Fermer
                                  </Button>
                                </Grid>
                            </Grid>
                          }
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
