import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {num2} from '../../../chant/num2'
// import HTMLtoDOCX from "html-to-docx";
// import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let multiple = true;
export default function ProgrammationEpreuveConcours() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [programmation, setProgrammation] = useState([]);
  const [epreuve, setEpreuve] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
  });
  const [state, setState] = React.useState({
    id: "",
    schoolYear: {
      id: "",
    },
    competitionCategory: {
      id: "",
    },
    profession: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    schoolYear: false,
    competitionCategory: false,
    profession: false,
    programmation: false,
    coefficient: false,
    examination: false,
    numberCorrection: false,
  });

  React.useEffect(() => {
    (()=>{
      // pdf(betiba, "betiba")
      GetEpreuveConcours(-1, -1)
      bindAnnees()
    })();
  }, []);


  // const download = async () => {
  //   let str = "";
  //   divRef.current.forEach(async (element, index) => {
  //     str = str + element.innerHTML;
  //     if((index+1) === divRef.current.length) {
  //       const content = `
  //       <html lang="fr"><meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  //       <body>
  //       <div>${str}</div>
  //       </body>
  //       </html>`;
  //       const data = await HTMLtoDOCX(content, null, {
  //         table: { row: { cantSplit: true } },
  //         footer: true,
  //         pageNumber: true,
  //       });
  //       saveAs(data, "numero2_final_Pierre.docx");
  //     }
  //   })
  // };

  const GetEpreuveConcours = async (page, size) => {
    Service.GetEpreuveConcours(page, size).then(
      async (response) => {
        setEpreuve(response.data)
      })
}

const GetProgrammationEpreuve = async (page, size, year) => {
  setSpinner(true)
  Service.GetProgrammationEpreuve(page, size, year).then(
    async (response) => {
      setSpinner(false)
      setPage({
        page: page,
        size: size,
        totalElements: response.data.totalElements
      })
      setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          GetProgrammationConcours(-1, -1, year)
          GetProgrammationEpreuve(page.page, page.size, year)
      });
    }

    const GetProgrammationConcours = async (page, size, year) => {
      Service.GetProgrammationConcours(page, size, year).then(
        async (response) => {
          setProgrammation(response.data)
        })
    }

const OpenModal = async () => {
  multiple = true
  let d = []
  setState(
    {
      id: "",
      schoolYear: {
        id: "",
      },
      competitionCategory: {
        id: "",
      },
      profession: {
        id: "",
      },
    }
  )
  setOpen(true)
}

const modifier = async (data) => {
  multiple = false
  setState({
    programmation: data.programmationCategoryProfession.competitionCategory.id + "/2474/" +data.programmationCategoryProfession.profession.id,
    coefficient: data.coefficient,
    examination: {
      id: data.examination.id
    },
    numberCorrection: data.numberCorrection,
  })
  setOpen(true)
}
  const deleteProgrammationEpreuve = async (data) => {
    setSpinner(true)
    Service.deleteProgrammationEpreuve(
      data.programmationCategoryProfession.schoolYear.id,
      data.programmationCategoryProfession.competitionCategory.id,
      data.programmationCategoryProfession.profession.id,
      data.examination.id
      ).then(
      async (response) => {
        GetProgrammationEpreuve(page.page, page.size, recherche.annee);
        setSpinner(false)
        toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}

  const columns = [
      {
        name: "examination.name",
        label: "Epreuve",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "examination.step",
        label: "Etape",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "coefficient",
        label: "Coefficient",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "numberCorrection",
        label: "Nombre de correction",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "programmationCategoryProfession.profession.name",
        label: "Corps",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "programmationCategoryProfession.competitionCategory.name",
        label: "Type du concours",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteProgrammationEpreuve(tableMeta.tableData[tableMeta.rowIndex])}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex])}}> <Edit /> Modifier </IconButton>
            </div>;
          }
        }
      }
    ];


  const cliquer = async () => {
    if(state.programmation === undefined && state.programmation.length === 0 || state.programmation === null && state.programmation.length === 0){
      setError({...error, programmation: true})
    } else if(state.examination === undefined || state.examination === null){
      setError({...error, examination: true, programmation: false})
    } else if(state.coefficient === undefined || state.coefficient === null){
      setError({...error, coefficient: true, examination: false, programmation: false})
    } else if(state.numberCorrection === undefined || state.numberCorrection === null){
      setError({...error, numberCorrection: true, coefficient: false, examination: false, programmation: false})
    } else{
      setError({...error, numberCorrection: false, coefficient: false, examination: false, programmation: false})
      setSpinner(true);
      if(state.id === "") {
          let data = []
          state.programmation.map((e)=>{
            if(e !== false){
              let d = {}
              d.coefficient = parseInt(state.coefficient)
              d.numberCorrection = parseInt(state.numberCorrection)
              d.examination = state.examination
              d.programmationCategoryProfession = programmation.filter((a)=>a.competitionCategory.id === e.split("/2474/")[0] && a.profession.id === e.split("/2474/")[1])[0]
              data.push(d)
            }
          })
            Service.PostProgrammationEpreuve(data).then(
              async (response) => {
                GetProgrammationEpreuve(page.page, page.size, recherche.annee)
                setSpinner(false);
                setOpen(false)
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          let data = {}
            data.coefficient = parseInt(state.coefficient)
            data.numberCorrection = parseInt(state.numberCorrection)
            data.examination = state.examination
            data.programmationCategoryProfession = programmation.filter((a)=>a.competitionCategory.id === state.programmation.split("/2474/")[0] && a.profession.id === state.programmation.split("/2474/")[1])[0]
          Service.PutProgrammationEpreuve(data).then(
            async (response) => {
              GetProgrammationEpreuve(page.page, page.size, recherche.annee)
              setSpinner(false);
              setOpen(false)
              toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    }
}


  return (
    <>
    {/* <div>
      <button onClick={() => download()}>DOwnload</button>
    </div>
    {
      num2.map((item, index) => {
        return <div style={{paddingLeft: 10, paddingRight: 10, backgroundColor: 'white'}} ref={el => divRef.current[index] = el} >
          <p style={{fontWeight: 'bold', fontSize: 15,}}>{(index + 1)+") "}</p>
        {item.length>0 && item.map((element, ind) => {
          if(element.titre !== undefined){
            return <div key={"c"+ind} style={{marginTop: 20, borderBottomWidth: 1, marginBottom: 20, borderLeftWidth: 1}}>
                <p style={{ fontSize: 15, paddingBottom: 5, paddingLeft: 5, fontWeight: 'bold'}}>{element.titre.split("\n").join("<br>")}</p>
              </div>
          }
          if(element.refrain !== undefined){
            return <p key={"d"+ind} style={{fontSize: 10, marginLeft: 70, marginBottom: 20}}>{element.refrain.split("\n").join("<br>")}</p>
          }
          if(element.couple !== undefined){
            return <p key={"e"+ind} style={{fontSize: 10, paddingBottom: 20, marginLeft: 35}}>{element.couple.split("\n").join("<br>")}</p>
          }              
        })}
      </div>
        
      })
    } */}
      <ToastContainer />
      <PageTitle title="Programmation des épreuves selon le corps" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Programmer une épreuve
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={2}>
            <Select
                placeholder="Année"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, annee: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  annee.length > 0 && annee.map((element) => {
                    return { "value": element.id, "label": element.year }
                  })
                }
              />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={(e)=>GetProgrammationEpreuve(page.page, page.size, recherche.annee)} variant="contained" startIcon={<Add />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher
            </Button>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des épreuves programmés"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetProgrammationEpreuve(currentPage, page.size, recherche.annee)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetProgrammationEpreuve(page.page, numberOfRows, recherche.annee)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Programmation d'une épreuve
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Corps</span>
                                <br/>
                                <Select
                                  showSearch
                                  mode={multiple ? "multiple" : ""}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, programmation: e})}
                                  defaultValue={(state.programmation !== null && state.programmation !== undefined) && state.programmation}
                                  options=
                                  {
                                    programmation.length > 0 && programmation.map((element) => {
                                      return { "value": element.competitionCategory.id + "/2474/" +element.profession.id, "label": element.profession.name + " ("+element.competitionCategory.name+")" }
                                    })
                                  }
                                />
                                {error.programmation && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <span>Epreuve du concours</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, examination: {...state.examination, id: e}})}
                                  defaultValue={(state.examination !== null && state.examination !== undefined) && state.examination.id}
                                  options=
                                  {
                                    epreuve.length > 0 && epreuve.map((element) => {
                                      return { "value": element.id, "label": element.name + " ( Etape: "+element.step+")" }
                                    })
                                  }
                                />
                                {error.examination && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField type="number" defaultValue={state.coefficient} error={error.coefficient} helperText={error.coefficient && Constant.champObligatoire} onChange={(e)=>setState({...state, coefficient: e.target.value})} size="small" label="Coefficient" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <span>Nombre de correction</span>
                                  <br/>
                                  <Select
                                      showSearch
                                      filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                      }
                                      style={{ width: "100%", zIndex: 1}}
                                      onChange={(e)=>setState({...state, numberCorrection: e})}
                                      defaultValue={state.numberCorrection > 0 && state.numberCorrection}
                                      options=
                                      {Constant.nbrCorrection}
                                    />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
