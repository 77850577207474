import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let multiple = true;
let user = JSON.parse(localStorage.getItem("user"));
export default function Programmation() {
  const classes = useStyles();

  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [apivalide, setApiValide] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [typeconcours, setTypeconcours] = useState([]);
  const [profesion, setProfesion] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
  });
  const [state, setState] = React.useState({
    id: "",
    schoolYear: {
      id: "",
    },
    competitionCategory: {
      id: "",
    },
    profession: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    schoolYear: false,
    competitionCategory: false,
    profession: false,
  });

  React.useEffect(() => {
    (()=>{
      GetProfession(-1, -1)
      GetCategoryConcours(-1, -1)
      bindAnnees()
    })();
  }, []);


  const GetCategoryConcours = async (page, size) => {
    Service.GetCategoryConcours(page, size).then(
      async (response) => {
        setTypeconcours(response.data)
      })
}


const GetProgrammationConcours = async (page, size, year) => {
  setSpinner(true)
  Service.GetProgrammationConcours(page, size, year).then(
    async (response) => {
      setSpinner(false)
      setPage({
        page: page,
        size: size,
        totalElements: response.data.totalElements
      })
      setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          GetProgrammationConcours(page.page, page.size, year)
      });
    }
  const GetProfession = async (page, size) => {
    Service.GetCorps(page, size).then(
      async (response) => {
        setProfesion(response.data)
      })
}

const OpenModal = async () => {
  multiple = true
  let d = []
  setState(
    {
      id: "",
      schoolYear: {
        id: "",
      },
      competitionCategory: {
        id: "",
      },
      profession: {
        id: "",
      },
    }
  )
  setOpen(true)
}

  const deleteProgrammationConcours = async (rowData) => {
    setSpinner(true)
    Service.deleteProgrammationConcours(rowData.schoolYear.id, rowData.competitionCategory.id, rowData.profession.id).then(
      async (response) => {
        GetProgrammationConcours(page.page, page.size, recherche.annee);
        setSpinner(false)
        toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}



  const columns = [
      {
        name: "schoolYear.year",
        label: "Année",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return value.year;
          // }
         }
      },
      {
        name: "competitionCategory.name",
        label: "Type du concours",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return value.name;
          // }
         }
      },
      {
        name: "profession.name",
        label: "Corps",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return "("+value.acronym+") " + value.name;
          // }
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteProgrammationConcours(tableMeta.tableData[tableMeta.rowIndex])}}> <Delete /> Supprimer </IconButton>
              {/* <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta)}}> <Edit /> Modifier </IconButton> */}
            </div>;
          }
        }
      }
    ];


  const cliquer = async () => {
    if(state.schoolYear.id === ""){
      setError({...error, schoolYear: true})
    } else if(state.competitionCategory.id === ""){
      setError({...error, competitionCategory: true, schoolYear: false})
    } else if(state.profession.id === ""){
      setError({...error, profession: true, competitionCategory: false, schoolYear: false})
    } else{
        setError({...error, profession: false, competitionCategory: false, schoolYear: false})
        setSpinner(true);
        if(state.id === "") {
          let data = []
          state.profession.id.map((e)=>{
            if(e !== false){
              let d = {}
              d.competitionCategory = state.competitionCategory
              d.schoolYear = state.schoolYear
              d.profession = {
                id: e
              }
              data.push(d)
            }
          })
            Service.PostProgrammationConcours(data).then(
              async (response) => {
                GetProgrammationConcours(page.page, page.size, recherche.annee)
                setSpinner(false);
                setOpen(false)
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          let data = {
            schoolYear: {
              id: state.schoolYear.id,
            },
            competitionCategory: {
              id: state.competitionCategory.id,
            },
            profession: {
              id: state.profession.id,
            },
          }
          Service.PutProgrammationConcours(data).then(
            async (response) => {
              GetProgrammationConcours(page.page, page.size, recherche.annee)
              setSpinner(false);
              setOpen(false)
              toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    }
}


  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des programmations des concours" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Programmer un concours
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={2}>
            <Select
                placeholder="Année"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, annee: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  annee.length > 0 && annee.map((element) => {
                    return { "value": element.id, "label": element.year }
                  })
                }
              />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={(e)=>GetProgrammationConcours(page.page, page.size, recherche.annee)} variant="contained" startIcon={<Add />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher
            </Button>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des concours programmés"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetProgrammationConcours(currentPage, page.size, recherche.annee)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetProgrammationConcours(page.page, numberOfRows, recherche.annee)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Nouvelle programmation
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Année</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, schoolYear: {...state.schoolYear, id: e}})}
                                  defaultValue={state.schoolYear.id !== "" && state.schoolYear.id}
                                  options=
                                  {
                                    annee.length > 0 && annee.map((element) => {
                                      return { "value": element.id, "label": element.year }
                                    })
                                  }
                                />
                                {error.schoolYear && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <span>Type du concours</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, competitionCategory: {...state.competitionCategory, id: e}})}
                                  defaultValue={state.competitionCategory.id !== "" && state.competitionCategory.id}
                                  options=
                                  {
                                    typeconcours.length > 0 && typeconcours.map((element) => {
                                      return { "value": element.id, "label": element.name }
                                    })
                                  }
                                />
                                {error.competitionCategory && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <span>Corps</span>
                                <br/>
                                <Select
                                  showSearch
                                  mode={multiple ? "multiple" : ""}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, profession: {...state.profession, id: e}})}
                                  defaultValue={state.profession.id !== "" && state.profession.id}
                                  options=
                                  {
                                    profesion.length > 0 && profesion.map((element) => {
                                      return { "value": element.id, "label": element.name + " ("+element.acronym+")" }
                                    })
                                  }
                                />
                                {error.profession && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
