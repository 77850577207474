import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select, TimePicker } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, DeleteOutline, Edit, PictureAsPdf, Search } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {num2} from '../../../chant/num2'
// import HTMLtoDOCX from "html-to-docx";
// import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const format = 'HH:mm';
const list = 'list';

export default function GestionClasse() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [reponse2, setReponse2] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [profession, setProfesion] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
    idEvaluation: ""
  });
  const [state, setState] = React.useState({
    id: "",
    type: "Candidats",
  });
  const [error, setError] = React.useState({
    level: false,
    name: false,
    profession: false,
    year: false,
  });

  React.useEffect(() => {
    (()=>{
      // pdf(betiba, "betiba")
      bindAnnees()
      // GetClasse(page.page, page.size)
    })();
  }, []);


  const GetProfession = async (page, size) => {
    Service.GetCorps(page, size).then(
      async (response) => {
        setProfesion(response.data)
      })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          GetProfession(-1, -1)
      });
    }

const OpenModal = async (type) => {
  let d = []
  setState({
    ...state,
    id: "",
    level: "",
    year: "",
    profession: "",
  })
  setOpen(true)
}

const modifier = async (data) => {
  setState({
    ...state,
    id: data.id,
    name: data.name,
    level: data.level,
    year: data.schoolYearID,
    profession: data.profession.id,
  })
  setOpen(true)
}

const deleteClasse = async (data) => {
  Service.deleteClasse(data.id).then(
    async (response) => {
      toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      GetClasse(page.page, page.size)
    }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    }
  )
}


  const columns = [
      {
        name: "name",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "level",
        label: "Formation",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "profession.name",
        label: "Corps",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "year",
        label: "Année",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteClasse(tableMeta.tableData[tableMeta.rowIndex])}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex])}}> <Edit /> Modifier </IconButton>
            </div>;
          }
        }
      }
    ];

  const cliquer = async () => {
    if(state.year === undefined || state.year === null){
      setError({...error, year: true})
    } else if(state.profession === undefined || state.profession === null){
      setError({...error, profession: true, year: false})
    } else if(state.level === undefined || state.level === null){
      setError({...error, profession: false, year: false, level: true, name: false})
    } else{
        setError({...error, profession: false, year: false, level: false, name: false})
        setSpinner(true);
        let an = annee.filter((a)=> a.id === state.year)
        let data ={
          id: state.id,
          level: state.level,
          year: an[0].year,
          schoolYearID: an[0].id,
          profession: {
            id: state.profession
          },
        }
        if(state.id === "") {
            delete data.id
            Service.PostClasse(data).then(
              async (response) => {
                toast.success(Constant.reponsesServeur.ajouteffectuer, {position: "top-center", autoClose: 10000,})
                setSpinner(false);
                setOpen(false)
                GetClasse(page.page, page.size)
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        } else {
          Service.PutClasse(data).then(
            async (response) => {
              toast.success(Constant.reponsesServeur.modificationeffectuer, {position: "top-center", autoClose: 10000,})
              setSpinner(false);
              setOpen(false)
              GetClasse(page.page, page.size)
            }, error => {
                setSpinner(false);
                toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
            }
          )
        }
    }
}

const GetClasse = async (page, size) => {
  setSpinner(true)
  Service.GetClasse(page, size, recherche.profession).then(
    async (response) => {
      setSpinner(false)
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

  return (
    <>
    
      <ToastContainer />
      <PageTitle title="Gestion des classes" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={4}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Nouvelle classe
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item sm={6}>
            <Select
                placeholder="Corps"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, profession: e})}
                options=
                {
                  profession.length > 0 && profession.map((element) => {
                    return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                  })
                }
              />
        </Grid>
        <Grid item sm={4}>
          <Button onClick={(e)=>GetClasse(page.page, page.size)} variant="contained" startIcon={<Search />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher la liste
          </Button>
        </Grid>
        <Grid item sm={2}>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des classes"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetClasse(currentPage, page.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetClasse(page.page, numberOfRows)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                      {state.id === "" ? "Nouvelle classe" : "Modification de la classe"}
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                          <Grid item xs={12}>
                                <span>Année</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, year:  e})}
                                  defaultValue={state.year !== "" && state.year}
                                  options=
                                  {
                                    annee.length > 0 && annee.map((element) => {
                                      return { "value": element.id, "label": element.year }
                                    })
                                  }
                                />
                                {error.year && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <span>Corps</span>
                                <br/>
                                <Select
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, profession: e})}
                                  defaultValue={state.profession !== "" && state.profession}
                                  options=
                                  {
                                    profession.length > 0 && profession.map((element) => {
                                      return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                                    })
                                  }
                                />
                                {error.profession && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <span>Formation</span>
                                <br/>
                                <Select
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, level: e})}
                                  defaultValue={state.level !== "" && state.level}
                                  options={Constant.niveauTab}
                                />
                                {error.level && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
