import React, {useState, useEffect, useRef} from "react";
import Service from "../../../service";
import CustomTable from '../../../components/CrudGenerator/CrudGenerator';
import {IconButton } from "@material-ui/core";
import { Add, Delete, Edit, ChevronLeft, Close } from "@material-ui/icons";
import { Button, Modal } from 'antd';
import AnneeForm from './AnneeForm';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {Constant} from "../../../constant";
import Methodes from "../../../utils";
import {CommonModal} from "../../../components/_Ena/CommonModal";
const { confirm } = Modal;


export default function Annees() {

    //States
    const [annees, setAnnees] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [modal, setModal] = useState(false);
    const actionRef = useRef("")

     //Vars
     const columns = [
        {
          name: "year",
          label: "Année",
          options: {
            filter: true,
            sort: true           }
        },
        {
          name: "status",
          label: "Status",
          options: {
            filter: true,
            sort: true           }
        },
        {
          name: "id",
          label: "Action",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) =>  {
                return <div>
                <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {deleteConfirm(value, tableMeta)}}> <Delete /> Supprimer </IconButton>
                <IconButton style={{color: 'primary', fontSize: 15}} onClick={(e)=> {modifier(tableMeta)}}> <Edit /> Modifier </IconButton>
                <IconButton style={{color: 'success', fontSize: 15}} onClick={(e)=> {activaeConfirm(value, tableMeta, true)}}> <ChevronLeft /> Activer </IconButton>
                <IconButton style={{color: 'info', fontSize: 15}} onClick={(e)=> {activaeConfirm(value, tableMeta, false)}}> <Close /> Déactiver </IconButton>
              </div>
              }
           }
        }];
     const options = {
         filterType: "checkbox",
         onRowClick: (rowData) => {
         console.log("RowClicked->", rowData);
     }}

    const modalOpen = () => {
        setModal(true);
      };
     const modalClose = () => {
        setModal(false)
     };

     function formClosed(){
        bindAnnees();
        modalClose()
    }
    function bindAnnees (){
        Service.GetAnneeScolaire().then(anneesBinded => {
            console.log("datatableData :", anneesBinded.data.content);
            setAnnees({...annees, original: anneesBinded.data.content, datatableData: anneesBinded.data.content });
        });
    }
    function add (){
        actionRef.current = 'add';
        modalOpen();
    }

    function modifier (data){
        console.log("Data : ", data);
        actionRef.current = 'edit';
        setRowData({
            id: data.rowData[2],
            year: data.rowData[0],
            status: data.rowData[1],
        });

        modalOpen(true);
        
    }

    function deleteConfirm (id, data){
        console.log("delet d", data);
        confirm({
            title: Constant.cornfirmation,
            icon: <ExclamationCircleFilled />,
            content: Constant.deleteMessage+' '+data.rowData[0],
            okText: 'Oui, supprimer !',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                deleteAnnee(id);
            },
            onCancel() {},
        });
    }

    function deleteAnnee(id){
         Service.deleteAnneeScolaire(id).then(deleted =>{
             Methodes.ToastTopCenter('ok', deleted, 7000);
             bindAnnees();
         }).catch(error =>{
             console.log("delete error",error);
             Methodes.ToastTopCenter('error', error, 7000);
         })
    }

    function activaeConfirm (id, data, state){
        console.log("activ d", data);
        confirm({
            title: 'Etes-vous sûr de votre action ?',
            icon: <ExclamationCircleFilled />,
            content: state ? 'Activer : '+data.rowData[0] : 'Déactiver '+data.rowData[0],
            okText: state ? 'Oui, activer !' : 'Oui, Déactiver !',
            okType: 'primary',
            cancelText: 'Non',
            onOk() {
                activeAnnee(id, state);
            },
            onCancel() {},
        });
    }
    function activeAnnee(id, state){
         const body = {active: state}
        Service.activeAnneeScolaire(id, body).then(deleted =>{
            const msg = state ? 'Activé avec succès !' : 'Désactivé avec succès !'
            Methodes.ToastTopCenter('ok', msg, 7000);
            bindAnnees();
        }).catch(error =>{
            console.log("active error",error);
            Methodes.ToastTopCenter('error', Constant.reponsesServeur.erreurServeur, 7000);
        })
    }

    console.log("Call --> ");

    useEffect(()=>{
        bindAnnees();
    }, []);

   
    console.log("datatableData ; ", annees.datatableData);

    return (
        <div>
            <Button onClick={(e)=>{add()}} variant="outlined" style={{color: 'green', borderColor: 'green'}}>
                Ajouter une année
            </Button>
            <CustomTable 
                title={'Gestion des Années'}
                columns={columns} 
                datatableData={annees.datatableData} options={options} />

                <CommonModal
                    title={actionRef.current === 'add' ? 'Créer une nouvelle année' : 'Modification'}
                    openState={modal}
                    close={modalClose}
                    width={500}
                    >

                    <AnneeForm
                        rowData={rowData}
                        actionRef={actionRef.current}
                        close={(action) => action === 'cancel' ? modalClose() : formClosed()}
                    />

                </CommonModal>
        </div>
    )
}