import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, CheckCircle, Delete, Edit, History, Money } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let user = JSON.parse(localStorage.getItem("user"));
export default function GestionPaiements() {
  const classes = useStyles();
  let search = ""
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [apivalide, setApiValide] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  var [open, setOpen] = useState(false);
  var [info, setInfo] = useState({});
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  var [stateCherche, setStateCherche] = useState({
    firstName: "",
    applicationNumber: "",
    lastName: "",
    phone: "",
  });
  const [recherche, setRecherche] = React.useState({
    annee: 0,
    search: ""
  });
  const [state, setState] = React.useState({
    id: "",
    schoolYear: {
      id: "",
    },
    competitionCategory: {
      id: "",
    },
    profession: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    frais: false,
    competitionCategory: false,
    profession: false,
  });

  React.useEffect(() => {
    (()=>{
      bindAnnees()
    })();
  }, []);


const GetCandidatNotPaid = async (page, size, year, search) => {
  setSpinner(true)
  setRecherche({...recherche, search: search})
  Service.GetCandidatNotPaid(page, size, year, search).then(
    async (response) => {
      setSpinner(false)
      setPage({
        page: page,
        size: size,
        totalElements: response.data.totalElements
      })
      setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          // GetCandidatNotPaid(page.page, page.size, year, recherche.search)
      });
    }

const OpenModal = async (data) => {
  setInfo(data)
  setState(
    {
      id: "",
      schoolYear: {
        id: recherche.annee,
      },
      candidature: {
        id: data.idCandidature,
      },
    }
  )
  setOpen(true)
}


  const columns = [
      {
        name: "applicationNumber",
        label: "Numéro du dossier",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "amount",
        label: "Montant",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "firstName",
        label: "Prénom",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "lastName",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "profession",
        label: "Corps",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "competitionCategory",
        label: "Type du concours",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: "green", fontSize: 15}} onClick={(e)=> {OpenModal(tableMeta.tableData[tableMeta.rowIndex])}}> <CheckCircle /> Faire le paiement </IconButton>
            </div>;
          }
        }
      }
    ];


  const cliquer = async () => {
    if(state.feeType === ""){
      setError({...error, feeType: true})
    } else{
        setError({...error, feeType: false})
        setSpinner(true);
        if(state.id === "") {
          let data = state
          delete data.id
          if(state.numReceipter !== undefined && state.numReceipter.trim() === "") {delete data.numReceipter}
          if(state.numReceipt !== undefined && state.numReceipt.trim() === "") {delete data.numReceipt}
            Service.PostPaiements(data).then(
              async (response) => {
                GetCandidatNotPaid(page.page, page.size, recherche.annee, recherche.search)
                setSpinner(false);
                setOpen(false)
                toast.success(Constant.reponsesServeur.paiementeffectuer, {position: "top-center", autoClose: 10000,})
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        }
    }
}


const afficher = async () => {
  search = "&search="
  if(stateCherche.applicationNumber.trim() !== ""){
    search = `${search}applicationNumber%3D${stateCherche.applicationNumber} AND `
  }
  if(stateCherche.firstName.trim() !== ""){
    search =`${search}candidate__firstName%3D${stateCherche.firstName} AND `
  }
  if(stateCherche.lastName.trim() !== ""){
    search =`${search}candidate__lastName%3D${stateCherche.lastName} AND `
  }
  if(stateCherche.phone.trim() !== ""){
    search =`${search}candidate__phone%3D${stateCherche.phone} AND `
  }
  GetCandidatNotPaid(page.page, page.size, recherche.annee, (search !== "&search=" ? search.slice(0, -5) : ""))
}

  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des paiements en attente" />
      <Grid container spacing={4}>
        <Grid item xs={2}>
            <Select
                placeholder="Année"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, annee: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  annee.length > 0 && annee.map((element) => {
                    return { "value": element.id, "label": element.year }
                  })
                }
              />
        </Grid>
        <Grid item sm={2}>
              <TextField onChange={(e)=>setStateCherche({...stateCherche, applicationNumber: e.target.value})} size="medium" placeholder="Numéro Dossier"  fullWidth
                  InputProps={{
                    style: {background: 'white'},
                    startAdornment: (
                      <InputAdornment position="start">
                        <Edit />
                      </InputAdornment>
                    ),
                  }}
                />
          </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, firstName: e.target.value})} size="medium" placeholder="Prénom"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, lastName: e.target.value})} size="medium" placeholder="Nom"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item sm={2}>
            <TextField onChange={(e)=>setStateCherche({...stateCherche, phone: e.target.value})} size="medium" placeholder="Téléphone"  fullWidth
                InputProps={{
                  style: {background: 'white'},
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
              />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={(e)=>afficher()} variant="contained" startIcon={<Add />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher
            </Button>
        </Grid>
        <Grid item sm={2}>
          <Button size="small" onClick={(e)=>{GetCandidatNotPaid(page.page, page.size, recherche.annee, recherche.search)}} variant="outlined" startIcon={<History />} style={{color: 'white', borderColor: 'white'}}>
              Actualiser
          </Button>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des paiements en attente"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetCandidatNotPaid(currentPage, page.size, recherche.annee, recherche.search)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetCandidatNotPaid(page.page, numberOfRows, recherche.annee, recherche.search)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h3 style={{textAlign: 'center', color: 'red', paddingTop: 50, paddingBottom: 20, paddingRight: 30, paddingLeft: 30}}>
                      Assurez-vous d'avoir récupéré l'argent avant de {state.id === "" ? "valider" : "modifier"} le paiement de: <br/>{info.firstName} {info.lastName} ({info.phone})
                    </h3>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Type de frais</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, feeType:  e})}
                                  defaultValue={state.feeType !== "" && state.feeType}
                                  options={Constant.fraisTab}
                                />
                                {error.frais && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.numReceipter} onChange={(e)=>setState({...state, numReceipter: e.target.value})} size="small" label="Numéro de quittancer" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.numReceipt} onChange={(e)=>setState({...state, numReceipt: e.target.value})} size="small" label="Quittance" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Valider" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
