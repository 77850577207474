import React, { useState } from "react";
import { Button, CircularProgress, Grid, InputAdornment, LinearProgress, Modal, Paper, TextField, Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
  IconButton
} from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// data
import Service from "../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit, Person } from "@material-ui/icons";
import { Constant } from "../../constant";
import useStyles from "../dashboard/styles";
const { RangePicker } = DatePicker;



export default function GestionApis() {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  var [open, setOpen] = useState(false);
  const [state, setState] = React.useState({
    id:0,
    nom: "",
    description: "",
    type: "",
    montant: 0,
  });
  const [error, setError] = React.useState({
    nom: false,
    description: false,
    type: false,
    montant: false,
  });

  React.useEffect(() => {
    getAPIsList()
  }, []);


  const getAPIsList = async () => {
      Service.getAPIsList('all').then(
        async (response) => {
          setReponse(response.data)
        })
  }

  const changeStatut = async (id) => {
      setSpinner(true);
      Service.changeStatutAPIs(id).then(
        async (response) => {
          getAPIsList();
          setSpinner(false);
        }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      })
  }

  const init = () => {
    setState({
      id:0,
      nom: "",
      description: "",
      type: "",
      montant: 0,
    });
  }

  const cliquer = async () => {
    if(state.nom.trim() === ""){
      setError({...error, nom: true})
    } else if(state.description.trim() === ""){
      setError({...error, description: true, nom: false})
    } else if(state.montant <= 0){
      setError({...error, montant: true, description: false, nom: false})
    } else if(state.type.trim() === ""){
      setError({...error, type: true, montant: false, description: false, nom: false})
    } else {
        setSpinner(true);
        Service.SaveApi(state).then(
          async (response) => {
            setSpinner(false);
            init()
            setOpen(false)
            toast.success("Ajout effectué avec succès", {position: "top-center", autoClose: 10000,})
            getAPIsList()
          
          }, error => {
              setSpinner(false);
              toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
          }
        )
    }
}


  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des APIs" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{setOpen(true); init()}} variant="outlined" startIcon={<Add />} style={{color: 'green', borderColor: 'green'}}>
            Ajouter un API
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color="secondary" />}
          <Widget title="Listes des APIs" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            {reponse.length > 0 && 
            <Table className="mb-0">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Facturation</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Date d'ajout</TableCell>
                    <TableCell colSpan={2}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reponse.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="pl-3 fw-normal">{item.id}</TableCell>
                      <TableCell>{item.nom}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.montant}{item.type === "Pourcentage" ? "%" : " XOF"}</TableCell>
                      <TableCell>
                        <Chip label={item.statut ? "Activé" : "Desactivé"} classes={{root: item.statut ? classes.success : classes.secondary}}/>
                      </TableCell>
                      <TableCell>{item.dateinscription}</TableCell>
                      <TableCell> <IconButton color="primary" onClick={(e)=> {setState(item); setOpen(true)}}><Edit /> </IconButton></TableCell>
                      <TableCell> <IconButton style={{color: item.statut ? 'red' : 'green'}} onClick={(e)=> changeStatut(item.id)}> {item.statut ? <Cancel /> : <Check />}</IconButton></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          </Widget>
        </Grid>
      </Grid>


      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 0}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={4}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Page d'ajout des APIs
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.nom} error={error.nom} helperText={error.nom && Constant.champObligatoire} onChange={(e)=>setState({...state, nom: e.target.value})} size="small" label="Nom de l'API" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Edit />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.description} error={error.description} helperText={error.description && Constant.champObligatoire} onChange={(e)=>setState({...state, description: e.target.value})} size="small" label="Description de l'API" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Edit />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                                <span>Type de facturation</span>
                                <br/>
                                <Select
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, type: e})}
                                  defaultValue={state.type}
                                  options={[
                                    { value: 'Pourcentage', label: 'Pourcentage' },
                                    { value: 'Transaction', label: 'Transaction' },
                                    // { value: 'Journalière', label: 'Journalière' },
                                    // { value: 'Hebdomendaire', label: 'Hebdomendaire' },
                                    // { value: 'Mensuel', label: 'Mensuel' },
                                    // { value: 'Trimestriel', label: 'Trimestriel' },
                                    // { value: 'Annuelle', label: 'Annuelle' },
                                  ]}
                                />
                                {error.type && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField type="number" defaultValue={state.montant} error={error.montant} helperText={error.montant && Constant.champObligatoire} onChange={(e)=>setState({...state, montant: e.target.value})} size="small" label="Montant de la facturation" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AccountBalanceWallet />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === 0 ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
