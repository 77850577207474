

//Remplie les champs du formulaire
import Methodes from "../../../utils";

export function formElementEditControl(e, name, formRef){
    console.log("event val => ", e);
    formRef.current[name].value = e;
}


export function checkFieldValidity(prevForm, name, value){ //ex
    // prevForm[name].req
    if (Methodes.isNotEmpty(value)) {
        prevForm[name].valided = 'ok';
        if (prevForm[name].pattern) {
            if (Methodes.isNotEmpty(value.match(prevForm[name].pattern))) {
                prevForm[name].valided = 'ok';
            } else {
                prevForm[name].valided = 'no';
            }
        }
    }else {
        prevForm[name].valided = 'no';
    }
    prevForm[name].value = value;
    return prevForm;
}


export function checkFormValidity(form){ //ex
    console.log("formRef :: --> ", form);
    let reqCount = 0;
    let validCount = 0;
    Object.entries(form).forEach(f => {
        if (f[1].req) reqCount ++;
        if (f[1].req && Methodes.isNotEmpty(f[1].value)){
            f[1].valided = 'ok';
            validCount ++;
            if (f[1].pattern){// vérification du pattern
                if (Methodes.isNotEmpty(f[1].value.match(f[1].pattern))){
                    f[1].valided = 'ok';
                }else {
                    f[1].valided = 'no';
                    validCount--;
                }
            }
        }
    });
    console.log("reqCount", reqCount);
    console.log("validCount", validCount);
    console.log("form Val 1", form);
    return +reqCount === +validCount;
}