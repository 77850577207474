import {toast} from "react-toastify";
import {PersonnelsDonnees} from "./datas/PersonnelsData";
import Countrys from './datas/paysNationnalites.json';



class Methodes {

    constructor(){}

    /** */
    static convertToTableData(data){
        return data.map(d => {
            delete d.id;
            return Object.values(d);
        });
    }

    // TOAST
    static Build_Toast(type, message, options){
        if (type ==='ok')
            return toast.success(message, options);
        else if('error')
            return  toast.error(message, options);
        else if('info')
            return toast.info(message, options);
        else
            return toast.warning(message, options);
    }

    static ToastTopCenter(type, message, time = undefined){
        const options = {position: "top-center", autoClose: time ? +time: 10000,}

        return this.Build_Toast(type, message, options);
    }

    static ToastTopRigth(type,message, time = undefined){
        const options = {position: "top-rigth", autoClose: time ? +time: 10000,}
        return this.Build_Toast(type, message, options);
    }

    //-
    static isEmpty(element){
        return element == "" || element == null || element == undefined;
    }
    static isNotEmpty(element){
        return element !== "" && element !== null && element !== undefined;
    }


    static fn(value, required,  pattern=null){
        return {value: value,  req: required, valided: 'yet', pattern: pattern};
    }

    static allCountryOrNationalityList(type){
        return Countrys.map(country => {
            return type === 'pays' ?
                {value: country.country, label: country.country} :
                {value: country.nationality, label: country.nationality}
        })
    }
    static getPersonnelsData(type){
        return PersonnelsDonnees[type].map(data => {
            const val = !["corpsPersonnelAdministratif","etatsService"].includes(type) ? data : data.nom;
            return {value: val , label: val}
        })
    }



}

export default Methodes;

