import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Select, TimePicker } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, DeleteOutline, Edit, PictureAsPdf, Search } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {num2} from '../../../chant/num2'
// import HTMLtoDOCX from "html-to-docx";
// import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const format = 'HH:mm';
const list = 'list';

export default function GestionEcarts() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [reponse2, setReponse2] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [programmation, setProgrammation] = useState([]);
  const [epreuve, setEpreuve] = useState([]);
  var [open, setOpen] = useState(false);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
    idEvaluation: ""
  });
  const [state, setState] = React.useState({
    id: "",
    type: "Candidats",
  });
  const [error, setError] = React.useState({
    date: false,
    heure: false,
    profession: false,
    programmation: false,
    coefficient: false,
    examination: false,
    numberCorrection: false,
  });

  React.useEffect(() => {
    (()=>{
      // pdf(betiba, "betiba")
      bindAnnees()
    })();
  }, []);


const GetEvaluations = async (page, size, year) => {
  Service.GetEvaluations(page, size, year).then(
    async (response) => {
      setProgrammation(response.data)
    }, error => {
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          GetEvaluations(-1, -1, year)
      });
    }

const OpenModal = async (type) => {
  setState({...state, type: type, id: ""})
  let d = []
  setOpen(true)
  getListEcart()
}

const modifier = async (data) => {
  setState({
    ...state,
    id: data.id,
    name: data.name,
    value: data.value,
  })
}

const deleteEcart = async (data) => {
  setSpinner(true);
  Service.DeleteEcart(data.id).then(
    async (response) => {
      setSpinner(false);
      toast.success(Constant.reponsesServeur.suppressionEffectuer, {position: "top-center", autoClose: 10000,})
      getListEcart()
    }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    }
  )
}

const getListEcart = async () => {
  setSpinner(true);
  Service.GetEcart().then(
    async (response) => {
      setReponse2(response.data.content)
      setSpinner(false);
    }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    }
  )
}

  const columns = [
      {
        name: "candidateNumberAnonymity",
        label: "Anonymat",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "mark1",
        label: "Note 1",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "mark2",
        label: "Note 2",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "markDiff",
        label: "Différence",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "dateMark1",
        label: "Date de la note 1",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "dateMark2",
        label: "Date de la note 2",
        options: {
          filter: true,
          sort: true,
         }
      },
    ];

  const columns2 = [
      {
        name: "name",
        label: "Type d'écart",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let type = Constant.ecarts.filter((a) => a.value===tableMeta.tableData[tableMeta.rowIndex].name);
            return <div>{type[0].label}</div>;
          }
         }
      },
      {
        name: "value",
        label: "Valeur",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Action",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>
              <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteEcart(tableMeta.tableData[tableMeta.rowIndex])}}> <Delete /> Supprimer </IconButton>
              <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex])}}> <Edit /> Modifier </IconButton>
            </div>;
          }
        }
      }
    ];


  const cliquer = async () => {
    if(state.name === undefined || state.name === null){
      setError({...error, name: true})
    } else if(state.value === undefined || state.value === null){
      setError({...error, value: true, name: false})
    } else{
        setError({...error, name: false, value: false})
        setSpinner(true);
        let data ={
          id: state.id,
          name: state.name,
          value: state.value,
        }
        if(state.id === "") {
            delete data.id
            Service.PostEcart(data).then(
              async (response) => {
                setSpinner(false);
                setOpen(false)
                getListEcart()
                toast.success(response.data, {position: "top-center", autoClose: 10000,})
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
                }
                )
          } else {
            Service.PutEcart(data).then(
              async (response) => {
                setSpinner(false);
                setOpen(false)
                getListEcart()
                toast.success(response.data, {position: "top-center", autoClose: 10000,})
              }, error => {
                  setSpinner(false);
                  toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
              }
            )
        }
    }
}

const GetEcartCandidat = async (page, size) => {
  setSpinner(true)
  Service.GetEcartCandidat(page, size, recherche.idEvaluation).then(
    async (response) => {
      setSpinner(false)
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
    }, error => {
      setSpinner(false);
      toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
  })
}

  return (
    <>
    
      <ToastContainer />
      <PageTitle title="Gestion des écarts" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={4}>
          <Button onClick={(e)=>{OpenModal("Ecarts")}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Gestion des écarts
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item sm={6}>
          <Select
            placeholder="Evaluation"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: "100%", zIndex: 1}}
              onChange={(e)=>setRecherche({...recherche, idEvaluation: e})}
              options=
              {
                programmation.length > 0 && programmation.map((element) => {
                  return { "value": element.id, "label": element.programmationExamination.examination.name + " (Etape: "+element.programmationExamination.examination.step+")" + " ("+element.programmationExamination.programmationCategoryProfession.competitionCategory.name+")" }
                })
              }
            />
        </Grid>
        <Grid item sm={4}>
          <Button onClick={(e)=>GetEcartCandidat(page.page, page.size)} variant="contained" startIcon={<Search />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher la liste
          </Button>
        </Grid>
        <Grid item sm={2}>
        </Grid>
        <Grid item xs={12}>
          {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title="Liste des candidats inclus dans l'écart"
            data={reponse}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: serverSide,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: page.size,
              count: page.totalElements,
              textLabels: Constant.textLabels,
              selectableRows: "none",
              setFilterChipProps: (colIdx, colName, data) => {
                return {
                  label: `${colName}: ${data}`,
                }
              },
              onTableChange(action, tableState) {
                if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                  setServerSide(false)
                }
              },
              onChangePage(currentPage) {
                setServerSide(true)
                GetEcartCandidat(currentPage, page.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                setServerSide(true)
                GetEcartCandidat(page.page, numberOfRows)
              },
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                      {state.id === "" ? "Nouveau écart" : "Modification de l'écart"}
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Type d'écart</span>
                                <br/>
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, name: e})}
                                  value={(state.name !== null && state.name !== undefined) && state.name}
                                  options={Constant.ecarts}
                                />
                                {error.name && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField type="number" value={state.value} error={error.value} helperText={error.value && Constant.champObligatoire} onChange={(e)=>setState({...state, value: e.target.value})} size="small" label="Valeur" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Edit />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
                            <MUIDataTable
                              title="Liste des écarts"
                              data={reponse2}
                              columns={columns2}
                              options={{
                                draggableColumns: { enabled: true },
                                enableNestedDataAccess: '.',
                                filterType: "multiselect",
                                jumpToPage: true,
                                serverSide: serverSide,
                                rowsPerPageOptions:[50, 100, 500, 1000],
                                rowsPerPage: page.size,
                                count: page.totalElements,
                                textLabels: Constant.textLabels,
                                selectableRows: "none",
                                setFilterChipProps: (colIdx, colName, data) => {
                                  return {
                                    label: `${colName}: ${data}`,
                                  }
                                },
                                onTableChange(action, tableState) {
                                  if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                                    setServerSide(false)
                                  }
                                },
                              }}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
