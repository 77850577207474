import React, {useState, useEffect, useRef} from "react";
import Service from "../../../service";
import CustomTable from '../../../components/CrudGenerator/CrudGenerator';
import {Add, Delete, Edit, ChevronLeft, Close, EditOutlined, DeleteOutlined} from "@material-ui/icons";
import {Button, Modal, Switch, Tooltip} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {Constant} from "../../../constant";
import Methodes from "../../../utils";
import {CommonModal} from "../../../components/_Ena/CommonModal";
import PersonnelsForm from "./PersonnelsForm";
const { confirm } = Modal;


export default function Personnels() {

    //States
    const [personnels, setPersonnels] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [modal, setModal] = useState(false);
    const actionRef = useRef("")

     //Vars
    const columns = [
        {
            name: "matricule",
            label: "Matricule",
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "firstName",
            label: "Prénom & nom",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    const row = tableMeta.tableData[tableMeta.rowIndex];
                    return row.firstName+" "+row.lastName
                }
            }
        },{
            name: "phone",
            label: "N°Téléphone",
            options: {
                filter: false,
                sort: true
            }
        },{
            name: "type",
            label: "Type de personnel",
            options: {
                filter: true,
                sort: true           }
        },{
            name: "id",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    const row = tableMeta.tableData[tableMeta.rowIndex];
                    return <div>

                        <Switch style={{marginBottom: 20, backgroundColor:  row.active ? 'lightGreen' : 'gry'}}
                                checked={row.active}
                                key={value} size="default" defaultChecked
                                onChange={(e) => activeUser(e,row)}
                        />

                        <Button type="dashed" onClick={(e)=> {modifier(row)}}>
                            <Tooltip placement="top" title={'Modifier'}><EditOutlined /></Tooltip>
                        </Button>

                        <Button danger onClick={(e)=> {deleteConfirm(value, tableMeta)}}>
                            <Tooltip placement="top" title={'Supprimer'}><DeleteOutlined /></Tooltip>
                        </Button>
                    </div>
                }
            }
        }];
    const options = {
        filterType: "checkbox",
        onRowsDelete: (e) =>{
            console.log("delete --> ",e);
        }
        // onRowClick: (rowData) => {console.log("RowClicked->", rowData);}
    }


    //INITIQTION
    useEffect(()=>{
        bindPersonnels();
    }, []);


    // FUNCRTIONS
    const modalOpen = () => {
        setModal(true);
    };
    const modalClose = () => {
        setModal(false)
    };

    function bindPersonnels(){
        Service.GetPersonnels().then(personelsBinded => {
            setPersonnels(personelsBinded.data.content);
        });
    }


    function formClosed(){
        bindPersonnels();
        modalClose()
    }


    //
    function add (){
        actionRef.current = 'add';
        modalOpen();
    }

    function modifier (data){
        console.log("Data 1230 : ", data);
        actionRef.current = 'edit';
        setRowData(data);
        modalOpen(true);
    }

    function deleteConfirm (id, data){
        console.log("delet d", data);
        confirm({
            title: 'Êtes-vous sûr de votre action ?',
            icon: <ExclamationCircleFilled />,
            content: Constant.deleteMessage+' '+data.rowData[0],
            okText: 'Oui, supprimer !',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                deletePersonnel(id, data);
            },
            onCancel() {},
        });
    }

    function deletePersonnel(id, data){
        Service.deletePersonnel(id).then(deleted =>{
            const msg = 'Personnel '+data.matricule+' : '+data.firstName+' '+data.lastName+'Supprimé avec succès !';
            Methodes.ToastTopCenter('ok', msg, 7000);
            bindPersonnels();
        }).catch(error =>{
            console.log("delete error",error);
            Methodes.ToastTopCenter('error', Constant.reponsesServeur.erreurServeur, 7000);
        })
    }

    function activeUser(e, data){
        console.log('data: ==>', data)
        const body = {active: e}
        Service.activeAndUnactivePersonnel(data.id, body).then(activedResponse => {{
            bindPersonnels();
            const etat = e ? 'activé' : 'désactivé';
            const msg = 'Utilisateur '+etat+' avec succès';
            Methodes.ToastTopCenter('ok', msg, 10000);
        }}).catch(error => {
            console.log("activedResponse : ", error);
            Methodes.ToastTopCenter('error', Constant.reponsesServeur, 10000);
        })
    }

    return (
        <div>
            <Button onClick={(e)=>{add()}} variant="outlined" style={{color: 'green', borderColor: 'green'}}>
                Ajouter un personnel
            </Button>
            <CustomTable 
                title={'Gestion des personnels'}
                columns={columns} 
                datatableData={personnels} options={options} />

                <CommonModal
                    title={actionRef.current === 'add' ? 'Ajout d\'un personnel' : 'Modification'}
                    openState={modal}
                    close={modalClose}
                    width={800}
                    >

                    <PersonnelsForm
                        rowData={rowData}
                        actionRef={actionRef.current}
                        close={(action) => action === 'cancel' ? modalClose() : formClosed()}
                    />

                </CommonModal>
        </div>
    )
}