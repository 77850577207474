import React  from 'react';
import { Modal } from 'antd';


export const CommonModal = ({title, openState, close, width, children}) => {
    return (
        <>
            <Modal
                open={openState}
                title={title}
                width={width}
                // onOk={handleOk}
                onCancel={close}
                footer={null}>
                {children}
            </Modal>
        </>
    );
}
