import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Divider, Radio, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit, Search } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import {num2} from '../../../chant/num2'
// import HTMLtoDOCX from "html-to-docx";
// import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function ResultatConcours() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  const [programmation, setProgrammation] = useState([]);
  const [epreuve, setEpreuve] = useState([]);
  var [open, setOpen] = useState(false);
  const [value, setValue] = useState(true);
  const [value2, setValue2] = useState("old");
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche, setRecherche] = React.useState({
    annee: 0,
  });
  const [state, setState] = React.useState({
    id: "",
    schoolYear: {
      id: "",
    },
    competitionCategory: {
      id: "",
    },
    profession: {
      id: "",
    },
  });
  const [error, setError] = React.useState({
    schoolYear: false,
    competitionCategory: false,
    profession: false,
    programmation: false,
    eliminationMark: false,
    examination: false,
    admission: false,
    step: false,
  });

  const [typeconcours, setTypeconcours] = useState([]);
  const [profession, setProfession] = useState([]);
  var [stateCherche, setStateCherche] = useState({
    id_year: 0,
    id_profession: 0,
    id_category: 0,
    status: "",
  });

  React.useEffect(() => {
    (()=>{
      bindAnnees()
      // GetCurrentYear()
      GetCategoryConcours(-1, -1)
    })();
  }, []);


    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
        let year = anneesBinded.data.content[anneesBinded.data.content.length -1].id;
          setRecherche({...recherche, annee: year})
          setAnnee(anneesBinded.data.content);
          GetProgrammationConcours(-1, -1, year)
      });
    }

    const GetProgrammationConcours = async (page, size, year) => {
      Service.GetProgrammationConcours(page, size, year).then(
        async (response) => {
          setProgrammation(response.data)
        })
    }

const OpenModal = async () => {
  let d = []
  setState(
    {
      id: "",
      schoolYear: {
        id: "",
      },
      competitionCategory: {
        id: "",
      },
      profession: {
        id: "",
      },
    }
  )
  setOpen(true)
}


  const cliquer = async () => {
    if(state.programmation === undefined || state.programmation === null || state.programmation.length === 0 ){
      setError({...error, programmation: true})
    } else{
      let data = []
      state.programmation.map((e) => {
        let result = {}
        result.programmationCategoryProfession = programmation.filter((a)=>a.competitionCategory.id === e.split("/2474/")[0] && a.profession.id === e.split("/2474/")[1])[0]
        result.step = parseInt(state.step)
        data.push(result)
      })
        setError({...error, programmation: false})
        setSpinner(true);
        Service.PutCompetionParametersApply(data).then(
          async (response) => {
            setSpinner(false);
            setOpen(false)
            toast.success(response.data, {position: "top-center", autoClose: 10000,})
          }, error => {
              setSpinner(false);
              toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
          }
        )
    }
}


const GetCandidatures = async (page, size) => {
  if(stateCherche.id_profession <= 0) {
    setError({...error, profession: true})
  } else {
    setError({...error, profession: false})
    setSpinner(true)
    Service.GetCandidaturesListForCompetionWithMoyenne(page, size, stateCherche).then(
      async (response) => {
        setSpinner(false)
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
  }
}

const getStatut = (value) => {
  if(value === "WAITING"){
    return "En attente";
  }
  if(value === "ACCEPT"){
    return "Accepté";
  }
  if(value === "REJECT_TEMPORARILY"){
    return "Rejet temporaire";
  }
  if(value === "REJECT_PERMANENT"){
    return "Rejet définitif";
  }
  if(value === "ADMITTED_PHASE_1"){
    return "Admis à la phase 1";
  }
  if(value === "ADMITTED_DEFINITIVELY"){
    return "Admis à la phase 2";
  }
}


  const columns = [
      {
        name: "applicationNumber",
        label: "Numéro Dossier",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "firstName",
        label: "Prénom(s)",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "lastName",
        label: "Nom",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "birthdate",
        label: "Date de naissance",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "phone",
        label: "Téléphone",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "overallAverage",
        label: "Moyenne",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "status",
        label: "Etat",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return getStatut(value);
          }
         }
      },
    ];


const GetCategoryConcours = async (page, size) => {
  Service.GetCategoryConcours(page, size).then(
    async (response) => {
      setTypeconcours(response.data)
    })
}

const GetProfession = async () => {
  if(stateCherche.status.trim() === ""){
    setError({...error, status: true})
  } else if(stateCherche.id_year<= 0){
    setError({...error, annee: true, status: false})
  } else if(stateCherche.id_category<= 0){
    setError({...error, annee: false, typeconcours: true, status: false})
  } else {
    setError({...error, annee: false, typeconcours: false, status: false})
    setSpinner(true);
    Service.GetProgrammationParAnneeEtType(stateCherche.id_year, stateCherche.id_category).then(
      async (response) => {
        setSpinner(false);
        setProfession(response.data)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    )
  }
}

// const GetCurrentYear = async () => {
//   Service.GetCurrentYear().then(
//     async (response) => {
//       let data = []
//       data.push(response.data)
//       setAnnee(data);
//     })
// }

  return (
    <>
      <ToastContainer />
      <PageTitle title="Resultat des concours" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'white', borderColor: 'white'}}>
            Appliquer le paramètre de passage
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
              <Grid item sm={2}>
                <Select
                    showSearch
                    placeholder="Statut"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    dropdownStyle={{zIndex: 1300}}
                    onChange={(e)=>setStateCherche({...stateCherche, status: e})}
                    options=
                    {
                      [
                        // { "value": "WAITING", "label": "En attente" },
                        // { "value": "ACCEPT", "label": "Accepté" },
                        // { "value": "REJECT_TEMPORARILY", "label": "Rejet temporaire" },
                        // { "value": "REJECT_PERMANENT", "label": "Rejet définitif" },
                        { "value": "ADMITTED_PHASE_1", "label": "admis à la phase 1" },
                        { "value": "ADMITTED_DEFINITIVELY", "label": "admis à la phase 2" },
                        { "value": "FAILED_PHASE_1", "label": "Echouer à la phase 2" },
                        { "value": "FAILED_PHASE_2", "label": "Echouer à la phase 2" }
                      ]
                    }
                  />
                  {error.status && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
              </Grid>
              <Grid item sm={2}>
                <Select
                    showSearch
                    placeholder="Année"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    dropdownStyle={{zIndex: 1300}}
                    onChange={(e)=>setStateCherche({...stateCherche, id_year: e})}
                    options=
                    {
                      annee.length > 0 && annee.map((element) => {
                        return { "value": element.id, "label": element.year }
                      })
                    }
                  />
                  {error.annee && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
              </Grid>
              <Grid item sm={3}>
                    <Select
                      showSearch
                      placeholder="Type du concours"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setStateCherche({...stateCherche, id_category: e})}
                      options=
                      {
                        typeconcours.length > 0 && typeconcours.map((element) => {
                          return { "value": element.id, "label": element.name }
                        })
                      }
                    />
                    {error.typeconcours && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
              </Grid>
              <Grid item sm={3}>
                <Button size="small" onClick={(e)=>{GetProfession()}} variant="outlined" startIcon={<Search />} style={{color: 'white', borderColor: 'white'}}>
                    Afficher les corps
                </Button>
              </Grid>
              {profession.length > 0 &&
                <Grid item sm={3}>
                      <Select
                        showSearch
                        placeholder="Corps"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", zIndex: 1}}
                        onChange={(e)=>setStateCherche({...stateCherche, id_profession: e})}
                        options=
                        {
                          profession.length > 0 && profession.map((element) => {
                            return { "value": element.id, "label": `${element.name} (${element.acronym})` }
                          })
                        }
                      />
                      {error.profession && <span style={{color: 'white'}}>{Constant.champObligatoire}</span>}
                </Grid>
              }
              {profession.length > 0 &&
                <Grid item sm={2}>
                  <Button size="small" onClick={(e)=>{GetCandidatures(page.page, page.size)}} variant="outlined" startIcon={<Search />} style={{color: 'white', borderColor: 'white'}}>
                      Afficher
                  </Button>
                </Grid>
              }
              <Grid item xs={12}>
                {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
                <MUIDataTable
                  title="Résultats"
                  data={reponse}
                  columns={columns}
                  options={{
                    filter: true,
                    draggableColumns: { enabled: true },
                    enableNestedDataAccess: '.',
                    filterType: "multiselect",
                    jumpToPage: true,
                    serverSide: serverSide,
                    rowsPerPageOptions:[50, 100, 500, 1000],
                    rowsPerPage: page.size,
                    count: page.totalElements,
                    textLabels: Constant.textLabels,
                    selectableRows: "none",
                    setFilterChipProps: (colIdx, colName, data) => {
                      return {
                        label: `${colName}: ${data}`,
                      }
                    },
                    onTableChange(action, tableState) {
                      if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                        setServerSide(false)
                      }
                    },
                    onChangePage(currentPage) {
                      setServerSide(true)
                      GetCandidatures(currentPage, page.size)
                    },
                    onChangeRowsPerPage(numberOfRows) {
                      setServerSide(true)
                      GetCandidatures(page.page, numberOfRows)
                    },
                  }}
                />
              </Grid>
            </Grid>

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1000}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                      Appliquer le paramètre de passage
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <span>Corps</span>
                                <br/>
                                <Select
                                  mode="multiple"
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, programmation: e})}
                                  options=
                                  {
                                    programmation.length > 0 && programmation.map((element) => {
                                      return { "value": element.competitionCategory.id + "/2474/" +element.profession.id, "label": element.profession.name + " ("+element.competitionCategory.name+")" }
                                    })
                                  }
                                />
                                {error.programmation && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <span>Etape</span>
                                  <br/>
                                  <Select
                                      showSearch
                                      filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                      }
                                      style={{ width: "100%", zIndex: 1}}
                                      onChange={(e)=>setState({...state, step: e})}
                                      defaultValue={state.step > 0 && state.step}
                                      options=
                                      {Constant.etape}
                                    />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === "" ? "Appliquer" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
    </>
  );
}
