 export const PersonnelsDonnees = {situationMatrimoniale: ["Célibataire sans enfant", "Célibataire avec enfant(s)", "Marié(e)"],
  statusFonction: ["Fonctionnaire civil", "Fonctionnaire militaire", "Contractuel", "Vacataire", "Conventionnel"],
 regimeMariage: ["Monogame", "Polygame", "Aucun"],
 niveauxEtude: ["Moins du DEF", "DEF", "CAP", "BT1", "BT2", "DUT/DUTS/BTS", "LICENCE", "MAITRISE", "MASTER/DEA", "DOCTORAT"],
 grades: ["Assistant", "Maître assistant", "Maître de conférences", "Maître de recherche","Directeur de recherche","Chargé de recherche","Attaché de recherche","Professeur"],
 etatsService: [
   {nom: "En activité"},
   {nom:"En disponibilité"},
   {nom:"En congé de formation"},
   {nom:"En congé de maternité"},
   {nom:"En congé de maladie"},
   {nom:"En congé annuel"},
   {nom:"En congé d'expectative"},
   {nom:"En congé d'intérêt public"},
   {nom:"En congé spécial"},
   {nom:"En congé pour des raisons familiales"},
   {nom:"En détachement"},
   {nom:"Année sabbatique"},
   {nom:"En retraite"},
   {nom:"Mutée"},
   {nom:"Abandonnée"},
   {nom:"Décédée"},
   {nom:"Disposée"}
   ],
 echelons: [
 "1er Echelon",
 "2ème Echelon",
 "3ème Echelon",
 "4ème Echelon",
 "5ème Echelon",
 "6ème Echelon",
 "7ème Echelon"],

  corpsPersonnelAdministratif: [
     {nom: "ADMINISTRATEUR CIVIL (A)"},
     {nom: "SECRETAIRE D'ADMINISTRATION (B2)"},
     {nom: "ATTACHE D'ADMINISTRATION (B1)"},
     {nom: "ADJOINT D'ADMINISTRATION (C)"},
     {nom: "ADJOINT DE SECRETARIAT (C)"},
     {nom: "INSPECTEUR DES SERVICES ECONOMIQUES (A)"},
     {nom: "CONTROLEUR DES SERVICES ECONOMIQUES (B2)"},
     {nom: "CONTROLEUR DES SERVICES ECONOMIQUES (B1)"},
     {nom: "ADJOINT DES SERVICES ECONOMIQUES (C)"},
     {nom: "INSPECTEUR DES FINANCES (A)"},
     {nom: "CONTROLEUR DES FINANCES (B2)"},
     {nom: "CONTROLEUR DES FINANCES (B1)"},
     {nom: "CONSEILLER DES AFFAIRES ETRANGERES (A)"},
     {nom: "TRADUCTEUR INTERPRETE (A)"},
     {nom: "SECRETAIRE DES AFFAIRES ETRANGERES (B2)"},
     {nom: "SECRETAIRE DES AFFAIRES ETRANGERES (B1)"},
     {nom: "ADMINISTRATEUR DE L'ACTION SOCIALE (A)"},
     {nom: "TECHNICIEN SUPERIEUR DE L'ACTION SOCIALE (B2)"},
     {nom: "INGENIEUR DE L'AGRICULTURE ET DU GENIE RURAL (A)"},
     {nom: "TECHNICIEN DE L'AGRICULTURE ET DU GENIE RURAL (B2)"},
     {nom: "TECHNICIEN DE L'AGRICULTURE ET DU GENIE RURAL (B1)"},
     {nom: "AGENT TECHNIQUE DE L'AGRICULTURE ET DU GENIE RURAL (C)"},
     {nom: "ADMINISTRATEUR DES ARTS ET DE LA CULTURE (A)"},
     {nom: "TECHNICIEN SUPERIEUR DES ARTS ET DE LA CULTURE (B2)"},
     {nom: "INGENIEUR DES CONSTRUCTIONS CIVILES (A)"},
     {nom: "TECHNICIEN SUPERIEUR DES CONSTRUCTIONS CIVILES (B2)"},
     {nom: "TECHNICIEN DES CONSTRUCTIONS CIVILES (B1)"},
     {nom: "AGENT TECHNIQUE DES CONSTRUCTIONS CIVILES (C)"},
     {nom: "INSPECTEUR DES DOUANES (A)"},
     {nom: "CONTROLEUR DES DOUANES (B2)"},
     {nom: "CONTROLEUR DES DOUANES (B1)"},
     {nom: "AGENT DE CONSTATATION DES DOUANES (C)"},
     {nom: "INGENIEUR DES EAUX ET FORETS (A)"},
     {nom: "TECHNICIEN SUPERIEUR DES EAUX ET FORETS (B2)"},
     {nom: "AGENT TECHNIQUE DES EAUX ET FORETS (C)"},
     {nom: "PROFESSEUR ENSEIGNEMENT SECONDAIRE (A)"},
     {nom: "PROFESSEUR TITULAIRE FONDAMENTAL (A)"},
     {nom: "MAITRE PRINCIPAL FONDAMENTAL (B2)"},
     {nom: "MAITRE PRINCIPAL FONDAMENTAL (B1)"},
     {nom: "PROFESSEUR PRINCIPAL FONDAMENTAL (A)"},
     {nom: "MAITRE DU PREMIER CYCLE (C)"},
     {nom: "PROFESSEUR AGREGE SECONDAIRE (A)"},
     {nom: "PROFESSEUR PRINCIPAL SECONDAIRE (A)"},
     {nom: "PROFESSEUR TITULAIRE SECONDAIRE (A)"},
     {nom: "MAITRE PRINCIPAL SECONDAIRE (B2)"},
     {nom: "MAITRE TITULAIRE SECONDAIRE (B2)"},
     {nom: "INSPECTEUR DES IMPOTS (A)"},
     {nom: "TECHNICIEN SUPERIEUR DES IMPOTS (B2)"},
     {nom: "CONTROLEUR DES IMPOTS (B1)"},
     {nom: "ADJOINT DES IMPOTS (C)"},
     {nom: "INGENIEURS DE L'INDUSTRIE ET DES MINES (A)"},
     {nom: "TECHNICIEN SUPERIEUR DE L'INDUSTRIE ET MINES (B2)"},
     {nom: "TECHNICIEN DE L'INDUSTRIE ET MINES (B1)"},
     {nom: "AGENT TECHNIQUE DE L'INDUSTRIE ET MINES (C)"},
     {nom: "JOURNALISTE ET REALISATEUR (A)"},
     {nom: "INGENIEUR DE L'INFORMATION ET DE L'AUDIO VISUEL (A)"},
     {nom: "ASSISTANT PRESSE ET REALISATION (B2)"},
     {nom: "CONTROLEUR DE L'INFORMATION (B2)"},
     {nom: "CONTROLEUR DE L'INFORMATION (B1)"},
     {nom: "REGISSEUR OU AGENT TECHNIQUE (C)"},
     {nom: "INGENIEUR INFORMATICIEN (A)"},
     {nom: "TECHNICIEN SUPERIEUR DE L'INFORMATIQUE (B2)"},
     {nom: "TECHNICIEN DE L'INFORMATIQUE (B1)"},
     {nom: "AGENT TECHNIQUE DE L'INFORMATIQUE (C)"},
     {nom: "INSPECTEUR DE LA JEUNESSE ET DES SPORTS (A)"},
     {nom: "INSTRUCTREUR DE LA JEUNESSE ET DES SPORTS (B2)"},
     {nom: "INGENIEUR DE LA METEOROLOGIE (A)"},
     {nom: "TECHNICIEN SUPERIEUR DE LA METEOROLOGIE (B2)"},
     {nom: "TECHNICIEN DE LA METEOROLOGIE (B1)"},
     {nom: "INGENIEUR DE LA NAVIGATION AERIENNE (A)"},
     {nom: "TECHNICIEN DE LA NAVIGATION AERIENNE (B1)"},
     {nom: "AGENT TECHNIQUE DE LA NAVIGATION AERIENNE (C)"},
     {nom: "PLANIFICATEUR (A)"},
     {nom: "TECHNICIEN SUPERIEUR DES TRAVAUX DE PLANIFICATION (B2)"},
     {nom: "TECHNICIEN DES TRAVAUX DE PLANIFICATION (B1)"},
     {nom: "MEDECIN, PHARMACIEN ET ODONTO-STOMALOGUE (A)"},
     {nom: "INGENIEUR SANITAIRE (A)"},
     {nom: "ASSISTANT MEDICAL (A)"},
     {nom: "TECHNICIEN SUPERIEUR DE SANTE (B2)"},
     {nom: "TECHNICIEN DE SANTE (B1)"},
     {nom: "AGENT TECHNIQUE DE SANTE (C)"},
     {nom: "INGENIEUR DE LA STATISTIQUE (A)"},
     {nom: "TECHNICIEN SUPERIEUR DE LA STATISTIQUE (B2)"},
     {nom: "TECHNICIEN DE LA STATISTIQUE (B1)"},
     {nom: "AGENT TEHCNIQUE DE LA STATISTIQUE (C)"},
     {nom: "ADMINISTRATEUR DU TOURISME (A)"},
     {nom: "TECHNICIEN SUPERIEUR DU TOURISME (B2)"},
     {nom: "TECHNICIEN DU TOURISME (B1)"},
     {nom: "ADMINISTRATEUR DU TRAVAIL ET DE LA SECURITE  SOCIALE (A)"},
     {nom: "CONTROLEUR DU TRAVAIL ET DE LA SECURITE SOCIALE (B1)"},
     {nom: "INSPECTEUR DU TRESOR (A)"},
     {nom: "CONTROLEUR DU TRESOR (B2)"},
     {nom: "CONTROLEUR DU TRESOR (B1)"},
     {nom: "ADJOINT DU TRESOR (C)"},
     {nom: "INSPECTEUR DES SCES. PENIT. ET EDUC. (A)"},
     {nom: "CONTROLEUR DES SCES. PENIT. ET EDUC. SURVEILLEE (B2)"},
     {nom: "CONTROLEUR DES SCES. PENIT. ET EDUC. (B1)"},
     {nom: "AGENT TECHNIQUE DES SCES. PENIT. ET EDUC. SURVEILLEE (C)"},
     {nom: "ADMINISTRATEUR DE LA PROTECTION CIVILE (A)"},
     {nom: "TECHNICIEN SUPERIEUR DE LA PROTECTION CIVILE (B2)"},
     {nom: "TECHNICIEN DE LA PROTECTION CIVILE (B1)"},
     {nom: "AGENT TECH. DE LA PROTECTION CIVILE (C)"},
     {nom: "ADMIMNSTRATEUR DES RESSOURCES HUMAINES (A)"},
     {nom: "CHAUFFEUR (D)"},
     {nom: "MANOEUVRE ET PLATON (E)"},
   ],


 corpsEnseignant: [
 "ENSEIGNANT DU SUPERIEUR (A)",
 "CHERCHEUR (A)"],

 classes: [
 "3ème classe",
 "2ème classe",
 "1ère classe",
 "Classe Exceptionnelle"],

 categorie: [
 "A",
 "B1",
 "B2",
 "C",
 "D",
 "E"]
 }
