import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
// import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";
import GestionUtilisateur from "../../pages/gestionUtilisateur/GestionUtilisateur";
import MesTransactions from "../../pages/MesTransactions/MesTransactions";
import GestionApis from "../../pages/gestionApis/GestionApis";
import Evenement from "../../pages/pierre/evenement/Evenement";
import Academy from "../../pages/pierre/academy/Academy";
import Profession from "../../pages/pierre/profession/Profession";
import Programmation from "../../pages/pierre/programmation/Programmation";
import CategoryConcours from "../../pages/pierre/categoryConcours/CategoryConcours";
import TypeRejet from "../../pages/pierre/typeRejet/TypeRejet";
import ServiceCandidats from "../../pages/pierre/serviceCandidats/ServiceCandidats";
import CentreConcours from "../../pages/pierre/centreConcours/CentreConcours";
import SalleCentres from "../../pages/pierre/salleCentres/SalleCentres";
import EpreuveConcours from "../../pages/pierre/epreuveConcours/EpreuveConcours";
import Departement from "../../pages/pierre/departement/Departement";
import Service from "../../pages/pierre/service/Service";
import Division from "../../pages/pierre/division/Division";
import GestionCandidature from "../../pages/pierre/gestionCandidature/GestionCandidature";
import Dashboard from "../../pages/_ENA/ControlPanel/Dashbord";
import Corps from "../../pages/pierre/corps/Corps";
import HandicapNature from "../../pages/pierre/handicapNature/HandicapNature";
import HandicapAssistance from "../../pages/pierre/handicapAssistance/HandicapAssistance";
import Batiments from "../../pages/pierre/batiments/Batiments";
import SalleBatiment from "../../pages/pierre/salleBatiment/SalleBatiment";
import EquipementSalle from "../../pages/pierre/equipementSalle/EquipementSalle";
import SpecialityFonction from "../../pages/pierre/specialityFonction/SpecialityFonction";
import Repartitions from "../../pages/pierre/repartitions/Repartitions";
import ProgrammationEpreuveConcours from "../../pages/pierre/programmationEpreuveConcours/ProgrammationEpreuveConcours";
import Evaluations from "../../pages/pierre/evaluations/Evaluations";
import GestionAnonimat from "../../pages/pierre/gestionAnonimat/GestionAnonimat";
import GestionNoteAnonimat from "../../pages/pierre/gestionNoteAnonimat/GestionNoteAnonimat";
import NoteCandidat from "../../pages/pierre/noterCandidat/NoterCandidat";
import ParametresConcours from "../../pages/pierre/parametresConcours/ParametresConcours";
import ResultatConcours from "../../pages/pierre/resultatConcours/ResultatConcours";
import StateCandidature from "../../pages/pierre/stateCandidature/StateCandidature";
import GestionFrais from "../../pages/pierre/gestionFrais/GestionFrais";
import GestionPaiements from "../../pages/pierre/gestionPaiements/GestionPaiements";
import PaiementsFaits from "../../pages/pierre/paiementsFaits/PaiementsFaits";
import InscriptionsAttente from "../../pages/pierre/inscriptionsAttente/InscriptionsAttente";
import InscriptionsFaites from "../../pages/pierre/inscriptionsFaites/InscriptionsFaites";
import StateInscription from "../../pages/pierre/stateInscription/StateInscription";
import GestionEcarts from "../../pages/pierre/gestionEcarts/GestionEcarts";
import GestionClasse from "../../pages/pierre/gestionClasse/GestionClasse";
import GestionModules from "../../pages/pierre/gestionModules/GestionModules";
import Annees from "../../pages/paul/Annee/Annee";
import Personnels from "../../pages/paul/personnels/Personnels";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  const enaAdmin = process.env.REACT_APP__ENA_ADMINISTRAT_ROUTE;

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path={enaAdmin+'/annees'} component={Annees} />
              <Route path={enaAdmin+'/personnels'} component={Personnels} />
              <Route path={enaAdmin+'/dashbord'} component={Dashboard} />
              <Route path={enaAdmin+'/table'} component={Tables} />
              
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/gestionUtilisateur" component={GestionUtilisateur} />
              <Route path="/app/mesTransactions" component={MesTransactions} />
              <Route path={enaAdmin+"/gestionApis"} component={GestionApis} />
              <Route path="/app/notifications" component={Notifications} />
              <Route path={enaAdmin+"/evenements"} component={Evenement} />
              <Route path={enaAdmin+"/academy"} component={Academy} />
              <Route path={enaAdmin+"/profession"} component={Profession} />
              <Route path={enaAdmin+"/programmation"} component={Programmation} />
              <Route path={enaAdmin+"/categoryConcours"} component={CategoryConcours} />
              <Route path={enaAdmin+"/typeRejet"} component={TypeRejet} />
              <Route path={enaAdmin+"/serviceCandidats"} component={ServiceCandidats} />
              <Route path={enaAdmin+"/centreConcours"} component={CentreConcours} />
              <Route path={enaAdmin+"/salleCentres"} component={SalleCentres} />
              <Route path={enaAdmin+"/epreuveConcours"} component={EpreuveConcours} />
              <Route path={enaAdmin+"/departement"} component={Departement} />
              <Route path={enaAdmin+"/service"} component={Service} />
              <Route path={enaAdmin+"/division"} component={Division} />
              <Route path={enaAdmin+"/gestionCandidature"} component={GestionCandidature} />
              <Route path={enaAdmin+"/corps"} component={Corps} />
              <Route path={enaAdmin+"/handicapNature"} component={HandicapNature} />
              <Route path={enaAdmin+"/handicapAssistance"} component={HandicapAssistance} />
              <Route path={enaAdmin+"/batiments"} component={Batiments} />
              <Route path={enaAdmin+"/salleBatiment"} component={SalleBatiment} />
              <Route path={enaAdmin+"/equipementSalle"} component={EquipementSalle} />
              <Route path={enaAdmin+"/speciality-fonction"} component={SpecialityFonction} />
              <Route path={enaAdmin+"/Repartitions"} component={Repartitions} />
              <Route path={enaAdmin+"/ProgrammationEpreuveConcours"} component={ProgrammationEpreuveConcours} />
              <Route path={enaAdmin+"/Evaluations"} component={Evaluations} />
              <Route path={enaAdmin+"/GestionAnonimat"} component={GestionAnonimat} />
              <Route path={enaAdmin+"/GestionNoteAnonimat"} component={GestionNoteAnonimat} />
              <Route path={enaAdmin+"/NoteCandidat"} component={NoteCandidat} />
              <Route path={enaAdmin+"/ParametresConcours"} component={ParametresConcours} />
              <Route path={enaAdmin+"/ResultatConcours"} component={ResultatConcours} />
              <Route path={enaAdmin+"/StateCandidature"} component={StateCandidature} />
              <Route path={enaAdmin+"/GestionFrais"} component={GestionFrais} />
              <Route path={enaAdmin+"/GestionPaiements"} component={GestionPaiements} />
              <Route path={enaAdmin+"/PaiementsFaits"} component={PaiementsFaits} />
              <Route path={enaAdmin+"/InscriptionsAttente"} component={InscriptionsAttente} />
              <Route path={enaAdmin+"/InscriptionsFaites"} component={InscriptionsFaites} />
              <Route path={enaAdmin+"/StateInscription"} component={StateInscription} />
              <Route path={enaAdmin+"/GestionEcarts"} component={GestionEcarts} />
              <Route path={enaAdmin+"/GestionClasse"} component={GestionClasse} />
              <Route path={enaAdmin+"/GestionModules"} component={GestionModules} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path={enaAdmin+"/app/ui/charts"} component={Charts} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
              color={"white"}
            >
              <div>
                <p>Copyright &copy; 2023 - {new Date().getFullYear()} par ENA</p>
              </div>
              <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="white"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="white"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="white"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
