import React, { useState } from "react";
import { Button, Grid, LinearProgress, Modal, Paper,Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";

import { DatePicker, Radio, Select, Switch } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { Constant } from "../../../constant";
import Widget from "../../../components/Widget/Widget";

import useStyles from "../../dashboard/styles";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let user = JSON.parse(localStorage.getItem("user"));
export default function StateCandidature() {

  const [spinner, setSpinner] = useState(false);
  const [show, setShow] = useState(true);
  const [reponse, setReponse] = useState([]);
  const [serverSide, setServerSide] = useState(true);
  const [annee, setAnnee] = useState([]);
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [recherche2, setRecherche2] = React.useState({
    gender: false,
    profession: false,
    category: false,
    oldYear: false,
    id_school_year: 0,
  });
  const [recherche, setRecherche] = React.useState({
    gender: false,
    profession: false,
    category: false,
    oldYear: false,
    id_school_year: 0,
  });
  React.useEffect(() => {
    (()=>{
      bindAnnees()
    })();
  }, []);


    //Functions
    function bindAnnees (){
      Service.GetAnneeScolaire().then(anneesBinded => {
          setAnnee(anneesBinded.data.content);
      });
    }


const GetStatistiqueCandidature = async () => {
    setSpinner(true);
    Service.GetStatistiqueCandidature(recherche).then(
      async (response) => {
        setSpinner(false);
        let r = recherche;
        setRecherche2(r)
        setRecherche({...recherche, gender: false, category: false, profession: false, oldYear: false})
        setReponse(response.data);
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
      }
    )
}

const typeRecherche = async (val) => {
    if(val === "gender"){setRecherche({...recherche, gender: true})} else
    if(val === "oldYear"){setRecherche({...recherche, oldYear: true})} else
    if(val === "profession"){setRecherche({...recherche, profession: true})} else
    if(val === "category"){setRecherche({...recherche, category: true})} else
    if(val === "gender,profession"){setRecherche({...recherche, gender: true, profession: true})} else
    if(val === "gender,category"){setRecherche({...recherche, gender: true, category: true})} else
    if(val === "gender,oldYear"){setRecherche({...recherche, gender: true, oldYear: true})} else
    if(val === "gender,category,profession"){setRecherche({...recherche, gender: true, category: true, profession: true})}
}

const columns = [
  {
    name: "gender",
    label: "Genre",
    options: {
      filter: true,
      sort: true,
      display: recherche2.gender,
     }
  },
  {
    name: "oldYear",
    label: "Age",
    options: {
      filter: true,
      sort: true,
      display: recherche2.oldYear,
     }
  },
  {
    name: "profession",
    label: "Corps",
    options: {
      filter: true,
      sort: true,
      display: recherche2.profession,
     }
  },
  {
    name: "category",
    label: "Type de concours",
    options: {
      filter: true,
      sort: true,
      display: recherche2.category,
     }
  },
  {
    name: "number",
    label: "Nombre",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "percentage",
    label: "Pourcentage",
    options: {
      filter: true,
      sort: true,
     }
  },
];

  return (
    <>
      <ToastContainer />
      <PageTitle title="Statistique des candidatures" />
      <Grid container spacing={4}>
        <Grid item lg={2}>
            <Select
                placeholder="Année"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", zIndex: 1}}
                onChange={(e)=>setRecherche({...recherche, id_school_year: e})}
                // defaultValue={annee.length > 0 && annee[0].year}
                options=
                {
                  annee.length > 0 && annee.map((element) => {
                    return { "value": element.id, "label": element.year }
                  })
                }
              />
        </Grid>
        <Grid item lg={3}>
            <Select
                placeholder="Type de recherche"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: 250, zIndex: 1}}
                onChange={(e)=>typeRecherche(e)}
                options={[
                { "value": "gender", "label": "Genre" },
                { "value": "oldYear", "label": "Age" },
                { "value": "profession", "label": "Corps" },
                { "value": "category", "label": "Type de concours" },
                { "value": "gender,profession", "label": "Genre et Corps" },
                { "value": "gender,category", "label": "Genre et Type de concours" },
                { "value": "gender,oldYear", "label": "Genre et Age" },
                { "value": "gender,category,profession", "label": "Genre, Corps et Type de concours" },
                ]}
              />
        </Grid>
        <Grid item lg={2}>
          <Button onClick={(e)=>GetStatistiqueCandidature()} variant="contained" startIcon={<Add />} style={{color: 'black', backgroundColor: 'white'}}>
              Afficher
            </Button>
        </Grid>
        {/* <Grid item lg={1}>
          <Switch defaultChecked onChange={(e)=> setShow(!show)} checkedChildren="Tableau" unCheckedChildren="Graphique" />
        </Grid> */}
        <Grid item xs={12}>
        {spinner && <LinearProgress color={Constant.couleurSpinnerLine} />}
          {show ?
                <MUIDataTable
                  title="Statistiques"
                  data={reponse}
                  columns={columns}
                  options={{
                    draggableColumns: { enabled: true },
                    enableNestedDataAccess: '.',
                    filterType: "multiselect",
                    jumpToPage: true,
                    serverSide: serverSide,
                    rowsPerPageOptions:[50, 100, 500, 1000],
                    rowsPerPage: page.size,
                    count: page.totalElements,
                    textLabels: Constant.textLabels,
                    selectableRows: "none",
                    setFilterChipProps: (colIdx, colName, data) => {
                      return {
                        label: `${colName}: ${data}`,
                      }
                    },
                    onTableChange(action, tableState) {
                      if(action === "sort" || action === "filterChange" || action === "resetFilters" || action === "search") {
                        setServerSide(false)
                      }
                    },
                  }}
                />
          :
              <div>

              </div>
          }
        </Grid>
      </Grid>
    </>
  );
}
